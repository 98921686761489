import React, { useState, useEffect } from "react";
import {
  ChakraProvider,
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Checkbox,
  RadioGroup,
  Radio,
  Textarea,
  Select,
  useDisclosure,
  TableContainer,
  List,
  ListItem,
  IconButton,
} from "@chakra-ui/react";
import { ViewIcon, EditIcon, DeleteIcon } from "@chakra-ui/icons";
import axios from "axios";
import AddSignatureOne from "./AddSignatureOne";

const AddPerformanceOne = ({ username }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [employees, setEmployeeNames] = useState([]);
  const {
    isOpen: isViewOpen,
    onOpen: onViewOpen,
    onClose: onViewClose,
  } = useDisclosure();

  const initialPerformanceData = {
    staff: username,
    reference_number: "",
    appraisal_type: "",
    issue_date: "",
    next_due_date: "",
    criteria: {
      job_knowledge: "",
      quality_of_work: "",
      productivity: "",
      dependability: "",
      attendance: "",
      relations_with_others: "",
      commitment_to_safety: "",
      supervisory_ability: "",
      overall_rating: "achieves",
    },
    comments: {
      rater_comments: "",
      supervisor_comments: "",
      employee_comments: "",
    },
    signatures: {
      employee_signature: "",
      employee_signature_date: "",
      rater_name: "",
      rater_signature: "",
      rater_signature_date: "",
      supervisor_name: "",
      supervisor_signature: "",
      supervisor_signature_date: "",
      rater_certification: "",
    },
  };

  useEffect(() => {
    const accessToken = localStorage.getItem("access_token");

    axios
      .get("https://divine.backenddd.in/HumanResource/StaffList/", {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        setEmployeeNames(response.data);
      })
      .catch((error) => {
        console.error("Error fetching employee names:", error);
      });
  }, []);

  const [performanceData, setPerformanceData] = useState(
    initialPerformanceData
  );
  const [allAppraisals, setAllAppraisals] = useState([]);
  const [viewedAppraisal, setViewedAppraisal] = useState(null);
  const [editingAppraisalIndex, setEditingAppraisalIndex] = useState(null);
  const [signature, setSignature] = useState([]);

  const [levelsOfPerformance, setLevelsOfPerformance] = useState({
    exceed: [],
    proficient: [],
    marginal: [],
    below: [],
  });

  useEffect(() => {
    const fetchAppraisals = () => {
      axios
        .get(
          `https://divine.backenddd.in/HumanResource/getuserwiseAppraisal/${username}/`
        )
        .then((response) => {
          setAllAppraisals(response.data);
        })
        .catch((error) => {
          console.error("There was an error fetching the appraisals!", error);
        });
    };

    fetchAppraisals();
  }, [username]);

  const fetchAppraisals = () => {
    axios
      .get(
        `https://divine.backenddd.in/HumanResource/getuserwiseAppraisal/${username}/`
      )
      .then((response) => {
        setAllAppraisals(response.data);
      })
      .catch((error) => {
        console.error("There was an error fetching the appraisals!", error);
      });
  };

  useEffect(() => {
    if (username) {
      fetchAppraisals();
    }
  }, [username]);

  const fetchSignatures = async () => {
    try {
      const response = await axios.get(
        "https://divine.backenddd.in/HumanResource/signatures/"
      );
      setSignature(response.data);
    } catch (error) {
      console.error("Error fetching entries:", error);
    }
  };

  useEffect(() => {
    fetchSignatures();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPerformanceData({
      ...performanceData,
      [name]: value,
    });
  };

  const handleCriteriaChange = (value, criteriaName) => {
    setPerformanceData({
      ...performanceData,
      criteria: {
        ...performanceData.criteria,
        [criteriaName]: value,
      },
    });
  };

  const handleCheckboxChange = (e, criteriaName) => {
    const { checked } = e.target;
    const value = checked ? "exceed" : "achieves";
    setPerformanceData({
      ...performanceData,
      criteria: {
        ...performanceData.criteria,
        [criteriaName]: value,
      },
    });
  };

  const handleCommentsChange = (e, commentType) => {
    const { value } = e.target;
    setPerformanceData({
      ...performanceData,
      comments: {
        ...performanceData.comments,
        [commentType]: value,
      },
    });
  };

  const handleSignaturesChange = (e, signatureType) => {
    const { value } = e.target;
    setPerformanceData({
      ...performanceData,
      signatures: {
        ...performanceData.signatures,
        [signatureType]: value,
      },
    });
  };

  const validateForm = () => {
    console.log("All Done");
    return true;
  };

  const validateCriteria = () => {
    for (const [key, value] of Object.entries(performanceData.criteria)) {
      if (value === "") {
        alert(
          `Please select a rating for ${key
            .replace(/([A-Z])/g, " $1")
            .toLowerCase()}`
        );
        return false;
      }
    }
    return true;
  };

  const handleSave = () => {
    if (!validateForm() || !validateCriteria()) return;

    const newLevelsOfPerformance = {
      exceed: [],
      proficient: [],
      marginal: [],
      below: [],
    };

    for (const [key, value] of Object.entries(performanceData.criteria)) {
      if (value === "exceed") newLevelsOfPerformance.exceed.push(key);
      if (value === "proficient") newLevelsOfPerformance.proficient.push(key);
      if (value === "marginal") newLevelsOfPerformance.marginal.push(key);
      if (value === "below") newLevelsOfPerformance.below.push(key);
    }

    setLevelsOfPerformance(newLevelsOfPerformance);

    const payload = {
      ...performanceData,
      levelsOfPerformance: newLevelsOfPerformance,
    };

    if (editingAppraisalIndex !== null) {
      axios
        .put(
          `https://divine.backenddd.in/HumanResource/appraisals/${allAppraisals[editingAppraisalIndex].id}/`,
          payload
        )
        .then((response) => {
          fetchAppraisals();
        });
    } else {
      axios
        .post(
          "https://divine.backenddd.in/HumanResource/appraisals/",
          payload
        )
        .then((response) => {
          fetchAppraisals();
        });
    }

    setEditingAppraisalIndex(null);
    onClose();
  };

  const handleView = (index) => {
    const appraisal = allAppraisals[index];
    axios
      .get(
        `https://divine.backenddd.in/HumanResource/appraisals/${appraisal.id}/`
      )
      .then((response) => {
        setViewedAppraisal(response.data);
        onViewOpen();
      });
  };

  const handleEdit = (index) => {
    const appraisal = allAppraisals[index];

    console.log("Selected appraisal for editing:", appraisal);

    setPerformanceData({
      staff: appraisal.staff || "",
      reference_number: appraisal.reference_number || "",
      appraisal_type: appraisal.appraisal_type || "",
      issue_date: appraisal.issue_date || "",
      // next_due_date: appraisal.next_due_date || "",
      criteria: {
        job_knowledge: appraisal.job_knowledge || "",
        quality_of_work: appraisal.quality_of_work || "",
        productivity: appraisal.productivity || "",
        dependability: appraisal.dependability || "",
        attendance: appraisal.attendance || "",
        relations_with_others: appraisal.relations_with_others || "",
        commitment_to_safety: appraisal.commitment_to_safety || "",
        supervisory_ability: appraisal.supervisory_ability || "",
        overall_rating: appraisal.overall_rating || "achieves",
      },
      comments: {
        rater_comments: appraisal.rater_comments || "",
        supervisor_comments: appraisal.supervisor_comments || "",
        employee_comments: appraisal.employee_comments || "",
      },
      signatures: {
        employee_signature: appraisal.employee_signature || "",
        employee_signature_date: appraisal.employee_signature_date || "",
        rater_name: appraisal.rater_name || "",
        rater_signature: appraisal.rater_signature || "",
        rater_signature_date: appraisal.rater_signature_date || "",
        supervisor_name: appraisal.supervisor_name || "",
        supervisor_signature: appraisal.supervisor_signature || "",
        supervisor_signature_date: appraisal.supervisor_signature_date || "",
        rater_certification: appraisal.rater_certification || "",
      },
    });

    setLevelsOfPerformance(
      appraisal.levelsOfPerformance || {
        exceed: [],
        proficient: [],
        marginal: [],
        below: [],
      }
    );

    setEditingAppraisalIndex(index);
    onOpen();
  };

  const handleDelete = (index) => {
    const appraisal = allAppraisals[index];
    axios
      .delete(
        `https://divine.backenddd.in/HumanResource/appraisals/${appraisal.id}/`
      )
      .then(() => {
        fetchAppraisals();
      });
  };

  const handleClose = () => {
    setEditingAppraisalIndex(null);
    onClose();
  };

  const handleNewForm = () => {
    setPerformanceData(initialPerformanceData);
    setLevelsOfPerformance({
      exceed: [],
      proficient: [],
      marginal: [],
      below: [],
    });
    setEditingAppraisalIndex(null);
    onOpen();
  };

  const criteriaDescriptions = {
    job_knowledge:
      "In-depth knowledge of all requirements of the job. How well does the employee understand all phases of the job as defined by the performance standards set for the position?",
    quality_of_work:
      "Accuracy and neatness. Does the employee produce a high-quality work product? Is quality work a priority for the employee?",
    productivity:
      "Consider employee's ability to prioritize and organize work effectively to meet assigned deadlines. Were assignments timely completed and appropriate follow-up implemented? Is the employee a self starter?",
    dependability:
      "Employee needs little or no direction. To what extent can the employee be relied upon to carry out instructions; and the degree to which the employee can work with limited supervision?",
    attendance:
      "Attendance and punctuality are very important in maintaining a normal workload and efficient schedule. Employees are expected to report to work regularly and be ready to perform their assigned duties at the beginning of their assigned work shift. Is the employee absent frequently? Are the absences affecting his/her performance? Does this pattern constitute a hardship on the work environment?",
    relations_with_others:
      "Consider employee's abilities to maintain a positive and harmonious attitude in the work environment. How well does the employee relate to the supervisors, co-workers and the broader University community?",
    commitment_to_safety:
      "To what extent has the employee adhered to the recommended safe work practices, participated in safety training programs; and contributes to the recognition and control of hazard in his/her work area.",
    supervisory_ability:
      "In the evaluation of this factor, consider the employee's ability to organize, plan, train, delegate and control the work of subordinates in an effective manner.",
  };

  return (
    <ChakraProvider>
      <Box p={4}>
        <Button className="addEmployeeBtn" onClick={handleNewForm}>
          <i className="fa-solid fa-plus AddDependIcon"></i> Add Record
        </Button>
        <Modal isOpen={isOpen} onClose={handleClose}>
          <ModalOverlay />
          <ModalContent maxWidth="80%">
            <ModalHeader>Performance Appraisal Form</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <div className="row">
                <div className="col-12 col-md-4 col-lg-4">
                  <FormControl mb={4}>
                    <FormLabel>Staff Name</FormLabel>
                    <Input
                      name="staff"
                      value={username}
                      onChange={handleInputChange}
                      required
                    />
                  </FormControl>
                </div>
                <div className="col-12 col-md-4 col-lg-4">
                  <FormControl mb={4}>
                    <FormLabel>Reference Number</FormLabel>
                    <Input
                      name="reference_number"
                      value={performanceData.reference_number}
                      onChange={handleInputChange}
                      required
                    />
                  </FormControl>
                </div>
                <div className="col-12 col-md-4 col-lg-4">
                  <FormControl mb={4}>
                    <FormLabel>Types of Appraisal</FormLabel>
                    <Select
                      name="appraisal_type"
                      value={performanceData.appraisal_type}
                      onChange={handleInputChange}
                      required
                    >
                      <option value={""}>Choose Appraisal Type</option>
                      <option>Anniversary Appraisal</option>
                      <option>Birthday Appraisal</option>
                    </Select>
                  </FormControl>
                </div>
                <div className="col-12 col-md-2 col-lg-2">
                  <FormControl mb={4}>
                    <FormLabel>Issue Date</FormLabel>
                    <Input
                      type="date"
                      name="issue_date"
                      value={performanceData.issue_date}
                      onChange={handleInputChange}
                      required
                    />
                  </FormControl>
                </div>
                <div className="col-12 col-md-12 col-lg-12">
                  <TableContainer>
                    <Table
                      variant="simple"
                      mb={4}
                      className="modelFormFormatting"
                    >
                      <Thead>
                        <Tr style={{ background: "#338687" }}>
                          <Th width={"40%"} border="1px" borderColor="gray.200">
                            Name
                          </Th>
                          <Th width={"20%"} border="1px" borderColor="gray.200">
                            Exceed Standards
                          </Th>
                          <Th width={"30%"} border="1px" borderColor="gray.200">
                            Achieves Standards
                          </Th>
                          <Th width={"20%"} border="1px" borderColor="gray.200">
                            Below Standards
                          </Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {Object.keys(performanceData.criteria).map(
                          (criteria) => (
                            <Tr key={criteria}>
                              <Td>
                                {criteria
                                  .replace(/([A-Z])/g, " $1")
                                  .toUpperCase()}
                              </Td>
                              <Td>
                                <Checkbox
                                  isChecked={
                                    performanceData.criteria[criteria] ===
                                    "exceed"
                                  }
                                  onChange={(e) =>
                                    handleCheckboxChange(e, criteria)
                                  }
                                />
                              </Td>
                              <Td>
                                <RadioGroup
                                  value={performanceData.criteria[criteria]}
                                  onChange={(value) =>
                                    handleCriteriaChange(value, criteria)
                                  }
                                >
                                  <Radio value="proficient" pr={"5"}>
                                    Proficient
                                  </Radio>
                                  <Radio value="marginal">Marginal</Radio>
                                </RadioGroup>
                              </Td>
                              <Td>
                                <Checkbox
                                  isChecked={
                                    performanceData.criteria[criteria] ===
                                    "below"
                                  }
                                  onChange={() =>
                                    handleCriteriaChange("below", criteria)
                                  }
                                />
                              </Td>
                            </Tr>
                          )
                        )}
                      </Tbody>
                    </Table>
                  </TableContainer>
                </div>

                <div className="col-12 col-md-12 col-lg-12">
                  <FormControl mb={4}>
                    <FormLabel>Rater's Overall Comments</FormLabel>
                    <Textarea
                      name="rater_comments"
                      value={performanceData.comments.rater_comments}
                      onChange={(e) =>
                        handleCommentsChange(e, "rater_comments")
                      }
                    />
                  </FormControl>
                </div>
                <div className="col-12 col-md-12 col-lg-12">
                  <FormControl mb={4}>
                    <FormLabel>Second Level Supervisor Comments</FormLabel>
                    <Textarea
                      name="supervisor_comments"
                      value={performanceData.comments.supervisor_comments}
                      onChange={(e) =>
                        handleCommentsChange(e, "supervisor_comments")
                      }
                    />
                  </FormControl>
                </div>

                <div className="col-12 col-md-12 col-lg-12">
                  <FormControl mb={4}>
                    <FormLabel>Employee Comments</FormLabel>
                    <Textarea
                      name="employee_comments"
                      value={performanceData.comments.employee_comments}
                      onChange={(e) =>
                        handleCommentsChange(e, "employee_comments")
                      }
                    />
                  </FormControl>
                </div>

                <div className="col-12 col-md-4 col-lg-4">
                  <FormControl mb={4}>
                    <FormLabel>Employee Signature</FormLabel>
                    <Select
                      name="employee_signature"
                      value={performanceData.signatures.employee_signature}
                      onChange={(e) =>
                        handleSignaturesChange(e, "employee_signature")
                      }
                    >
                      <option value="">Select Signature</option>
                      {signature &&
                        signature.map((enter, index) => (
                          <option key={index} value={enter.id}>
                            {enter.staff}-{enter.id}
                          </option>
                        ))}
                    </Select>
                  </FormControl>
                </div>

                <div className="col-12 col-md-4 col-lg-4">
                  <FormControl mt={"8"}>
                    <AddSignatureOne onSignatureAdded={fetchSignatures} />
                  </FormControl>
                </div>

                <div className="col-12 col-md-4 col-lg-4">
                  <FormControl mb={4}>
                    <FormLabel>Employee Signature Date</FormLabel>
                    <Input
                      type="date"
                      name="employee_signature_date"
                      value={performanceData.signatures.employee_signature_date}
                      onChange={(e) =>
                        handleSignaturesChange(e, "employee_signature_date")
                      }
                    />
                  </FormControl>
                </div>

                <div className="col-12 col-md-4 col-lg-4">
                  <FormControl mb={4}>
                    <FormLabel>Rater Name</FormLabel>
                    <Select
                      name="rater_name"
                      value={performanceData.signatures.rater_name}
                      onChange={(e) => handleSignaturesChange(e, "rater_name")}
                    >
                          <option value="">Select Signature</option>
                      {signature &&
                        signature.map((enter, index) => (
                          <option key={index} value={enter.id}>
                            {enter.staff}-{enter.id}
                          </option>
                        ))}
                    </Select>
                  </FormControl>
                </div>

                <div className="col-12 col-md-4 col-lg-4">
                  <FormControl mb={4}>
                    <FormLabel>Rater Signature</FormLabel>
                    <Select
                      name="rater_signature"
                      value={performanceData.signatures.rater_signature}
                      onChange={(e) =>
                        handleSignaturesChange(e, "rater_signature")
                      }
                    >
                      <option value="">Select Signature</option>
                      {signature &&
                        signature.map((enter, index) => (
                          <option key={index} value={enter.id}>
                            {enter.staff}-{enter.id}
                          </option>
                        ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="col-12 col-md-4 col-lg-4">
                  <FormControl mb={4}>
                    <FormLabel>Rater Signature Date</FormLabel>
                    <Input
                      type="date"
                      name="rater_signature_date"
                      value={performanceData.signatures.rater_signature_date}
                      onChange={(e) =>
                        handleSignaturesChange(e, "rater_signature_date")
                      }
                    />
                  </FormControl>
                </div>
                <div className="col-12 col-md-4 col-lg-4">
                  <FormControl mb={4}>
                    <FormLabel>Second Level Supervisor Name</FormLabel>
                    <Select
                      name="supervisor_name"
                      value={performanceData.signatures.supervisor_name}
                      onChange={(e) =>
                        handleSignaturesChange(e, "supervisor_name")
                      }
                    >
                          <option value="">Select Signature</option>
                      {signature &&
                        signature.map((enter, index) => (
                          <option key={index} value={enter.id}>
                            {enter.staff}-{enter.id}
                          </option>
                        ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="col-12 col-md-4 col-lg-4">
                  <FormControl mb={4}>
                    <FormLabel>Second Level Supervisor Signature</FormLabel>
                    <Select
                      name="supervisor_signature"
                      value={performanceData.signatures.supervisor_signature}
                      onChange={(e) =>
                        handleSignaturesChange(e, "supervisor_signature")
                      }
                    >
                      <option value="">Select Signature</option>
                      {signature &&
                        signature.map((enter, index) => (
                          <option key={index} value={enter.id}>
                            {enter.staff}-{enter.id}
                          </option>
                        ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="col-12 col-md-4 col-lg-4">
                  <FormControl mb={4}>
                    <FormLabel>
                      Second Level Supervisor Signature Date
                    </FormLabel>
                    <Input
                      type="date"
                      name="supervisor_signature_date"
                      value={
                        performanceData.signatures.supervisor_signature_date
                      }
                      onChange={(e) =>
                        handleSignaturesChange(e, "supervisor_signature_date")
                      }
                    />
                  </FormControl>
                </div>

                <div className="col-12 col-md-12 col-lg-12">
                  <FormControl mb={4}>
                    <FormLabel>Rater Certification</FormLabel>
                    <Select
                      name="rater_certification"
                      value={performanceData.signatures.rater_certification}
                      onChange={(e) =>
                        handleSignaturesChange(e, "rater_certification")
                      }
                    >
                      <option value="">Select Certification</option>
                      <option value="certification1">Certification 1</option>
                      <option value="certification2">Certification 2</option>
                    </Select>
                  </FormControl>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="blue" mr={3} onClick={handleSave}>
                Save
              </Button>
              <Button variant="ghost" onClick={handleClose}>
                Cancel
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        {/* Display all appraisal entries */}
        {allAppraisals.length > 0 && (
          <Box mt={4} overflowX={"auto"}>
            <TableContainer>
              <Table variant="simple" className="addEmployeeTableFormat">
                <Thead>
                  <Tr>
                    <Th width={"10%"} border="1px" borderColor="gray.200">
                      Reference Number
                    </Th>
                    <Th width={"10%"} border="1px" borderColor="gray.200">
                      Appraisal Type
                    </Th>
                    <Th width={"10%"} border="1px" borderColor="gray.200">
                      Issue Date
                    </Th>
                    <Th width={"10%"} border="1px" borderColor="gray.200">
                      Next Due Date
                    </Th>
                    <Th width={"10%"} border="1px" borderColor="gray.200">
                      Actions
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {allAppraisals.map((appraisal, index) => (
                    <Tr key={index}>
                      <Td>{appraisal.reference_number}</Td>
                      <Td>{appraisal.appraisal_type}</Td>
                      <Td>{appraisal.issue_date}</Td>
                      <Td>{appraisal.next_due_date}</Td>
                      <Td>
                        <IconButton
                          icon={<EditIcon />}
                          mr={2}
                          onClick={() => handleEdit(index)}
                        />
                        <IconButton
                          icon={<DeleteIcon />}
                          onClick={() => handleDelete(index)}
                        />
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
        )}

        {/* Modal to view detailed results */}
        {viewedAppraisal && (
          <Modal isOpen={isViewOpen} onClose={onViewClose} size="xl">
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Appraisal Details</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <TableContainer>
                  <Table variant="simple" className="modelFormFormatting">
                    <Thead>
                      <Tr style={{ background: "#338687" }}>
                        <Th>Performance Level</Th>
                        <Th>Criteria and Description</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      <Tr>
                        <Td>Significantly Above</Td>
                        <Td>
                          An evaluation resulting from overall performance which
                          is significantly above the performance standards of
                          the position.
                          <List styleType="disc" pl={4}>
                            {(
                              viewedAppraisal.levelsOfPerformance?.exceed || []
                            ).map((criteria) => (
                              <ListItem key={criteria}>
                                <strong>
                                  {criteria
                                    .replace(/([A-Z])/g, " $1")
                                    .toUpperCase()}
                                </strong>
                                : {criteriaDescriptions[criteria]}
                              </ListItem>
                            ))}
                          </List>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>Fully Meets</Td>
                        <Td>
                          An evaluation resulting from performance which fully
                          meets the performance standards of the position.
                          <List styleType="disc" pl={4}>
                            {(
                              viewedAppraisal.levelsOfPerformance?.proficient ||
                              []
                            ).map((criteria) => (
                              <ListItem key={criteria}>
                                <strong>
                                  {criteria
                                    .replace(/([A-Z])/g, " $1")
                                    .toUpperCase()}
                                </strong>
                                : {criteriaDescriptions[criteria]}
                              </ListItem>
                            ))}
                          </List>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>Barely Meets</Td>
                        <Td>
                          An evaluation resulting from performance which barely
                          meets the performance standards of the position. The
                          supervisor must contact the Division of Human
                          Resources to initiate a Performance Improvement Plan,
                          which must be completed jointly by the employee and
                          the supervisor.
                          <List styleType="disc" pl={4}>
                            {(
                              viewedAppraisal.levelsOfPerformance?.marginal ||
                              []
                            ).map((criteria) => (
                              <ListItem key={criteria}>
                                <strong>
                                  {criteria
                                    .replace(/([A-Z])/g, " $1")
                                    .toUpperCase()}
                                </strong>
                                : {criteriaDescriptions[criteria]}
                              </ListItem>
                            ))}
                          </List>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>Fails to Meet</Td>
                        <Td>
                          An evaluation resulting from performance which fails
                          to meet the minimum performance standards of the
                          position. The supervisor must contact the Division of
                          Human Resources to initiate a Performance Improvement
                          Plan, which must be completed jointly by the employee
                          and the supervisor.
                          <List styleType="disc" pl={4}>
                            {(
                              viewedAppraisal.levelsOfPerformance?.below || []
                            ).map((criteria) => (
                              <ListItem key={criteria}>
                                <strong>
                                  {criteria
                                    .replace(/([A-Z])/g, " $1")
                                    .toUpperCase()}
                                </strong>
                                : {criteriaDescriptions[criteria]}
                              </ListItem>
                            ))}
                          </List>
                        </Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </TableContainer>
              </ModalBody>
              <ModalFooter>
                <Button variant="ghost" onClick={onViewClose}>
                  Close
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        )}
      </Box>
    </ChakraProvider>
  );
};

export default AddPerformanceOne;
