import React, { useState, useEffect } from "react";
import NavigationMenu from "../Navigation/NavigationMenu";
import axios from "axios";

import {
  ChakraProvider,
  InputGroup,
  Input,
  InputRightElement,
  IconButton,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  FormControl,
  FormLabel,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
} from "@chakra-ui/react";

import {
  SearchIcon,
  ViewIcon,
  EditIcon,
  DeleteIcon,
  AddIcon,
} from "@chakra-ui/icons";

import "./AddHRPolicy.css";

const API_BASE_URL = "https://divine.backenddd.in";

const AddLicence = () => {
  const [name, setName] = useState("");
  const [doc, setDoc] = useState(null); // Changed initial state to null
  const [pubdate, setPubDate] = useState(null); // Changed initial state to null

  const [policies, setPolicies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refNumber, setRefNumber] = useState("");

  const [isOpenAddLicence, setIsOpenAddLicence] = useState(false);

  const resetFormAddLicence = () => {
    setName("");
    setDoc(null);
    setPubDate(null);
    setRefNumber("");
    setEditingIndexAddLicence(-1);
    setIsOpenAddLicence(false);
  };

  const fetchPolicies = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/Licence/`);
      setPolicies(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Failed to fetch policies", error);
      setLoading(false);
    }
  };
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedLicence, setSelectedLicence] = useState(null);
  // Create a new policy
  const createPolicy = async () => {
    const formData = new FormData();
    formData.append("title", name); // Corrected field name to 'title'
    formData.append("ref_number", refNumber); // Added ref_number
    formData.append("pub_date", pubdate); // Assuming pub_date is current date
    if (doc) {
      formData.append("doc", doc);
    } else {
      return;
    }

    try {
      const response = await axios.post(
        `https://divine.backenddd.in/Licence/AddLicence/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      // console.log("Policy Created:", response.data);
      fetchPolicies(); // Refresh the list
      resetFormAddLicence();
    } catch (error) {
      console.error("Failed to create policy", error);
    }
  };

  // Update an existing policy
  const updatePolicy = async (id, policyData) => {
    try {
      // Ensure "ref_number" is included in the policyData
      if (!policyData.ref_number) {
        throw new Error("Reference number is required.");
      }

      const response = await axios.put(
        `${API_BASE_URL}/Licence/UpdateLicence/${id}/`,
        policyData
      );
      // console.log("Policy Updated:", response.data);
      fetchPolicies(); // Refresh the list
    } catch (error) {
      console.error("Failed to update policy", error);
      // Handle error gracefully, display error message to the user
    }
  };

  // Delete a policy
  const deletePolicy = async (id) => {
    try {
      const response = await axios.delete(
        `${API_BASE_URL}/Licence/DeleteLicence/${id}/`
      );
      // console.log("Policy Deleted:", response.data);
      fetchPolicies(); // Refresh the list
    } catch (error) {
      console.error("Failed to delete policy", error);
    }
  };

  // Use Effect to load data on component mount
  useEffect(() => {
    fetchPolicies();
  }, []);

  const handleSearchAddLicence = () => {
    // Define what happens when you search
    console.log("Searching...");
  };
  const {
    isOpen,
    onOpen: onOpenAddLicence,
    onClose: onCloseAddLicence,
  } = useDisclosure();

  const [policiesAddLicence, setPoliciesAddLicence] = useState([]);
  const [currentPolicyAddLicence, setCurrentPolicyAddLicence] = useState({
    name: "",
    file: null,
  });
  const [editingIndexAddLicence, setEditingIndexAddLicence] = useState(-1); // -1 when not editing

  const handleFileChangeAddLicence = (e) => {
    setCurrentPolicyAddLicence((prev) => ({
      ...prev,
      file: e.target.files[0],
    }));
  };

  const savePolicyAddLicence = () => {
    const newPolicy = {
      ...currentPolicyAddLicence,
      id:
        editingIndexAddLicence === -1
          ? policiesAddLicence.length + 1
          : policiesAddLicence[editingIndexAddLicence].id,
      createDate:
        editingIndexAddLicence === -1
          ? new Date()
          : policiesAddLicence[editingIndexAddLicence].createDate,
    };

    let updatedPoliciesAddLicence = [...policiesAddLicence];
    if (editingIndexAddLicence === -1) {
      updatedPoliciesAddLicence.push(newPolicy);
    } else {
      updatedPoliciesAddLicence[editingIndexAddLicence] = newPolicy;
    }

    setPoliciesAddLicence(updatedPoliciesAddLicence);
    resetFormAddLicence();
  };

  const handleEditAddLicence = (index) => {
    console.log("Selected index:", index);
    console.log("Policies at the time of edit:", policies);

    if (index >= 0 && index < policies.length) {
      const policy = policies[index];
      setName(policy.title); // Set name in state
      setDoc(policy.doc); // Set document in state, assuming `policy.doc` holds the document info

      setEditingIndexAddLicence(index);
      onOpenAddLicence();
    } else {
      console.error("Invalid index or empty policies array");
    }
    // setEditingIndexAddLicence(index);
    // onOpenAddLicence();
  };

  const handleDeleteAddLicence = (index) => {
    setPoliciesAddLicence(policiesAddLicence.filter((_, i) => i !== index));
  };

  const handleCloseEditModal = () => {
    setSelectedLicence(null);
    setIsEditModalOpen(false);
  };

  const formatDate = (dateString) => {
    if (!dateString) return "No date provided"; // Fallback text or logic
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
  };

  const handleEdit = (policy) => {
    setSelectedLicence(policy);
    setIsEditModalOpen(true);
  };

  const handleSaveEdit = async () => {
    const formData = new FormData();
    formData.append("title", selectedLicence.title); // Assuming 'title' is the state for the policy name
    formData.append("ref_number", selectedLicence.ref_number); // Assuming 'ref_number' is the state for the reference number
    formData.append("pub_date", selectedLicence.pub_date); // Assuming 'pub_date' is the state for the publication date
    if (selectedLicence.doc) {
      formData.append("doc", selectedLicence.doc); // Assuming 'doc' is the state containing the file
    } else {
      formData.append("doc", "");
    }

    try {
      await axios.put(
        `${API_BASE_URL}/Licence/UpdateLicence/${selectedLicence.id}/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setIsEditModalOpen(false);

      fetchPolicies();
    } catch (error) {
      console.error("Error updating policy:", error);
    }
  };

  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleSearch = async () => {
    try {
      const response = await axios.get(
        `https://divine.backenddd.in/Licence/SearchLicence/?q=${inputValue}/`
      );
      setPolicies(response.data);
    } catch (error) {
      console.error("Failed to search policies", error);
    }
  };

  const [userStatus, setUserStatus] = useState(false);
  const [isSuperuser, setIsSuperUser] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get("https://divine.backenddd.in/HumanResource/user-status/");
        if (response.status === 200) {
          // console.log("Response: ", response.data)
          setUserStatus(response.data);
          setIsSuperUser(response.data.is_superuser);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, []);

  return (
    <>
      <NavigationMenu />
      <ChakraProvider>
        <div className="addLicenceWrapper">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-md-12 col-lg-12">
                <div className="addLicenceHeading">
                  <h1>Licence Document</h1>
                  <p>
                    Guidelines for <span> Employee Management </span>and
                    Workplace Conduct
                  </p>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md-4 col-lg-4">
                <InputGroup className="LicenceSearchInput">
                  <Input
                    placeholder="Search Licence"
                    onChange={handleInputChange}
                  />
                  <InputRightElement>
                    <IconButton
                      aria-label="Search database"
                      icon={<SearchIcon />}
                      onClick={handleSearch}
                      size="sm"
                    />
                  </InputRightElement>
                </InputGroup>
              </div>

              <div className="col-12 col-md-4 col-lg-4">
                <Button colorScheme="blue" onClick={handleSearch}>Search</Button>
              </div>

              <div className="col-12 col-md-4 col-lg-4">
                <Button
                  leftIcon={<AddIcon />}
                  colorScheme="blue"
                  onClick={() => {
                    setIsOpenAddLicence(true); // Trigger the opening of the modal
                  }}
                >
                  Add Licence
                </Button>
                <Modal
                  isOpen={isOpenAddLicence}
                  onClose={resetFormAddLicence}
                >
                  <ModalOverlay />
                  <ModalContent>
                    <ModalHeader>
                      {editingIndexAddLicence === -1
                        ? "Create Licence"
                        : "Edit Licence"}
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                      <FormControl>
                        <FormLabel mt={4}>Reference Number</FormLabel>
                        <Input
                          value={refNumber}
                          onChange={(e) => setRefNumber(e.target.value)}
                        />
                        <FormLabel>Title of Licence</FormLabel>
                        <Input
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />

                        <FormLabel mt={4}>Documents</FormLabel>
                        <Input
                          type="file"
                          onChange={(e) => setDoc(e.target.files[0])}
                        />
                        <FormLabel mt={4}>Date of Licence</FormLabel>
                        <Input
                          type="date"
                          onChange={(e) => setPubDate(e.target.value)}
                        />
                      </FormControl>
                    </ModalBody>

                    <ModalFooter>
                      <Button
                        colorScheme="blue"
                        mr={3}
                        onClick={async () => {
                          await createPolicy();
                          resetFormAddLicence();
                        }}
                      >
                        Save
                      </Button>
                    </ModalFooter>
                  </ModalContent>
                </Modal>
              </div>
            </div>

            <Modal isOpen={isEditModalOpen} onClose={handleCloseEditModal}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Edit Licence</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <FormControl>
                    <FormLabel>Licence Name</FormLabel>
                    <Input
                      type="text"
                      value={selectedLicence ? selectedLicence.title : ""}
                      onChange={(e) =>
                        setSelectedLicence({
                          ...selectedLicence,
                          title: e.target.value,
                        })
                      }
                    />
                    <FormLabel mt={4}>Reference Number</FormLabel>
                    <Input
                      type="text"
                      value={
                        selectedLicence ? selectedLicence.ref_number : ""
                      }
                      onChange={(e) =>
                        setSelectedLicence({
                          ...selectedLicence,
                          ref_number: e.target.value,
                        })
                      }
                    />
                    <FormLabel mt={4}>Publication Date</FormLabel>
                    <Input
                      type="date"
                      value={
                        selectedLicence ? selectedLicence.pub_date : ""
                      }
                      onChange={(e) =>
                        setSelectedLicence({
                          ...selectedLicence,
                          pub_date: e.target.value,
                        })
                      }
                    />
                    <FormLabel mt={4}>Attach Document</FormLabel>
                    <Input
                      type="file"
                      onChange={(e) =>
                        setSelectedLicence({
                          ...selectedLicence,
                          doc: e.target.files[0],
                        })
                      }
                    />
                  </FormControl>
                </ModalBody>
                <ModalFooter>
                  <Button colorScheme="blue" onClick={handleSaveEdit}>
                    Save
                  </Button>
                  <Button onClick={handleCloseEditModal}>Cancel</Button>
                </ModalFooter>
              </ModalContent>
            </Modal>

            <div className="addLicenceViewWrapper">
              <div className="row">
                <div className="col-12 col-md-12 col-lg-12">
                  <Box overflowX={"auto"}>
                    <Table
                      variant="simple"
                      mt={4}
                      className="LicenceTableFormat"
                    >
                      <Thead>
                        <Tr>
                          <Th width={"25%"}>Sr. No.</Th>
                          <Th width={"25%"}>Reference Number</Th>
                          <Th width={"20%"}>Title of Licence</Th>
                          <Th width={"20%"}>Date of Licence</Th>
                          <Th width={"5%"}>Documents</Th>
                          {isSuperuser && (
                            <Th width={"20%"}>Actions</Th>
                          )}
                        </Tr>
                      </Thead>
                      <Tbody>
                        {policies.map((policy, index) => (
                          <Tr key={index}>
                            <Td>{index+1}</Td>
                            <Td>{policy.ref_number}</Td>
                            <Td>{policy.title}</Td>
                            <Td>{formatDate(policy.pub_date)}</Td>

                            <Td>
                              <IconButton
                                icon={<ViewIcon />}
                                aria-label="View Document"
                                onClick={() =>
                                  window.open(
                                    `https://divine.backenddd.in${policy.doc}/`,
                                    "_blank"
                                  )
                                }
                              />
                            </Td>
                            {isSuperuser && (
                            <Td>
                              <IconButton
                                icon={<EditIcon />}
                                aria-label="Edit"
                                mx={2}
                                onClick={() => handleEdit(policy)} // This should prepare the form
                              />
                              <IconButton
                                icon={<DeleteIcon />}
                                aria-label="Delete"
                                onClick={() => deletePolicy(policy.id)}
                              />
                            </Td>
                            )}
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ChakraProvider>
    </>
  );
};

export default AddLicence;
