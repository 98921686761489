import {
  Box,
  Flex,
  Avatar,
  HStack,
  IconButton,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useDisclosure,
  useColorModeValue,
  Stack,
  ChakraProvider,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import "./NavigationMenu.css";
import logoImg from "../../assets/divine-logo.png";
import profileImg from "../../assets/niksonsir.jpg";


const NavLink = ({ children, to }) => (
  <RouterLink
    to={to}
    style={{
      padding: "8px 16px",
      borderRadius: "md",
      textDecoration: "none",
      display: "flex",
      alignItems: "center",
      color: "inherit", // Ensures text color matches your design
    }}
    _hover={{
      textDecoration: "none",
      backgroundColor: useColorModeValue("gray.200", "gray.700"),
    }}
  >
    {children}
  </RouterLink>
);

export default function NavigationMenu() {
  const navigate = useNavigate();
  const handleSignOut = () => {
    // Clear the access token from localStoragefetchUserStatus
    localStorage.removeItem("access_token");

    // Redirect to the home page
    navigate("/");
  };


  const {
    isOpen: isOpenNavigationMenu,
    onOpen: onOpenNavigationMenu,
    onClose: onCloseNavigationMenu,
  } = useDisclosure();


  return (
    <>
      <ChakraProvider>
        <Flex
          h={16}
          alignItems={"center"}
          justifyContent={"space-between"}
          className="navFirst"
        >
          <IconButton
            size={"md"}
            icon={isOpenNavigationMenu ? <CloseIcon /> : <HamburgerIcon />}
            aria-label={"Open Menu"}
            display={{ md: "none" }}
            onClick={
              isOpenNavigationMenu
                ? onCloseNavigationMenu
                : onOpenNavigationMenu
            }
          />
          <HStack alignItems={"center"}>
            <Box>
              <div className="logoImgDiv">
                <img src={logoImg} alt="" />
                <h4>Divine Medical Mission Hospital</h4>
              </div>
            </Box>
          </HStack>

          <Flex className="profileNav">
            <Menu>
              
              <MenuButton
                as={Button}
                rounded={"full"}
                variant={"link"}
                cursor={"pointer"}
                minW={0}
              >
                <Avatar
                  size={"sm"}
                  src={profileImg}
                />
              </MenuButton>
              <MenuList>
                <MenuItem onClick={handleSignOut}>Sign Out</MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        </Flex>

        <div className="navSecond">
          <nav className="navbar navbar-expand-lg">
            <div className="container-fluid">
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNavDropdown"
                aria-controls="navbarNavDropdown"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>

              <div className="collapse navbar-collapse" id="navbarNavDropdown">
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <NavLink to="/dashboard">
                      {" "}
                      <span>Dashboard</span>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/addemployee">
                      {" "}
                      <span>Employee</span>
                    </NavLink>
                  </li>

                  <li className="nav-item">
                    <NavLink to="/searchemployee">
                      {" "}
                      <span>Search</span>
                    </NavLink>
                  </li>

                  <li className="nav-item">
                    <NavLink to={"/addleaverequest"}>
                      {" "}
                      <span>Leave</span>
                    </NavLink>
                  </li>

                  <li className="nav-item">
                    <NavLink to={"/addhrpolicy"}>
                      {" "}
                      <span>HR Policy</span>
                    </NavLink>
                  </li>

                  <li className="nav-item">
                    <NavLink to={"/addnabhpolicy"}>
                      {" "}
                      <span>Nabh Policy</span>
                    </NavLink>
                  </li>

                  <li className="nav-item">
                    <NavLink to={"/addSalary"}>
                      {" "}
                      <span>Salary</span>
                    </NavLink>
                  </li>


                  <li className="nav-item">
                    <NavLink to={"/licencepage"}>
                      {" "}
                      <span>Licence</span>
                    </NavLink>
                  </li>

                  <li className="nav-item">
                    <NavLink to={"/issueformat"}>
                      {" "}
                      <span>Issue Format</span>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to={"/addformat"}>
                      {" "}
                      <span>Add Formats</span>
                    </NavLink>
                  </li>

                  <li className="nav-item">
                    <NavLink to={"/addcircular"}>
                      {" "}
                      <span>Circular</span>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to={"/addsignature"}>
                      {" "}
                      <span>Signature</span>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to={"/addmaster"}>
                      {" "}
                      <span>Master</span>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to={"/addDocument"}>
                      {" "}
                      <span>Add</span>
                    </NavLink>
                  </li> 
                  {/* <li className="nav-item">
                    <NavLink to={"/addnabhpolicy"}>
                      {" "}
                      <span> Nabh Policy</span>
                    </NavLink>
                  </li> */}
                 
                </ul>
              </div>
            </div>
          </nav>
        </div>

        {isOpenNavigationMenu ? (
          <Box display={{ md: "none" }} className="topMobileNav">
            <Stack as={"nav"} className="mobileNav">
              <NavLink to="/dashboard">Dashboard</NavLink>
              <NavLink to="/addemployee">Employee</NavLink>
              <NavLink to="/searchemployee">Search</NavLink>
              <NavLink to={"/addleaverequest"}>Leave </NavLink>
              <NavLink to={"/addhrpolicy"}>HR Policy & Manuals</NavLink>
              <NavLink to={"/addformat"}>Add Formats</NavLink>
              <NavLink to={"/issueformat"}>Issue Format</NavLink>
              <NavLink to={"/addcircular"}>Circular</NavLink>
              <NavLink to={"/add"}>Add</NavLink>
              <NavLink to={"/addnabhpolicy"}>Nabh Policy</NavLink>
            </Stack>
          </Box>
        ) : null}
      </ChakraProvider>
    </>
  );
}
