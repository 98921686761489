import React, { useState, useEffect } from "react";
import {
  Button,
  Input,
  FormControl,
  FormLabel,
  Select,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  ChakraProvider,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";

const AddSignatureOne = ({ onSignatureAdded }) => {
  const [staff, setStaff] = useState("");
  const [position, setPosition] = useState("");
  const [signature, setSignature] = useState(null);
  const [positions, setPositions] = useState([]);
  
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  useEffect(() => {
    fetchPositions();
  }, []);

  const fetchPositions = async () => {
    try {
      const response = await axios.get(
        "https://divine.backenddd.in/HumanResource/api/positions/"
      );
      setPositions(response.data);
    } catch (error) {
      console.error("Error fetching positions:", error);
      toast({
        title: "Error fetching positions.",
        description: "Unable to fetch positions data.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSignature(file);
  };

  const addEntry = async () => {
    if (!staff || !signature || !position) {
      toast({
        title: "Error",
        description: "All fields are required.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    const formData = new FormData();
    formData.append("staff", staff);
    formData.append("position", position);
    formData.append("signature", signature);

    try {
      const response = await axios.post(
        "https://divine.backenddd.in/HumanResource/signatures/create/",
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );
      toast({
        title: "Entry created.",
        description: "Your entry has been created successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setStaff("");
      setPosition("");
      setSignature(null);
      onClose();
      onSignatureAdded(); // Notify parent component about the new signature
    } catch (error) {
      console.error("Error adding entry:", error);
      toast({
        title: "Error adding entry.",
        description: "There was an error adding your entry.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <ChakraProvider>
        <Button onClick={onOpen} colorScheme="blue" mb={4}>
          Add Signature
        </Button>

        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Add New Entry</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormControl isRequired mb={4}>
                <FormLabel>Name of Person</FormLabel>
                <Input
                  placeholder="Enter name"
                  value={staff}
                  onChange={(e) => setStaff(e.target.value)}
                />
              </FormControl>

              <FormControl isRequired mb={4}>
                <FormLabel>Designation</FormLabel>
                <Select
                  placeholder="Select designation"
                  value={position}
                  onChange={(e) => setPosition(e.target.value)}
                >
                  {positions &&
                    positions.map((pos, index) => (
                      <option key={index} value={pos.name}>
                        {pos.name}
                      </option>
                    ))}
                </Select>
              </FormControl>

              <FormControl isRequired mb={4}>
                <FormLabel>Upload Signature</FormLabel>
                <Input
                  type="file"
                  accept="image/*"
                  onChange={handleFileChange}
                />
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <Button colorScheme="blue" onClick={addEntry}>
                Create
              </Button>
              <Button variant="ghost" onClick={onClose}>
                Cancel
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </ChakraProvider>
    </>
  );
};

export default AddSignatureOne;
