import { React, useEffect, useState } from "react";
import NavigationMenu from "../Navigation/NavigationMenu";
import { useNavigate } from "react-router-dom";

import {
  Box,
  Button,
  ChakraProvider,
  Select,
  InputGroup,
  InputRightElement,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";
import "./Dashboard.css";

import empImage from "../../assets/icon1.png";
import empImage2 from "../../assets/icon2.png";

const Dashboard = () => {
  const [userData, setUserData] = useState([]);
  const [totalStaff, setTotalStaff] = useState([]);
  const [totalleave, setTotalLeave] = useState([]);

  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState("");

  const navigate = useNavigate();
  const [human_res, setHumanResourcePolicy] = useState([]);
  const [selectedHRPolicy, setSelectedHRPolicy] = useState("");

  useEffect(() => {
    // Fetch department data from your backend API
    const fetchDepartments = async () => {
      const accessToken = localStorage.getItem("access_token");

      try {
        const response = await fetch(
          "https://divine.backenddd.in/HumanResource/dashboard-user-status/",
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch dashboard data");
        }
        const data = await response.json();
        console.log("Fetched Data: ", data);
        setUserData(data);
        setTotalStaff(data.total_staff);
        setTotalLeave(data.total_leave_request);
        setHumanResourcePolicy(data.human_res || []);
        setDepartments(data.dept_5);
      } catch (error) {
        console.error("Error fetching dashboard data:", error);
      }
    };

    fetchDepartments();
  }, []);

  const handleDepartmentChange = (event) => {
    setSelectedDepartment(event.target.value);
  };

  const handleHRPolicyChange = (event) => {
    setSelectedHRPolicy(event.target.value);
  };

  const handleDeptSearch = () => {
    // Navigate with state
    navigate("/searchemployee", { state: { department: selectedDepartment } });
  };

  const handleHRPolicySearch = () => {
    // Navigate with state
    navigate("/addhrpolicy", { state: { hrpolicysearch: selectedHRPolicy } });
  };

  return (
    <>
      <NavigationMenu />
      <ChakraProvider>
        <Box className="dashboard">
          <div className="dashBoardContent">
            <div className="dashBoardContentOne">
              <div className="row">
                <div className="col-12 col-md-6 col-lg-6">
                  <div className="dashboardLeftDiv">
                    <div className="hospitalInformation">

                      <table border="1" className="dashboardTable">
                        <thead>
                          <tr>
                            <th colSpan={2}>Hospital Information </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <strong>Hospital Name:</strong>{" "}
                            </td>
                            <td>Divine Medical Mission Hospital</td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Address:</strong>{" "}
                            </td>
                            <td>Vadakkencherry, Palakkad, Kerala</td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Country</strong>
                            </td>
                            <td>India</td>
                          </tr>
                        </tbody>
                      </table>
                      
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-6">
                  <div className="dashboardRightDiv">
                    <div className="departmentSelect">
                      <FormControl>
                        <FormLabel
                          className="dashboardLabel"
                          htmlFor="department"
                        >
                          Departments
                        </FormLabel>
                        <InputGroup>
                          <Select
                            placeholder="Select Department"
                            value={selectedDepartment}
                            onChange={handleDepartmentChange}
                          >
                            {departments.map((department) => (
                              <option key={department.id} value={department.id}>
                                {department.name}
                              </option>
                            ))}
                          </Select>
                          <InputRightElement width="4.5rem">
                            <Button
                              h="1.75rem"
                              size="sm"
                              className="dboardSearch"
                              onClick={handleDeptSearch}
                            >
                              Search
                            </Button>
                          </InputRightElement>
                        </InputGroup>
                      </FormControl>
                    </div>
                    <div className="policySelect">
                      <FormControl>
                        <FormLabel
                          className="dashboardLabel"
                          htmlFor="department"
                        >
                          HR Policy & Manuals
                        </FormLabel>
                        <InputGroup>
                          <Select
                            placeholder="Select HR Policy"
                            value={selectedHRPolicy}
                            onChange={handleHRPolicyChange}
                          >
                            {human_res.map((department) => (
                              <option key={department.id} value={department.id}>
                                {department.name}
                              </option>
                            ))}
                          </Select>
                          <InputRightElement width="4.5rem">
                            <Button
                              h="1.75rem"
                              className="dboardSearch"
                              size="sm"
                              onClick={handleHRPolicySearch}
                            >
                              Search
                            </Button>
                          </InputRightElement>
                        </InputGroup>
                      </FormControl>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div class="dashboardParent">
                  <div class="dashboardChild">
                    <img src={empImage} alt="Description" />
                    <div class="dashboardChildText">
                      <h2>{totalStaff}</h2>
                      <p>Total Employee</p>
                    </div>
                  </div>
                  <div class="dashboardChild">
                    <img src={empImage2} alt="Description" />
                    <div class="dashboardChildText">
                      <h2>{totalleave}</h2>
                      <p>Today Leave Count</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </ChakraProvider>
    </>
  );
};

export default Dashboard;
