import React, { useState, useRef, useEffect } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useToast } from "@chakra-ui/react"; // Import useToast from Chakra UI
import { useNavigate } from "react-router-dom";
import NavigationMenu from "../Navigation/NavigationMenu";
import axios from "axios";
import {
  ChakraProvider,
  FormControl,
  FormLabel,
  Input,
  Button,
  Select,
} from "@chakra-ui/react";

import "./AddFormat.css";
import ViewFormat from "./ViewFormat";

export default function AddFormat() {
  const [certificateContent, setCertificateContent] = useState("");
  const [certificateName, setCertificateName] = useState("");
  const [certificateSiganture, setSignature] = useState("");

  const [entries, setEntries] = useState("");
  const [letters, setLetters] = useState([]);


  const toast = useToast();
  const navigate = useNavigate();

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setCertificateContent(data);
  };

  const handleCertificateNameChange = (event) => {
    setCertificateName(event.target.value);
  };

  const handleCertificateSignatureChange = (event) => {
    setSignature(event.target.value);
  };

  useEffect(() => {
    fetchEntries();
    
  }, []);

  const fetchEntries = async () => {
    try {
      const response = await axios.get(
        "https://divine.backenddd.in/HumanResource/signatures/"
      );
      setEntries(response.data);
    } catch (error) {
      console.error("Error fetching entries:", error);
    }
  };

  const handleSave = async () => {
    try {
      const response = await fetch(
        "https://divine.backenddd.in/Letter/api/save_certificate/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: certificateName,
            content: certificateContent,
            signature: certificateSiganture,
          }),
        }
      );
  
      const data = await response.json();
  
      if (data.name !== "certificates with this name already exists.") {
        console.log("Certificate saved successfully");
  
        // Trigger success toast message
        toast({
          title: "Certificate saved",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
  
        // Fetch the updated entries
        await fetchEntries();
      } else {
        console.error(
          "certificates with this name already exists.:",
          data.errors
        );
  
        // Show generic error toast
        toast({
          title: "Error saving certificate",
          description: "certificates with this name already exists.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error saving certificate:", error);
  
      // Show generic error toast
      toast({
        title: "Error saving certificate",
        description: "An error occurred while saving the certificate.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const [value, setValue] = useState("");

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <>
      <NavigationMenu />
      <ChakraProvider>
        <div className="addFormatWrapper">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-md-12 col-lg-12">
                <div className="addFormatHeading">
                  <h1>Add Format</h1>
                  <p>
                    Structured Format for Official
                    <span> Employment Documentation </span> and Correspondence
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-4 col-lg-4">
                <FormControl isRequired className="addEmployeeFormGroup">
                  <FormLabel htmlFor="inputField">Format Name</FormLabel>
                  <Input
                    id="certificateName"
                    type="text"
                    name="format_name"
                    value={certificateName}
                    onChange={handleCertificateNameChange}
                    className="addFormatInputField"
                    placeholder="Enter Format Name"
                  />
                </FormControl>
              </div>
              <div className="col-12 col-md-4 col-lg-4">
                <FormControl isRequired className="addEmployeeFormGroup">
                  <FormLabel htmlFor="inputField">Select Signature</FormLabel>
                  <Select
                    id="country"
                    value={certificateSiganture}
                    onChange={handleCertificateSignatureChange}
                    placeholder="Select Signature"
                  >
                    {entries &&
                      entries.map((enter, index) => (
                        <>
                          <option key={index} value={enter.id}>
                            {enter.staff}-{enter.id}
                          </option>
                        </>
                      ))}
                    {/* <option>Nikson</option>
                    <option>Mexico</option>
                    <option>Kingdom</option> */}
                  </Select>
                </FormControl>
              </div>
              <div className="col-12 col-md-9 col-lg-9">
                <FormControl isRequired className="addEmployeeFormGroup">
                  <FormLabel htmlFor="inputField">Format Content</FormLabel>

                  <CKEditor
                    className="formatCkEditor"
                    editor={ClassicEditor}
                    onChange={handleEditorChange}
                    config={{
                      toolbar: [
                        "heading",
                        "|",
                        "bold",
                        "italic",
                        "link",
                        "bulletedList",
                        "numberedList",
                        "blockQuote",
                        "|",
                        "undo",
                        "redo",
                      ],
                      // Removed CKFinder configuration to disable media uploads
                    }}
                  />
                </FormControl>
              </div>

              <div className="my-4 col-12 col-md-12 col-lg-12">
                <Button colorScheme="teal" onClick={handleSave}>
                  Create Format
                </Button>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-12 col-lg-12">
                <ViewFormat />
              </div>
            </div>
          </div>
        </div>
      </ChakraProvider>
    </>
  );
}
