import React, { useState, useEffect } from "react";
import NavigationMenu from "../Navigation/NavigationMenu";
import axios from "axios";
import Select from "react-select";
import { Link } from "react-router-dom";
import {
  Input,
  ChakraProvider,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  // Select,
  Textarea,
  Table,
  Thead,
  Icon,
  Tbody,
  Tr,
  Th,
  Td,
  FormLabel,
  Flex,
  Image,
  FormControl,
  Box,
  Stack,
} from "@chakra-ui/react";

import { Select as ChakraSelect } from "@chakra-ui/react";
import { ChevronDownIcon, ChevronUpIcon, AddIcon, EditIcon } from "@chakra-ui/icons";
import { DatePicker } from "antd";
import "./AddLeaveRequest.css";
import EditLeaveRequest from "./EditLeaveRequest";


// import { SearchIcon, ViewIcon, EditIcon, DeleteIcon } from "@chakra-ui/icons";

const AddLeaveRequest = () => {
  const handleSearch = () => {
    // Define what happens when you search
    console.log("Searching...");
  };

  const [isOpen, setIsOpen] = useState(false);
  const [leaveCategory, setLeaveCategory] = useState("");
  const [leaveReason, setLeaveReason] = useState("");
  const [staffID, setStaffID] = useState("");
  const [filterStatus, setFilterStatus] = useState("");
  const [filterStatusname, setFilterStatusName] = useState("");

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [leaveState, setLeaveState] = useState("");
  const [editIndex, setEditIndex] = useState(null);
  const [leaveRecords, setLeaveRecords] = useState([]);
  const [employee, setEmployee] = useState([]);
  const [staff, setStaff] = useState([]);
  const [searchDate, setSearchDate] = useState([]);
  const [searchToDate, setSearchToDate] = useState([]);

  const [leaveRequest, setLeaveRequests] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState(null);

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [currentLeaveRequest, setCurrentLeaveRequest] = useState(null);

  const [leaveCounts, setLeaveCounts] = useState({
    yesterday: 0,
    today: 0,
    tomorrow: 0,
    absent: 0,
  });

  useEffect(() => {
    const accessToken = localStorage.getItem("access_token");
    const fetchLeaveCounts = async () => {
      try {
        const response = await axios.get(
          "https://divine.backenddd.in/Leave/leave-counts/",
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        setLeaveCounts(response.data);
      } catch (error) {
        console.error("Error fetching leave counts:", error);
      }
    };

    fetchLeaveCounts();
  }, []);

  const leaveCategories = [
    "Annual Leave",
    "Sick Leave",
    "Maternity Leave",
    "Monthly Off",
    "Casual Leave",
    "Privilege Leave",
    "Absent",
  ];
  const leaveStates = ["Pending", "Approved", "Rejected"];

  const handleAddLeaveRecord = async () => {
    const newLeaveRecord = {
      staffID,
      leaveCategory,
      leaveReason,
      fromDate,
      toDate,
      leaveState,
    };

    // console.log("Staff ID: ", staffID);
    // console.log("Leave Category: ", leaveCategory);
    // console.log("Leave Reason: ", leaveReason);
    // console.log("Leave Reason: ", fromDate);
    // console.log("Leave Reason: ", toDate);
    // console.log("Leave Reason: ", leaveState);

    const formData = {
      staff_id: selectedStaff,
      start_date: fromDate,
      end_date: toDate,
      leave_type: leaveCategory,
      reason: leaveReason,
      status: leaveState,
    };

    const accessToken = localStorage.getItem("access_token");

    try {
      const response = await axios.post(
        "https://divine.backenddd.in/Leave/api/save-leave-request/",
        formData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.status === 200) {
        // console.log("Leave request submitted:", response.data);
        alert("Saved");
        fetchLeaveRequests();

        setLeaveCategory("");
        setLeaveReason("");
        setFromDate("");
        setToDate("");
        setLeaveState("");
        setIsOpen(false);
      }
    } catch (error) {
      console.error("Error:", error);
    }

    if (editIndex !== null) {
      const updatedLeaveRecords = [...leaveRecords];
      updatedLeaveRecords[editIndex] = newLeaveRecord;
      setLeaveRecords(updatedLeaveRecords);
      setEditIndex(null);
    } else {
      setLeaveRecords([...leaveRecords, newLeaveRecord]);
    }

    // Clear input fields after adding or editing leave record
    setLeaveCategory("");
    setLeaveReason("");
    setFromDate("");
    setToDate("");
    setLeaveState("");
    setIsOpen(false);
  };

  const token = localStorage.getItem("access_token");

  const fetchLeaveRequests = async () => {
    const accessToken = localStorage.getItem("access_token");

    try {
      const response = await axios.get(
        "https://divine.backenddd.in/Leave/api/leaves/",
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      // setLeaveRequests(response.data); // Update state with fetched data
      setLeaveRequests(response.data);
      // countLeaveRequests();
    } catch (error) {
      console.error("Error fetching leave requests:", error);
    }
  };

  const fetchUserData = async (staffID) => {
    try {
      const response = await axios.get(
        `https://divine.backenddd.in/HumanResource/getUserFromStaffID/${staffID}/`
      );
      setEmployee(response.data);
      // console.log("Fetched user data:", response.data);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    fetchLeaveRequests();
    fetchData();
  }, []);

  const [expandedRowSearchEmployee, setExpandedRowSearchEmployee] =
    useState(null);

  const toggleExpandSearchEmployee = (id) => {
    setExpandedRowSearchEmployee(expandedRowSearchEmployee === id ? null : id);
  };

  const fetchData = async () => {
    try {
      if (token) {
        const response = await axios.get(
          "https://divine.backenddd.in/HumanResource/StaffList/",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setStaff(response.data);
      } else {
        console.error("Access token not found");
      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };

  const fetchfilteredLeaveRequests = async () => {
    const accessToken = localStorage.getItem("access_token");
    console.log("Date Search: ", searchDate);
    try {
      const response = await axios.get(
        `https://divine.backenddd.in/Leave/api/searched_date/`,
        {
          params: {
            search_date: searchDate,
            search_to_date: searchToDate,

            status: filterStatus,
            filter_status_name: filterStatusname,
          },
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setLeaveRequests(response.data);
    } catch (error) {
      console.error("Error fetching leave requests:", error);
    }
  };

  const handleDateFilter = async (date) => {
    const token = localStorage.getItem("access_token");

    try {
      const response = await axios.get(
        `https://divine.backenddd.in/Leave/api/Filtered_leave_requests_by_date_view/${date}/`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log("Leave Requests: ", response.data);
      setLeaveRequests(response.data);
      // Handle the response data as needed, e.g., set it to state
    } catch (error) {
      console.error("Error fetching leave requests:", error);
    }
  };

  const staffOptions = staff.map((category) => ({
    value: category.staff_id,
    label: `${category.fname} ${category.lname} - ${category.staff_id}`,
  }));

  const handleStaffChange = (selectedOption) => {
    // Assuming you are making an API call here or setting the state to be sent to backend
    const staffId = selectedOption ? selectedOption.value : null;
    console.log("Selected Staff ID:", staffId);
    setSelectedStaff(staffId);
    // Send staffId to the backend
  };

  const selectedStaffOption = staffOptions.find(
    (option) => option.value === selectedStaff
  );

  const staffSearchOptions = staff.map((category) => ({
    value: category.staff_id,
    label: `${category.fname} ${category.lname} - ${category.staff_id}`,
  }));

  const handleSearchStaffChange = (selectedOption) => {
    const staffId = selectedOption ? selectedOption.value : null;
    console.log("Selected Staff ID:", staffId);
    setFilterStatusName(staffId);
    // Send staffId to the backend
  };

  const selectedSearchStaffOption = staffSearchOptions.find(
    (option) => option.value === filterStatusname
  );

  const resetForm = () => {
    setLeaveCategory("");
    setLeaveReason("");
    setFromDate("");
    setToDate("");
    setLeaveState("");
    setSelectedStaff(null);
    setIsOpen(false);
  };

  const handleUpdateLeaveRequest = async () => {
    const formData = {
      leave_type: leaveCategory,
      reason: leaveReason,
      start_date: fromDate,
      end_date: toDate,
      status: leaveState,
    };

    try {
      const response = await axios.post(
        `https://divine.backenddd.in/Leave/api/leave-requests/${currentLeaveRequest.id}/update-status/`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );

      if (response.status === 200) {
        alert("Updated");
        fetchLeaveRequests();
        resetForm();
        setIsEditModalOpen(false);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };


  const handleEditClick = (leaveRequest) => {
    setCurrentLeaveRequest(leaveRequest);
    setLeaveCategory(leaveRequest.leave_type);
    setLeaveReason(leaveRequest.reason);
    setFromDate(leaveRequest.start_date);
    setToDate(leaveRequest.end_date);
    setLeaveState(leaveRequest.status);
    setIsEditModalOpen(true);
  };

  return (
    <>
      <NavigationMenu />

      <ChakraProvider>
        <div className="leaveRequestWrapper">
          <div className="container-fluid">
            {/* ***********************************  Search Leave Status *********************** */}

            <div className="row">
              <div className="col-12 col-md-4 col-lg-4">
                <div className="leaveRequestHeading">
                  <h1 style={{ paddingTop: "15px", paddingBottom: "15px" }}>
                    View Leave Request
                  </h1>
                  <>
                    <Button
                      style={{ marginRight: "10px" }}
                      colorScheme="blue"
                      leftIcon={<AddIcon />}
                      backgroundColor={"#338687"}
                      onClick={() => setIsOpen(true)}
                    >
                      Add Leave Request
                    </Button>{" "}
                    {/* <Link to="/leaveSummaryPage">
                      <Button colorScheme="blue" backgroundColor="#338687">
                        Leave Summary
                      </Button>
                    </Link> */}
                    <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
                      <ModalOverlay />
                      <ModalContent>
                        <ModalHeader>Add Leave Request</ModalHeader>
                        <ModalCloseButton />

                        <ModalBody>
                          <Stack spacing={4}>
                            <FormControl id="staff_id">
                              <FormLabel>Employee ID</FormLabel>
                              <Select
                                placeholder="Select Staff"
                                value={selectedStaffOption}
                                name="staff_id"
                                onChange={handleStaffChange}
                                options={staffOptions}
                                isSearchable
                              />
                            </FormControl>
                            <FormControl id="leaveCategory">
                              <FormLabel>Leave Category</FormLabel>
                              <ChakraSelect
                                placeholder="Select Leave Category"
                                value={leaveCategory}
                                onChange={(e) =>
                                  setLeaveCategory(e.target.value)
                                }
                              >
                                {leaveCategories.map((category, index) => (
                                  <option key={index} value={category}>
                                    {category}
                                  </option>
                                ))}
                              </ChakraSelect>
                            </FormControl>
                            <FormControl id="leaveReason">
                              <FormLabel>Leave Reason</FormLabel>
                              <Textarea
                                placeholder="Leave Reason"
                                value={leaveReason}
                                onChange={(e) => setLeaveReason(e.target.value)}
                              />
                            </FormControl>
                            <FormControl id="fromDate">
                              <FormLabel>From Date</FormLabel>
                              <Input
                                type="date"
                                placeholder="From Date"
                                value={fromDate}
                                onChange={(e) => setFromDate(e.target.value)}
                              />
                            </FormControl>
                            <FormControl id="toDate">
                              <FormLabel>To Date</FormLabel>
                              <Input
                                type="date"
                                placeholder="To Date"
                                value={toDate}
                                onChange={(e) => setToDate(e.target.value)}
                              />
                            </FormControl>
                            <FormControl id="leaveState">
                              <FormLabel>Leave Status</FormLabel>
                              <ChakraSelect
                                placeholder="Select Leave Status"
                                value={leaveState}
                                onChange={(e) => setLeaveState(e.target.value)}
                              >
                                {leaveStates.map((state, index) => (
                                  <option key={index} value={state}>
                                    {state}
                                  </option>
                                ))}
                              </ChakraSelect>
                            </FormControl>
                          </Stack>
                        </ModalBody>
                        {/* <ModalBody>
                          <label htmlFor="leaveCategory">Employee ID</label>
                          <Select
                            id="staff_id"
                            placeholder="Select Staff"
                            value={selectedStaffOption}
                            name="staff_id"
                            onChange={handleStaffChange}
                            options={staffOptions}
                            isSearchable
                          />
                          <label htmlFor="leaveCategory">Leave Category</label>
                          <ChakraSelect
                            id="leaveCategory"
                            placeholder="Select Leave Category"
                            value={leaveCategory}
                            onChange={(e) => setLeaveCategory(e.target.value)}
                          >
                            {leaveCategories.map((category, index) => (
                              <option key={index} value={category}>
                                {category}
                              </option>
                            ))}
                          </ChakraSelect>
                          <label htmlFor="leaveReason">Leave Reason</label>
                          <Textarea
                            id="leaveReason"
                            placeholder="Leave Reason"
                            value={leaveReason}
                            onChange={(e) => setLeaveReason(e.target.value)}
                          />
                          <label htmlFor="fromDate">From Date</label>
                          <Input
                            id="fromDate"
                            type="date"
                            placeholder="From Date"
                            value={fromDate}
                            onChange={(e) => setFromDate(e.target.value)}
                          />
                          <label htmlFor="toDate">To Date</label>
                          <Input
                            id="toDate"
                            type="date"
                            placeholder="To Date"
                            value={toDate}
                            onChange={(e) => setToDate(e.target.value)}
                          />
                          <label htmlFor="leaveState">Leave Status</label>
                          <ChakraSelect
                            id="leaveState"
                            placeholder="Select Leave Status"
                            value={leaveState}
                            onChange={(e) => setLeaveState(e.target.value)}
                          >
                            {leaveStates.map((state, index) => (
                              <option key={index} value={state}>
                                {state}
                              </option>
                            ))}
                          </ChakraSelect>
                        </ModalBody> */}
                        <ModalFooter>
                          <Button
                            colorScheme="blue"
                            mr={3}
                            onClick={() => setIsOpen(false)}
                          >
                            Close
                          </Button>
                          <Button
                            colorScheme="green"
                            onClick={handleAddLeaveRecord}
                          >
                            Save
                          </Button>
                        </ModalFooter>
                      </ModalContent>
                    </Modal>
                  </>
                </div>
              </div>

              <div className="col-12 col-md-4 col-lg-8">
                <div className="col-12 col-md-12 col-lg-12">
                  <Button
                    colorScheme="blue"
                    my={"2"}
                    mx={"4"}
                    width={"20%"}
                    onClick={() => handleDateFilter("Yesterday")}
                  >
                    Yesterday - {leaveCounts.yesterday}
                  </Button>
                  <Button
                    colorScheme="blue"
                    my={"2"}
                    mx={"4"}
                    width={"20%"}
                    onClick={() => handleDateFilter("Today")}
                  >
                    Today - {leaveCounts.today}
                  </Button>
                  <Button
                    colorScheme="blue"
                    my={"2"}
                    mx={"4"}
                    width={"20%"}
                    onClick={() => handleDateFilter("Tomorrow")}
                  >
                    Tomorrow - {leaveCounts.tomorrow}
                  </Button>

                  <Button
                    colorScheme="blue"
                    my={"2"}
                    mx={"4"}
                    width={"20%"}
                    onClick={() => handleDateFilter("Absent")}
                  >
                    Absent - {leaveCounts.absent}
                  </Button>
                </div>
              </div>

              <div className="col-12 col-md-12 col-lg-12">
                <div className="row">
                  <div className="col-12 col-md-2 col-lg-2">
                    <FormControl className="searchEmployeeFormGroup">
                      <FormLabel htmlFor="working_depts">Employee</FormLabel>

                      <Select
                        id="staff_id"
                        placeholder="Select Staff"
                        value={selectedSearchStaffOption}
                        name="staff_id"
                        onChange={handleSearchStaffChange}
                        options={staffSearchOptions}
                        isSearchable
                      />
                    </FormControl>
                  </div>

                  <div className="col-12 col-md-2 col-lg-2">
                    <FormControl className="searchEmployeeFormGroup">
                      <FormLabel htmlFor="working_depts">
                        Leave Status
                      </FormLabel>
                      <ChakraSelect
                        placeholder="Select option"
                        value={filterStatus}
                        onChange={(e) => setFilterStatus(e.target.value)}
                      >
                        <option value="Pending">Pending</option>
                        <option value="Approved">Approved</option>
                        <option value="Not Approved">Not Approved</option>
                      </ChakraSelect>
                    </FormControl>
                  </div>

                  <div className="col-12 col-md-2 col-lg-2">
                    <FormControl className="searchEmployeeFormGroup">
                      <FormLabel htmlFor="employee_id">From Date</FormLabel>
                      <input
                        id="date_of_joining"
                        style={{ width: "100%" }}
                        format="YYYY-MM-DD"
                        name="leave_date"
                        type="date"
                        className="leaveDateStyling"
                        value={searchDate || null}
                        onChange={(e) => setSearchDate(e.target.value)}
                      />
                    </FormControl>
                  </div>

                  <div className="col-12 col-md-2 col-lg-2">
                    <FormControl className="searchEmployeeFormGroup">
                      <FormLabel htmlFor="employee_id">To Date</FormLabel>
                      <input
                        id="date_of_joining"
                        style={{ width: "100%" }}
                        format="YYYY-MM-DD"
                        name="leave_date"
                        type="date"
                        className="leaveDateStyling"
                        value={searchToDate || null}
                        onChange={(e) => setSearchToDate(e.target.value)}
                      />
                    </FormControl>
                  </div>

                  <div className="col-12 col-md-2 col-lg-2">
                    <Button
                      colorScheme="blue"
                      onClick={fetchfilteredLeaveRequests}
                      className="leaveDateBtn"
                    >
                      Search Leave
                    </Button>
                  </div>

                  <div className="col-12 col-md-2 col-lg-2"></div>
                </div>
              </div>

              {/* ***********************************  Show Leave Status *********************** */}
              <div className="col-12 col-md-12 col-lg-12">
                <div className="viewLeaveRequestTable">
                  <Box overflowX={"auto"}>
                    <Table variant="simple" className="leaveRequestTableFormat">
                      <Thead>
                        <Tr>
                          <Th width={"10%"}>Leave ID</Th>
                          <Th width={"20%"}>Name</Th>
                          <Th width={"15%"}>Leave Type</Th>
                          <Th width={"15%"}> From Date</Th>
                          <Th width={"15%"}>To Date</Th>
                          <Th width={"15%"}>Status</Th>
                          <Th width={"5%"}>Action</Th>
                          <Th width={"5%"}></Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {leaveRequest.map((employee) => (
                          <React.Fragment key={employee.id}>
                            <Tr
                              onClick={() =>
                                toggleExpandSearchEmployee(employee.id)
                              }
                              style={{ cursor: "pointer" }}
                            >
                              <Td>{employee.id}</Td>

                              <Td>
                                <Flex>
                                  {employee.user__image ? (
                                    <>
                                      <Image
                                        src={`https://divine.backenddd.in/media/${employee.user__image}/`}
                                        alt={employee.user__username}
                                        boxSize="30px"
                                        mr="10px"
                                        borderRadius="50%"
                                      />
                                    </>
                                  ) : (
                                    <>

                                      <Image
                                        src={`https://divine.backenddd.in${employee.staff_img}/`}
                                        alt={employee.username}
                                        boxSize="30px"
                                        mr="10px"
                                        borderRadius="50%"
                                      />
                                    </>
                                  )}

                                  {employee.user__fname ? (
                                    <>
                                      {employee.user__fname} {employee.user__lname}
                                    </>
                                  ) : (
                                    <>
                                      {employee.staff_name} {employee.staff_lname}
                                    </>
                                  )}
                                </Flex>

                              </Td>
                              <Td>{employee.leave_type}</Td>
                              <Td>{employee.start_date}</Td>
                              <Td>{employee.end_date}</Td>
                              <Td>{employee.status}</Td>
                              {/* <Td><EditLeaveRequest /></Td> */}
                              <Td>
                                <Button
                                  leftIcon={<EditIcon />}
                                  onClick={() => handleEditClick(employee)}
                                >
                                  Edit
                                </Button>
                              </Td>
                              <Td>
                                <Link
                                  to="/leavesummary"
                                  state={{ user_id: employee.user__id }}
                                >
                                  <Button>Leave Summary</Button>
                                </Link>
                              </Td>
                              <Td>
                                {expandedRowSearchEmployee === employee.id ? (
                                  <Icon as={ChevronUpIcon} />
                                ) : (
                                  <Icon as={ChevronDownIcon} />
                                )}
                              </Td>
                            </Tr>
                            {expandedRowSearchEmployee === employee.id && (
                              <Tr>
                                <Td colSpan={7}>
                                  <h3 className="viewTableLeaveReason">
                                    Leave Reason{" "}
                                  </h3>
                                  <p>{employee.reason}</p>
                                </Td>
                              </Tr>
                            )}
                          </React.Fragment>
                        ))}
                      </Tbody>
                    </Table>
                  </Box>
                </div>
              </div>
              {/* Edit Modal */}
              {currentLeaveRequest && (
                <EditLeaveRequest
                  isOpen={isEditModalOpen}
                  onClose={() => setIsEditModalOpen(false)}
                  leaveRequest={currentLeaveRequest}
                  fetchLeaveRequests={fetchLeaveRequests}
                />
              )}
            </div>
          </div>
        </div>
      </ChakraProvider>
    </>
  );
};

export default AddLeaveRequest;