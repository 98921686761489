import React, { useState, useEffect } from "react";
import axios from "axios";
import "./AddMaster.css";

import {
  Box,
  Button,
  ChakraProvider,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import NavigationMenu from "../Navigation/NavigationMenu";

const FileFormatForm = () => {
  const [name, setName] = useState("");
  const [fileFormats, setFileFormats] = useState([]);
  const [editId, setEditId] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);
  const [formatToDelete, setFormatToDelete] = useState(null);
  const toast = useToast();

  useEffect(() => {
    fetchFileFormats();
  }, []);

  const fetchFileFormats = async () => {
    try {
      const response = await axios.get(
        "https://divine.backenddd.in/HumanResource/fileformats/"
      );
      setFileFormats(response.data);
    } catch (error) {
      console.error("Error fetching file formats:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (editId) {
        await axios.put(
          `https://divine.backenddd.in/HumanResource/fileformats/${editId}/`,
          {
            name,
          }
        );
        toast({
          title: "File Format updated successfully!",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        setEditId(null);
      } else {
        await axios.post(
          "https://divine.backenddd.in/HumanResource/fileformats/",
          {
            name,
          }
        );
        toast({
          title: "File Format created successfully!",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }
      setName("");
      setIsOpen(false);
      fetchFileFormats();
    } catch (error) {
      console.error("Error creating/updating file format:", error);
      toast({
        title:
          "An error occurred while creating/updating the file format. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleEdit = (format) => {
    setName(format.name);
    setEditId(format.id);
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
    setName(""); // Reset input field
    setEditId(null); // Reset editId
  };

  const handleOpenDeleteConfirmation = (format) => {
    setFormatToDelete(format);
    setIsDeleteConfirmationOpen(true);
  };

  const handleDelete = async () => {
    setIsDeleteConfirmationOpen(false);
    try {
      await axios.delete(
        `https://divine.backenddd.in/HumanResource/fileformats/${formatToDelete.id}/`
      );
      toast({
        title: "File Format deleted successfully!",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      fetchFileFormats();
    } catch (error) {
      console.error("Error deleting file format:", error);
      toast({
        title:
          "An error occurred while deleting the file format. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const [userStatus, setUserStatus] = useState(false);
  const [isSuperuser, setIsSuperUser] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get("https://divine.backenddd.in/HumanResource/user-status/");
        if (response.status === 200) {
          console.log("Response: ", response.data)
          setUserStatus(response.data);
          setIsSuperUser(response.data.is_superuser);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, []);

  return (
    <ChakraProvider>
      <NavigationMenu />

      <div className="addMasterWrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-md-12 col-lg-12">
              <div className="addHrPolicyHeading">
                <h1>Add Addition Fields</h1>
                <p>
                  Click on Create button to add the{" "}
                  <span> Documents, Working Dept, Designation </span>
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-4 col-lg-4"></div>
            <div className="col-12 col-md-4 col-lg-4">
              <Box
                maxW="md"
                mx="auto"
                mt="8"

                borderWidth="1px"
                borderRadius="lg"
                className="addDepartmentStyling"
              >
                <form onSubmit={handleSubmit}>
                  <h5 variant="h5" gutterBottom className="departmentHeading">
                    Add Document Field
                  </h5>

                  <div className="inputBox">
                    <div className="row">
                      <div className="col-12">
                        <FormControl id="fileFormat">
                          <FormLabel>Add Document Field</FormLabel>
                          <Input
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </FormControl>
                        <Button colorScheme="blue" type="submit" mt="4" className="depatmentBtn">
                          {editId ? "Update" : "Save"}
                        </Button>
                      </div>
                    </div>
                  </div>
                </form>
                <div className="departmentData">
                  <Stack spacing="2">
                    {fileFormats.map((format) => (
                      <Box
                        key={format.id}
                        borderWidth="1px"
                        p="4"
                        borderRadius="md"
                      >
                        <>
                          <Text>{format.name}</Text>
                          {isSuperuser && (
                            <Stack direction="row" mt="2">
                              <Button
                                colorScheme="teal"
                                size="sm"
                                onClick={() => handleEdit(format)}
                              >
                                Edit
                              </Button>
                              <Button
                                colorScheme="red"
                                size="sm"
                                onClick={() => handleOpenDeleteConfirmation(format)}
                              >
                                Delete
                              </Button>
                            </Stack>
                          )}
                        </>
                      </Box>
                    ))}
                  </Stack>

                </div>

              </Box>

            </div>
            <div className="col-12 col-md-4 col-lg-4"></div>
          </div>
        </div>
      </div>

      <Modal isOpen={isOpen} onClose={handleCloseModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {editId ? "Edit File Format" : "Add File Format"}
          </ModalHeader>
          <ModalBody>
            <form onSubmit={handleSubmit}>
              <FormControl>
                <FormLabel>File Format</FormLabel>
                <Input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </FormControl>
            </form>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleSubmit}>
              {editId ? "Update" : "Save"}
            </Button>
            <Button onClick={handleCloseModal}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={isDeleteConfirmationOpen}
        onClose={() => setIsDeleteConfirmationOpen(false)}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete File Format</ModalHeader>
          <ModalBody>
            Are you sure you want to delete "
            {formatToDelete && formatToDelete.name}"?
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="red" onClick={handleDelete}>
              Delete
            </Button>
            <Button onClick={() => setIsDeleteConfirmationOpen(false)}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </ChakraProvider>
  );
};

export default FileFormatForm;
