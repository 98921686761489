import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Switch,
  IconButton,
} from "@chakra-ui/react";
import { EditIcon, DeleteIcon, ViewIcon } from "@chakra-ui/icons";
import axios from "axios";

const AddTrainingOne = ({ username }) => {
  const [isPreModalOpen, setPreModalOpen] = useState(false);
  const [isPostModalOpen, setPostModalOpen] = useState(false);
  const [preTrainingData, setPreTrainingData] = useState([]);
  const [postTrainingData, setPostTrainingData] = useState([]);
  const [currentData, setCurrentData] = useState({ staff: username });
  const [isEdit, setIsEdit] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [isPreTraining, setIsPreTraining] = useState(true);

  const apiBaseUrl = "https://divine.backenddd.in/HumanResource"; // Replace with your actual API base URL

  useEffect(() => {
    if (username) {
      fetchPreTrainingData();
      fetchPostTrainingData();
      setCurrentData((prevData) => ({ ...prevData, staff: username }));
    }
  }, [username]);

  const fetchPreTrainingData = async () => {
    try {
      const response = await axios.get(
        `${apiBaseUrl}/pre_training_list/${username}/`
      );
      setPreTrainingData(response.data);
      console.log("Pre Training: ", response.data);
    } catch (error) {
      console.error("Error fetching pre-training data:", error);
    }
  };

  const fetchPostTrainingData = async () => {
    try {
      const response = await axios.get(
        `${apiBaseUrl}/post_training_list/${username}/`
      );
      setPostTrainingData(response.data);
      console.log("Post Training: ", response.data);
    } catch (error) {
      console.error("Error fetching post-training data:", error);
    }
  };

  const handlePreModalOpen = () => {
    setIsPreTraining(true);
    setPreModalOpen(true);
  };

  const handlePostModalOpen = () => {
    setIsPreTraining(false);
    setPostModalOpen(true);
  };

  const handleCloseModal = () => {
    setPreModalOpen(false);
    setPostModalOpen(false);
    setCurrentData({ staff: username });
    setIsEdit(false);
    setEditIndex(null);
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked, files } = e.target;
    setCurrentData({
      ...currentData,
      [name]:
        type === "checkbox" ? checked : type === "file" ? files[0] : value,
    });
  };

  const handleSaveData = async () => {
    const formData = new FormData();
    for (const key in currentData) {
      formData.append(key, currentData[key]);
    }
    try {
      if (isEdit) {
        if (isPreTraining) {
          await axios.put(`${apiBaseUrl}/pre_training/${editIndex}/`, formData);
          fetchPreTrainingData();
        } else {
          await axios.put(
            `${apiBaseUrl}/post_training/${editIndex}/`,
            formData
          );
          fetchPostTrainingData();
        }
      } else {
        if (isPreTraining) {
          await axios.post(`${apiBaseUrl}/pre_training/`, formData);
          fetchPreTrainingData();
        } else {
          await axios.post(`${apiBaseUrl}/post_training/`, formData);
          fetchPostTrainingData();
        }
      }
      handleCloseModal();
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  const handleEdit = (data, id, isPreTraining) => {
    setCurrentData({ ...data, staff: username });
    setIsEdit(true);
    setEditIndex(id);
    setIsPreTraining(isPreTraining);
    if (isPreTraining) {
      setPreModalOpen(true);
    } else {
      setPostModalOpen(true);
    }
  };

  const handleDelete = async (id, isPreTraining) => {
    try {
      if (isPreTraining) {
        await axios.delete(`${apiBaseUrl}/pre_training/${id}/`);
        fetchPreTrainingData();
      } else {
        await axios.delete(`${apiBaseUrl}/post_training/${id}/`);
        fetchPostTrainingData();
      }
    } catch (error) {
      console.error("Error deleting data:", error);
    }
  };

  const calculateScoreDifference = (preData, postData) => {
    const preScore = parseFloat(preData.actual_score) || 0;
    const postScore = parseFloat(postData.actual_score) || 0;
    return postScore - preScore;
  };

  const viewDocument = (file) => {
    if (file) {
      window.open(`https://divine.backenddd.in${file}/`, "_blank");
    }
  };

  if (!username) {
    return <Box p={4}>Please provide a username to view training data.</Box>;
  }

  return (
    <Box p={4}>
      <div className="addTrainingButton">
        <Button
          onClick={handlePreModalOpen}
          colorScheme="blue"
          className="trainingEachBtn"
          mr={3}
        >
          <i className="fa-solid fa-plus AddDependIcon"></i> Pre Training Data
        </Button>
        <Button
          onClick={handlePostModalOpen}
          colorScheme="green"
          className="trainingEachBtn"
        >
          <i className="fa-solid fa-plus AddDependIcon"></i> Post Training Data
        </Button>
      </div>

      {preTrainingData.map((preData, index) => (
        <Box
          key={preData.id}
          mt={4}
          borderWidth="1px"
          borderRadius="lg"
          p={4}
          overflowX={"auto"}
        >
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Training Type</Th>
                <Th>Date of Training</Th>
                <Th>Topic of Training</Th>
                <Th>Trainer Name</Th>
                <Th>Actual Score</Th>
                <Th>Total Score</Th>
                <Th>Appearance</Th>
                <Th>Training Document</Th>
                <Th>Action</Th>
                <Th>Score Difference</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td>Pre Training</Td>
                <Td>{preData.date_of_training}</Td>
                <Td>{preData.topic_Of_training}</Td>
                <Td>{preData.trainer_name}</Td>
                <Td>{preData.actual_score}</Td>
                <Td>{preData.target_score}</Td>
                <Td>{preData.appearance ? "Active" : "Inactive"}</Td>
                <Td>
                  <IconButton
                    aria-label="View"
                    icon={<ViewIcon />}
                    onClick={() => viewDocument(preData.file)}
                  />
                </Td>
                <Td>
                  <IconButton
                    aria-label="Edit"
                    icon={<EditIcon />}
                    onClick={() => handleEdit(preData, preData.id, true)}
                    mr={2}
                  />
                  <IconButton
                    aria-label="Delete"
                    icon={<DeleteIcon />}
                    onClick={() => handleDelete(preData.id, true)}
                  />
                </Td>
              </Tr>
              {postTrainingData[index] && (
                <Tr>
                  <Td>Post Training</Td>
                  <Td>{postTrainingData[index].date_of_training}</Td>
                  <Td>{postTrainingData[index].topic_Of_training}</Td>
                  <Td>{postTrainingData[index].trainer_name}</Td>
                  <Td>{postTrainingData[index].actual_score}</Td>
                  <Td>{postTrainingData[index].target_score}</Td>
                  <Td>
                    {postTrainingData[index].appearance ? "Active" : "Inactive"}
                  </Td>
                  <Td>
                    <IconButton
                      aria-label="View"
                      icon={<ViewIcon />}
                      onClick={() => viewDocument(postTrainingData[index].file)}
                    />
                  </Td>
                  <Td>
                    <IconButton
                      aria-label="Edit"
                      icon={<EditIcon />}
                      onClick={() =>
                        handleEdit(
                          postTrainingData[index],
                          postTrainingData[index].id,
                          false
                        )
                      }
                      mr={2}
                    />
                    <IconButton
                      aria-label="Delete"
                      icon={<DeleteIcon />}
                      onClick={() =>
                        handleDelete(postTrainingData[index].id, false)
                      }
                    />
                  </Td>
                  <Td>
                    {calculateScoreDifference(preData, postTrainingData[index])}
                  </Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        </Box>
      ))}

      <Modal isOpen={isPreModalOpen} onClose={handleCloseModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Pre Training Data</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>Staff</FormLabel>
              <Input
                type="text"
                name="staff"
                value={currentData.staff || ""}
                readOnly
              />
              <FormLabel>Date of Training</FormLabel>
              <Input
                type="date"
                name="date_of_training"
                value={currentData.date_of_training || ""}
                onChange={handleInputChange}
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Topic of Training</FormLabel>
              <Input
                type="text"
                name="topic_Of_training"
                value={currentData.topic_Of_training || ""}
                onChange={handleInputChange}
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Trainer Name</FormLabel>
              <Input
                type="text"
                name="trainer_name"
                value={currentData.trainer_name || ""}
                onChange={handleInputChange}
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Actual Score</FormLabel>
              <Input
                type="number"
                name="actual_score"
                value={currentData.actual_score || ""}
                onChange={handleInputChange}
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Total Score</FormLabel>
              <Input
                type="number"
                name="target_score"
                value={currentData.target_score || ""}
                onChange={handleInputChange}
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Appearance</FormLabel>
              <Switch
                name="appearance"
                isChecked={currentData.appearance || false}
                onChange={handleInputChange}
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Training Document</FormLabel>
              <Input type="file" name="file" onChange={handleInputChange} />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={handleSaveData}>
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isPostModalOpen} onClose={handleCloseModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Post Training Data</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>Staff</FormLabel>
              <Input
                type="text"
                name="staff"
                value={currentData.staff || ""}
                readOnly
              />
              <FormLabel>Date of Training</FormLabel>
              <Input
                type="date"
                name="date_of_training"
                value={currentData.date_of_training || ""}
                onChange={handleInputChange}
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Topic of Training</FormLabel>
              <Input
                type="text"
                name="topic_Of_training"
                value={currentData.topic_Of_training || ""}
                onChange={handleInputChange}
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Trainer Name</FormLabel>
              <Input
                type="text"
                name="trainer_name"
                value={currentData.trainer_name || ""}
                onChange={handleInputChange}
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Actual Score</FormLabel>
              <Input
                type="number"
                name="actual_score"
                value={currentData.actual_score || ""}
                onChange={handleInputChange}
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Total Score</FormLabel>
              <Input
                type="number"
                name="target_score"
                value={currentData.target_score || ""}
                onChange={handleInputChange}
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Appearance</FormLabel>
              <Switch
                name="appearance"
                isChecked={currentData.appearance || false}
                onChange={handleInputChange}
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Training Document</FormLabel>
              <Input type="file" name="file" onChange={handleInputChange} />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="green" onClick={handleSaveData}>
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default AddTrainingOne;
