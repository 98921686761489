import React, { useState, useEffect } from "react";
import NavigationMenu from "../Navigation/NavigationMenu";
import {
  ChakraProvider,
  Input,
  InputGroup,
  Select,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Icon,
  Flex,
  Image,
  FormControl,
  FormLabel,
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import axios from "axios";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import { DatePicker } from "antd";
import "./SearchEmployee.css";
import { Link, useLocation } from "react-router-dom";

const EmployeeImageModal = ({ employee }) => {
  const {
    isOpen: imgModelisOpen,
    onOpen: imgModelonOpen,
    onClose: imgModelonClose,
  } = useDisclosure();

  const handleImageClick = (e) => {
    e.stopPropagation();
    imgModelonOpen();
  };

  return (
    <>
      <Image
        src={`https://divine.backenddd.in/media/${employee.image}/`}
        alt={employee.name}
        boxSize="30px"
        mr="10px"
        borderRadius="50%"
        onClick={handleImageClick}
        cursor="pointer"
        loading="lazy"
      />

      <Modal isOpen={imgModelisOpen} onClose={imgModelonClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody display="flex" justifyContent="center" alignItems="center">
            <Image
              src={`https://divine.backenddd.in/media/${employee.image}/`}
              alt={employee.name}
              maxWidth="100%"
              maxHeight="100%"
              loading="lazy"
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

const SearchEmployee = () => {
  const [empCount, setEmpCount] = useState(0);
  const [employees, setEmployees] = useState([]);
  const [depts, setDepts] = useState([]);
  const [workingDept, setWorkingDept] = useState([]);
  const [positions, setPositions] = useState([]);
  const location = useLocation();

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [nextPage, setNextPage] = useState(null);
  const [previousPage, setPreviousPage] = useState(null);
  const initialDept = location.state?.department;
  const [formData, setFormData] = useState({
    dept: "",
    working_dept: "",
    position: "",
    id: "",
    name: "",
    joining_date: "",
    resignation_date: "",
  });

  const token = localStorage.getItem("access_token");

  const fetchDataWithDept = async (url) => {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      setEmployees(data.staff);
      setNextPage(data.next);
      setPreviousPage(data.previous);
      setTotalPages(Math.ceil(data.count / 10)); // Assuming 10 items per page
    } catch (error) {
      console.error("Failed to fetch department data:", error);
    }
  };

  const fetchData = async (url) => {
    try {
      if (token) {
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const data = response.data;
        setEmployees(data.results);
        setNextPage(data.next);
        setPreviousPage(data.previous);
        setTotalPages(Math.ceil(data.count / 10)); // Assuming 10 items per page
      } else {
        console.error("Access token not found");
      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };

  useEffect(() => {
    if (initialDept) {
      const url = `https://divine.backenddd.in/HumanResource/deptwisesearch/${initialDept}/`;
      fetchDataWithDept(url);
    } else {
      const url = `https://divine.backenddd.in/HumanResource/SelectStaffList/?page=${page}`;
      fetchData(url);
    }
  }, [initialDept, token, page]);

  useEffect(() => {
    const fetchWorkingDepartments = async () => {
      const url = `https://divine.backenddd.in/HumanResource/deptwiseworkingdept/${formData.dept}/`;
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        setWorkingDept(data.working_dept || []);
        setPositions(data.position || []);
      } catch (error) {
        console.error("Failed to fetch department data:", error);
      }
    };

    if (formData.dept) {
      fetchWorkingDepartments();
    }
  }, [formData.dept]);

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const deptsResponse = await axios.get(
          "https://divine.backenddd.in/HumanResource/api/departments/"
        );
        let data = deptsResponse.data.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
        setDepts(data || []);

      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchInitialData();
  }, [])

  const [expandedRowSearchEmployee, setExpandedRowSearchEmployee] = useState(
    null
  );

  const toggleExpandSearchEmployee = (id) => {
    setExpandedRowSearchEmployee(
      expandedRowSearchEmployee === id ? null : id
    );
  };

  const fetchFilteredData = async (url) => {
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setEmployees(data);
      setNextPage(data.next);
      setPreviousPage(data.previous);
      setTotalPages(Math.ceil(data.count / 10)); // Assuming 10 items per page
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setPage(1);
    const url = `https://divine.backenddd.in/HumanResource/filterbydept/`;
    fetchFilteredData(url);
  };

  const handleChange = (event) => {
    let { name, value } = event.target;

    if (name === "date") {
      value = new Date(value).toISOString().split("T")[0];
    }

    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));

  };

  useEffect(() => {
    setEmpCount(employees.length);
    // fetchUserStatus();
  }, [employees]);

  const handleDateChange = (date, dateString, key) => {
    setFormData((prevState) => ({
      ...prevState,
      [key]: date,
    }));
  };

  const [isSuperuser, setIsSuperUser] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get("https://divine.backenddd.in/HumanResource/user-status/");
        if (response.status === 200) {
          setIsSuperUser(response.data.is_superuser);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, []);

  return (
    <>
      <NavigationMenu />

      <ChakraProvider>
        <div className="searchEmployeeComponent">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-md-12 col-md-12">
                <div className="searchEmployeeHeading">
                  <h1>Search Employee</h1>
                  <p>
                    Explore employee detail using on{" "}
                    <span>Emp ID, Name, DOJ</span> button at the bottom
                    everytime.
                  </p>
                  <p style={{ textAlign: "right", color: "#EE1B24" }}>
                    Total Number of Employee: {empCount}
                  </p>
                </div>
              </div>
            </div>

            <div className="searchEmployeeWrapper">
              <div className="searchEmployeeSearchField">
                <div className="row">
                  <div className="col-12 col-md-12 col-lg-12">
                    <form onSubmit={handleSubmit} className="searchFilter">
                      <div className="row">
                        <div className="col-12 col-md-3 col-lg-3">
                          <FormControl className="searchEmployeeFormGroup">
                            <FormLabel htmlFor="employee_id">
                              Employee ID
                            </FormLabel>
                            <InputGroup>
                              <Input
                                placeholder="Search EMP ID..."
                                name="id"
                                value={formData.staff_id}
                                onChange={handleInputChange}
                                // onClick={fetchInitialData}
                              />
                            </InputGroup>
                          </FormControl>
                        </div>

                        <div className="col-12 col-md-3 col-lg-3">
                          <FormControl className="searchEmployeeFormGroup">
                            <FormLabel htmlFor="employee_id">
                              Employee Name
                            </FormLabel>
                            <InputGroup>
                              <Input
                                placeholder="Search Name..."
                                name="name"
                                value={formData.name}
                                onChange={handleInputChange}
                              />
                            </InputGroup>
                          </FormControl>
                        </div>
                        <div className="col-12 col-md-3 col-lg-3">
                          <FormControl className="searchEmployeeFormGroup">
                            <FormLabel htmlFor="employee_id">
                              Joining Date
                            </FormLabel>
                            <DatePicker
                              id="date_of_joining"
                              style={{ width: "100%" }}
                              format="YYYY-MM-DD"
                              name="joining_date"
                              value={formData.joining_date || null}
                              onChange={(date, dateString) =>
                                handleDateChange(date, dateString, "joining_date")
                              }
                            />
                          </FormControl>
                        </div>

                        <div className="col-12 col-md-3 col-lg-3">
                          <FormControl className="searchEmployeeFormGroup">
                            <FormLabel htmlFor="employee_id">
                              Resign Date
                            </FormLabel>
                            <DatePicker
                              id="date_of_joining"
                              style={{ width: "100%" }}
                              format="YYYY-MM-DD"
                              name="resignation_date"
                              value={formData.resignation_date || null}
                              onChange={(date, dateString) =>
                                handleDateChange(date, dateString, "resignation_date")
                              }
                            />
                          </FormControl>
                        </div>

                        <div className="col-12 col-md-3 col-lg-3">
                          <FormControl className="searchEmployeeFormGroup">
                            <FormLabel htmlFor="employee_id">
                              Department
                            </FormLabel>
                            <Select
                              labelId="depts"
                              id="depts"
                              value={formData.dept || ""}
                              label="Department"
                              name="dept"
                              onChange={handleChange}
                            >
                              <option value="">--Select Department--</option>
                              {depts.map((dept) => (
                                <option key={dept.id} value={dept.id}>
                                  {dept.name}
                                </option>
                              ))}
                            </Select>
                          </FormControl>
                        </div>

                        <div className="col-12 col-md-3 col-lg-3">
                          <FormControl className="searchEmployeeFormGroup">
                            <FormLabel htmlFor="employee_id">
                              Working Department
                            </FormLabel>
                            <Select
                              labelId="working_depts"
                              id="working_depts"
                              value={formData.working_dept || ""}
                              label="Working Department"
                              name="working_dept"
                              onChange={handleChange}
                            >
                              <option value="">
                                --Select Working Department--
                              </option>
                              {workingDept
                                .sort((a, b) => a.name.localeCompare(b.name))
                                .map((dept) => (
                                  <option key={dept.id} value={dept.id}>
                                    {dept.name}
                                  </option>
                                ))}
                            </Select>
                          </FormControl>
                        </div>

                        <div className="col-12 col-md-3 col-lg-3">
                          <FormControl className="searchEmployeeFormGroup">
                            <FormLabel htmlFor="employee_id">
                              Designation
                            </FormLabel>
                            <Select
                              labelId="position"
                              id="position"
                              value={formData.position || ""}
                              label="Designation"
                              name="position"
                              onChange={handleChange}
                            >
                              <option value="">--Select Designation--</option>
                              {positions
                                .sort((a, b) => a.name.localeCompare(b.name))
                                .map((dept) => (
                                  <option key={dept.id} value={dept.id}>
                                    {dept.name}
                                  </option>
                                ))}
                            </Select>
                          </FormControl>
                        </div>

                        <div className="col-12 col-md-3 col-lg-3">
                          <FormControl
                            isRequired
                            className="searchEmployeeFormGroup d-flex justify-content-center"
                          >
                            <Button
                              colorScheme="blue"
                              className="searchEmployeeSearchBtn"
                              type="submit"
                            >
                              Search
                            </Button>
                          </FormControl>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div className="searchEmployeeTable">
              <div className="row">
                <div className="col-12">
                  <Box overflowX={"auto"}>
                    <Table variant="simple" className="searchTable">
                      <Thead>
                        <Tr>
                          <Th width={"7%"}>Srno.</Th>
                          <Th width={"8%"}>Employee ID</Th>
                          <Th width={"20%"}>Name</Th>
                          <Th width={"30%"}>Designation</Th>
                          <Th width={"30%"}>Working Department</Th>
                          <Th width={"5%"}></Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {employees.map((employee, index) => (
                          <React.Fragment key={employee.id}>
                            <Tr

                              className="tableBordered"
                              onClick={() =>
                                toggleExpandSearchEmployee(employee.id)
                              }
                              style={{ cursor: "pointer" }}
                            >
                              <Td>{index + 1}</Td>

                              <Td>{employee.staff_id}</Td>

                              <Td>
                                <Flex align="center">
                                  <EmployeeImageModal employee={employee} />
                                  {employee.fname}
                                </Flex>
                              </Td>
                              <Td>{employee.position__name} {employee.pos_name}</Td>
                              <Td>{employee.working_dept__name} {employee.wor_dep_name}</Td>

                              <Td>
                                {expandedRowSearchEmployee === employee.id ? (
                                  <Icon as={ChevronUpIcon} />
                                ) : (
                                  <Icon as={ChevronDownIcon} />
                                )}
                              </Td>
                            </Tr>
                            {expandedRowSearchEmployee === employee.id && (
                              <Tr>
                                <Td colSpan={5}>
                                  <Table variant="styled">
                                    <Tbody>
                                      <Tr>
                                        <Td width={"15%"}>
                                          <p>Date of Joining </p>
                                          <span className="searchTableJoiningDate">
                                            {employee.joining_date}
                                          </span>
                                        </Td>
                                        <Td>
                                          <p>Date of Resign</p>
                                          <span className="searchTableResigngDate">
                                            {employee.resignation_date}
                                          </span>
                                        </Td>
                                        <Td>
                                          <p>Phone Number</p>
                                          {employee.mobile_number}{" "}
                                        </Td>
                                        <Td>
                                          <p>Email ID</p>
                                          {employee.email}
                                        </Td>
                                        <Td colSpan={2} textAlign="right">
                                          <Link
                                            to="/viewemployee"
                                            state={{ eid: employee.id }}
                                            className="viewEmpBtn"
                                          >
                                            <Button
                                              color="primary"
                                              className="viewEmpBtn"
                                            >
                                              View Details
                                            </Button>
                                          </Link>
                                        </Td>
                                        {(isSuperuser) && (
                                          <Td colSpan={2} textAlign="right">
                                            <Link
                                              to="/editEmployee"
                                              state={{ eid: employee.id }}
                                              className="viewEmpBtn"
                                            >
                                              <Button
                                                color="primary"
                                                className="viewEmpBtn"
                                              >
                                                Edit Details
                                              </Button>
                                            </Link>
                                          </Td>
                                        )}
                                      </Tr>
                                    </Tbody>
                                  </Table>
                                </Td>
                              </Tr>
                            )}
                          </React.Fragment>
                        ))}
                      </Tbody>
                    </Table>
                  </Box>
                  <Flex justify="center" mt={4}>
                    <Button
                      onClick={() => previousPage && setPage(page - 1)}
                      disabled={!previousPage}
                    >
                      Previous
                    </Button>
                    <Box mx={2} mt={2}>
                      Page {page} of {totalPages}
                    </Box>
                    <Button
                      onClick={() => nextPage && setPage(page + 1)}
                      disabled={!nextPage}
                    >
                      Next
                    </Button>
                  </Flex>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ChakraProvider>
    </>
  );
};

export default SearchEmployee;