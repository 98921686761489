import React, { useState, useEffect } from 'react';
import {
  ChakraProvider,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Input,
  Select as ChakraSelect,
  Textarea,
  Stack,
} from '@chakra-ui/react';
import axios from 'axios';

const EditLeaveRequest = ({ isOpen, onClose, leaveRequest, fetchLeaveRequests }) => {
  const [leaveCategory, setLeaveCategory] = useState(leaveRequest.leave_type);
  const [leaveReason, setLeaveReason] = useState(leaveRequest.reason);
  const [fromDate, setFromDate] = useState(leaveRequest.start_date);
  const [toDate, setToDate] = useState(leaveRequest.end_date);
  const [leaveState, setLeaveState] = useState(leaveRequest.status);
  const [medicalCertificate, setMedicalCertificate] = useState(null);

  const leaveCategories = [
    "Annual Leave",
    "Sick Leave",
    "Maternity Leave",
    "Monthly Off",
    "Casual Leave",
    "National Holiday",
    "Privilege Leave",
    "EL",
    "Absent",
  ];
  const leaveStates = ["Pending", "Approved", "Rejected"];

  const handleUpdateLeaveRequest = async () => {
    const formData = new FormData();
    formData.append('leave_type', leaveCategory);
    formData.append('reason', leaveReason);
    formData.append('start_date', fromDate);
    formData.append('end_date', toDate);
    formData.append('status', leaveState);
    if (medicalCertificate) {
      formData.append('medical_certificate', medicalCertificate);
    }

    const accessToken = localStorage.getItem("access_token");

    try {
      const response = await axios.post(
        `https://divine.backenddd.in/Leave/api/leave-requests/${leaveRequest.id}/update-status/`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (response.status === 200) {
        alert("Updated");
        fetchLeaveRequests();
        onClose();
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <ChakraProvider>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Leave Request</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={4}>
              <FormControl id="leaveCategory">
                <FormLabel>Leave Category</FormLabel>
                <ChakraSelect
                  placeholder="Select Leave Category"
                  value={leaveCategory}
                  onChange={(e) => setLeaveCategory(e.target.value)}
                >
                  {leaveCategories.map((category, index) => (
                    <option key={index} value={category}>
                      {category}
                    </option>
                  ))}
                </ChakraSelect>
              </FormControl>
              <FormControl id="leaveReason">
                <FormLabel>Leave Reason</FormLabel>
                <Textarea
                  placeholder="Leave Reason"
                  value={leaveReason}
                  onChange={(e) => setLeaveReason(e.target.value)}
                />
              </FormControl>
              <FormControl id="fromDate">
                <FormLabel>From Date</FormLabel>
                <Input
                  type="date"
                  placeholder="From Date"
                  value={fromDate}
                  onChange={(e) => setFromDate(e.target.value)}
                />
              </FormControl>
              <FormControl id="toDate">
                <FormLabel>To Date</FormLabel>
                <Input
                  type="date"
                  placeholder="To Date"
                  value={toDate}
                  onChange={(e) => setToDate(e.target.value)}
                />
              </FormControl>
              <FormControl id="leaveState">
                <FormLabel>Leave Status</FormLabel>
                <ChakraSelect
                  placeholder="Select Leave Status"
                  value={leaveState}
                  onChange={(e) => setLeaveState(e.target.value)}
                >
                  {leaveStates.map((state, index) => (
                    <option key={index} value={state}>
                      {state}
                    </option>
                  ))}
                </ChakraSelect>
              </FormControl>

              {leaveCategory === "Sick Leave" && (
                <FormControl id="medicalCertificate">
                  <FormLabel>Medical Certificate</FormLabel>
                  <Input
                    type="file"
                    onChange={(e) => setMedicalCertificate(e.target.files[0])}
                  />
                </FormControl>
              )}
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button colorScheme="green" onClick={handleUpdateLeaveRequest}>
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </ChakraProvider>
  );
};

export default EditLeaveRequest;
