import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  VStack,
  Box,
  Text,
} from "@chakra-ui/react";

const AddAdditionalField = ({ username }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [fields, setFields] = useState([]);
  const [newField, setNewField] = useState({ label: "", value: "" });

  useEffect(() => {
    if (username) {
      axios
        .get(
          `https://divine.backenddd.in/HumanResource/get_staff_form_additional_fields/${username}/`
        )
        .then((response) => setFields(response.data))
        .catch((error) => console.error("Failed to fetch fields", error));
    }
  }, [username]);

  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    setNewField({ ...newField, [name]: value });
  };

  const addField = () => {
    axios
      .post("https://divine.backenddd.in/HumanResource/staff-fields/", {
        staff: username,
        field_name: newField.label,
        field_value: newField.value,
      })
      .then((response) => {
        setFields([...fields, response.data]);
        setNewField({ label: "", value: "" });
        onClose();
      })
      .catch((error) => {
        console.error("Error adding field:", error);
        alert("Failed to add field. Please try again.");
      });
  };

  const updateField = (id, value) => {
    axios
      .put(
        `https://divine.backenddd.in/HumanResource/staff-fields/${id}/`,
        {
          field_value: value,
        }
      )
      .then((response) => {
        setFields(
          fields.map((field) => (field.id === id ? response.data : field))
        );
      })
      .catch((error) => console.error("Error updating field:", error));
  };

  const deleteField = (id) => {
    axios
      .delete(
        `https://divine.backenddd.in/HumanResource/staff-fields/${id}/`
      )
      .then(() => {
        setFields(fields.filter((field) => field.id !== id));
      })
      .catch((error) => console.error("Error deleting field:", error));
  };

  return (
    <>
      <Button onClick={onOpen} className="addEmployeeBtn">
        {" "}
        <i class="fa-solid fa-plus AddDependIcon"></i>Add Custom Field
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add a Custom Field</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>Label of Field</FormLabel>
              <Input
                placeholder="Enter label"
                name="label"
                value={newField.label}
                onChange={handleFieldChange}
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Value of Field</FormLabel>
              <Input
                placeholder="Enter value"
                name="value"
                value={newField.value}
                onChange={handleFieldChange}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={addField}>
              Add Field
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <div className="row">
        {fields.map((field) => (
          <div className="col-4">
            <FormControl
              key={field.id}
              isRequired
              className="addEmployeeFormGroup"
            >
              <FormLabel>{field.field_name}</FormLabel>

              <Input
                id="employee_id"
                value={field.field_value}
                type="text"
                onChange={(e) => updateField(field.id, e.target.value)}
              />
            </FormControl>
          </div>
        ))}
      </div>
    </>
  );
};

export default AddAdditionalField;
