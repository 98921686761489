import React, { useState, useEffect } from "react";

import {
  FormControl,
  FormLabel,
  Button,
  Input,
  Select,
  Textarea,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  IconButton,
  Box,
} from "@chakra-ui/react";
import { ViewIcon, EditIcon, DeleteIcon } from "@chakra-ui/icons";
import axios from "axios";

const AddPromotion = ({ username }) => {
  const {
    isOpen: isOpenPromotion,
    onOpen: onOpenPromotion,
    onClose: onClosePromotion,
  } = useDisclosure();
  const [formDataPromotion, setFormDataPromotion] = useState({
    staff: username,
    ref_number: "",
    post_promoted_to: "",
    post_promoted_from: "",
    responsibily_changed_to: "",
    promotion_date: "",
    docs: null,
  });
  const [recordsPromotion, setRecordsPromotion] = useState([]);
  const [editingIndexPromotion, setEditingIndexPromotion] = useState(-1);

  const [positions, setPositions] = useState([]);

  useEffect(() => {
    const fetchPositions = async () => {
      try {
        const response = await axios.get(
          "https://divine.backenddd.in/HumanResource/api/positions/"
        );
        setPositions(response.data);
      } catch (error) {
        console.error("Error fetching positions:", error);
      }
    };

    fetchPositions();
  }, []);

  const handleInputChangePromotion = (e) => {
    const { name, value, files } = e.target;
    if (files) {
      setFormDataPromotion({ ...formDataPromotion, docs: files[0] });
    } else {
      setFormDataPromotion({ ...formDataPromotion, [name]: value });
    }
  };

  useEffect(() => {
    const fetchDataPromotion = () => {
      axios
        .get(
          `https://divine.backenddd.in/HumanResource/viewparticularemployeepromotion/${username}/`
        )
        .then((response) => {
          setRecordsPromotion(response.data);
        })
        .catch((error) => {
          console.error("Error fetching promotions:", error);
        });
    };

    fetchDataPromotion();
  }, []);

  const fetchDataPromotion = () => {
    axios
      .get(
        `https://divine.backenddd.in/HumanResource/viewparticularemployeepromotion/${username}/`
      )
      .then((response) => {
        setRecordsPromotion(response.data);
      })
      .catch((error) => {
        console.error("Error fetching promotions:", error);
      });
  };

  useEffect(() => {
    fetchDataPromotion();
  }, [username]);

  const saveData = () => {
    const url =
      editingIndexPromotion === -1
        ? "https://divine.backenddd.in/HumanResource/create_promotion/"
        : `https://divine.backenddd.in/HumanResource/updateparticularemployeepromotion/${recordsPromotion[editingIndexPromotion].id}/`;

    const method = editingIndexPromotion === -1 ? "POST" : "PUT";

    const formData = new FormData();
    Object.entries(formDataPromotion).forEach(([key, value]) => {
      formData.append(key, value);
    });

    axios({
      method: method,
      url: url,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response) => {
        console.log("Response:", response.data);
        fetchDataPromotion();
        onClosePromotion(); // Close the modal after saving/updating
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const editRecordPromotion = (index) => {
    setFormDataPromotion(recordsPromotion[index]);
    setEditingIndexPromotion(index);
    onOpenPromotion(); // Open the modal for editing
  };

  const deleteRecordPromotion = (index) => {
    const id = recordsPromotion[index].id;
    axios
      .delete(
        `https://divine.backenddd.in/HumanResource/deleteparticularemployeepromotion/${id}/`
      )
      .then((response) => {
        console.log("Deleted successfully:", response.data);
        fetchDataPromotion();
      })
      .catch((error) => {
        console.error("Error deleting record:", error);
      });
  };

  return (
    <>
      <Button
        className="addEmployeeBtn"
        onClick={() => {
          setEditingIndexPromotion(-1);
          setFormDataPromotion({
            staff: username,
            ref_number: "",
            post_promoted_to: "",
            post_promoted_from: "",
            responsibily_changed_to: "",
            promotion_date: "",
            docs: null,
          });
          onOpenPromotion();
        }}
      >
        <i class="fa-solid fa-plus AddDependIcon"></i> Add Record
      </Button>

      <Modal isOpen={isOpenPromotion} onClose={onClosePromotion}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {editingIndexPromotion >= 0 ? "Edit Record" : "Add Record"}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl isRequired>
              <FormLabel>Reference Number</FormLabel>
              <Input
                placeholder="Enter reference number"
                name="ref_number"
                value={formDataPromotion.ref_number}
                onChange={handleInputChangePromotion}
              />
            </FormControl>
            <FormControl mt={4} isRequired>
              <FormLabel>Post Promoted To</FormLabel>
              <Select
                placeholder="Select post promoted to"
                name="post_promoted_to"
                value={formDataPromotion.post_promoted_to}
                onChange={handleInputChangePromotion}
              >
                {positions.map((position) => (
                  <option key={position.id} value={position.id}>
                    {position.name}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl mt={4} isRequired>
              <FormLabel>Post Promoted From</FormLabel>
              <Select
                placeholder="Select post promoted from"
                name="post_promoted_from"
                value={formDataPromotion.post_promoted_from}
                onChange={handleInputChangePromotion}
              >
                {positions.map((position) => (
                  <option key={position.id} value={position.id}>
                    {position.name}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl mt={4} isRequired>
              <FormLabel>Responsibility Changed To</FormLabel>
              <Textarea
                placeholder="Describe the new responsibilities"
                name="responsibily_changed_to"
                value={formDataPromotion.responsibily_changed_to}
                onChange={handleInputChangePromotion}
              />
            </FormControl>
            <FormControl mt={4} isRequired>
              <FormLabel>Promotion Date</FormLabel>
              <Input
                type="date"
                name="promotion_date"
                value={formDataPromotion.promotion_date}
                onChange={handleInputChangePromotion}
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Attach Document</FormLabel>
              <Input
                type="file"
                name="docs"
                onChange={handleInputChangePromotion}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={saveData}>
              Save
            </Button>
            <Button onClick={onClosePromotion}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Box overflowX={"auto"}>
        <Table variant="simple" mt={4} className="addEmployeeTableFormat">
          <Thead>
            <Tr>
              <Th>Reference Number</Th>
              <Th>Post Promoted To</Th>
              <Th>Post Promoted From</Th>
              <Th>Change Responsibility To</Th>
              <Th>Promotion Date</Th>
              <Th>Document</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {recordsPromotion.map((record, index) => (
              <Tr key={record.id}>
                <Td>{record.ref_number}</Td>
                <Td>{record.ppt}</Td>
                <Td>{record.ppf}</Td>
                <Td>{record.responsibily_changed_to}</Td>
                <Td>{record.promotion_date}</Td>
                <Td>
                  {record.docs && (
                    <IconButton
                      icon={<ViewIcon />}
                      onClick={() =>
                        window.open(
                          `https://divine.backenddd.in${record.docs}/`,
                          "_blank"
                        )
                      }
                      aria-label="View Document"
                    />
                  )}
                </Td>
                <Td>
                  <IconButton
                    icon={<EditIcon />}
                    onClick={() => editRecordPromotion(index)}
                    aria-label="Edit"
                  />
                  <IconButton
                    icon={<DeleteIcon />}
                    onClick={() => deleteRecordPromotion(index)}
                    ml={2}
                    aria-label="Delete"
                  />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </>
  );
};

export default AddPromotion;
