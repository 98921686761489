import React, { useState, useEffect } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  IconButton,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  ChakraProvider,
  Box,
  Input,
} from "@chakra-ui/react";
import { ViewIcon, EditIcon, DeleteIcon } from "@chakra-ui/icons";
import axios from "axios";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const ViewFormat = () => {
  const [letters, setLetters] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isViewModalOpen, setViewModalOpen] = useState(false);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [formData, setFormData] = useState({ name: "", signature_name: "", content: "" });

  const fetchLetters = async () => {
    try {
      const response = await axios.get("https://divine.backenddd.in/Letter/api/save_certificate/");
      setLetters(response.data);
    } catch (error) {
      console.error("Error fetching entries:", error);
    }
  };

  const handleView = (row) => {
    setSelectedRow(row);
    setViewModalOpen(true);
  };

  const handleEdit = (row) => {
    setSelectedRow(row);
    setFormData({ name: row.name, signature_name: row.signature_name, content: row.content });
    setEditModalOpen(true);
  };

  const handleEditSave = async () => {
    try {
      const updatedRow = { ...formData };
      await axios.put(`https://divine.backenddd.in/Letter/certificates_detail_crud/${selectedRow.id}/`, updatedRow);
      fetchLetters(); // Refresh the list
      setEditModalOpen(false);
    } catch (error) {
      console.error("Error updating entry:", error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://divine.backenddd.in/Letter/certificates_detail_crud/${id}/`);
      fetchLetters(); // Refresh the list
    } catch (error) {
      console.error("Error deleting entry:", error);
    }
  };

  useEffect(() => {
    fetchLetters();
  }, []);

  return (
    <ChakraProvider>
      <Box overflowX={"auto"}>
        <Table variant="simple" className="leaveRequestTableFormat">
          <Thead>
            <Tr>
              <Th width={"20%"}>Sr. No</Th>
              <Th width={"30%"}>Format Name</Th>
              <Th width={"30%"}>Signature Name</Th>
              <Th width={"20%"}>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {letters.map((row, index) => (
              <Tr key={index}>
                <Td>{index + 1}</Td>
                <Td>{row.name}</Td>
                <Td>{row.signature_name}</Td>
                <Td>
                  <IconButton
                    icon={<ViewIcon />}
                    onClick={() => handleView(row)}
                    aria-label="View"
                  />
                  <IconButton
                    icon={<EditIcon />}
                    onClick={() => handleEdit(row)}
                    aria-label="Edit"
                  />
                  <IconButton
                    icon={<DeleteIcon />}
                    onClick={() => handleDelete(row.id)}
                    aria-label="Delete"
                  />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>

      {selectedRow && (
        <>
          <Modal isOpen={isViewModalOpen} onClose={() => setViewModalOpen(false)}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>View Details</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Box fontSize="md" textAlign="justify">
                  <div dangerouslySetInnerHTML={{ __html: selectedRow.content }} />
                </Box>
              </ModalBody>
              <ModalFooter>
                <Button colorScheme="blue" mr={3} onClick={() => setViewModalOpen(false)}>
                  Close
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>

          <Modal isOpen={isEditModalOpen} onClose={() => setEditModalOpen(false)}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Edit Details</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Box>
                  <Input
                    placeholder="Format Name"
                    value={formData.name}
                    onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                  />
                  <Input
                    placeholder="Signature Name"
                    value={formData.signature_name}
                    onChange={(e) => setFormData({ ...formData, signature_name: e.target.value })}
                    mt={4}
                  />
                  <Box mt={4}>
                    <CKEditor
                      editor={ClassicEditor}
                      data={formData.content}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setFormData({ ...formData, content: data });
                      }}
                    />
                  </Box>
                </Box>
              </ModalBody>
              <ModalFooter>
                <Button colorScheme="blue" mr={3} onClick={handleEditSave}>
                  Save
                </Button>
                <Button variant="ghost" onClick={() => setEditModalOpen(false)}>
                  Cancel
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </>
      )}
    </ChakraProvider>
  );
};

export default ViewFormat;
