import React, { useState } from "react";
import axios from "axios";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";

const AddWorkingDept = ({ depts }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [name, setName] = useState("");
  const [dept, setDept] = useState("");
  const toast = useToast();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(
        "https://divine.backenddd.in/HumanResource/api/Createworkingdepartments/",
        { name, dept }
      );
      toast({
        title: "Working Department created successfully!",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setName("");
      setDept("");
    } catch (error) {
      console.error("Error creating working department:", error);
      toast({
        title:
          "An error occurred while creating the working department. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
    onClose();
  };

  // const [formData, setFormData] = useState({
  //   name: "",
  //   dept: "",
  // });

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData((prevFormData) => ({
  //     ...prevFormData,
  //     [name]: value,
  //   }));
  // };

  return (
    <>
      <Button
        className="addButtonEmp"
        leftIcon={<AddIcon />}
        colorScheme="teal"
        variant="solid"
        onClick={onOpen}
      >
        Add
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Working Department</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl id="name">
              <FormLabel>Name of Working Department</FormLabel>
              <Input
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Working Department"
              />
            </FormControl>

            <FormControl id="department" mt={4}>
              <FormLabel>Department</FormLabel>
              <Select
                value={dept}
                onChange={(e) => setDept(e.target.value)}
                placeholder="Select Department"
              >
                {depts.map((dept) => (
                  <option key={dept.id} value={dept.id}>
                    {dept.name}
                  </option>
                ))}
              </Select>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="teal" mr={3} onClick={handleSubmit}>
              Save
            </Button>
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddWorkingDept;
