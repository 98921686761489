import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Button,
  ChakraProvider,
  FormControl,
  FormLabel,
  Input,
  Stack,
  List,
  ListItem,
  Flex,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useToast,
} from "@chakra-ui/react";
import { DeleteIcon, EditIcon } from "@chakra-ui/icons";

const AddDocumentFormat = ({ departments, onDataChange }) => {
  const [name, setName] = useState("");
  // const [departments, setDepartments] = useState([]);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const toast = useToast();

  useEffect(() => {
    // Fetch departments from server when component mounts
    fetchDepartments();
  }, []);

  const fetchDepartments = async () => {
    try {
      const response = await axios.get(
        "https://divine.backenddd.in/HumanResource/api/departments/"
      );
      // setDepartments(response.data);
    } catch (error) {
      console.error("Error fetching departments:", error);
      // Handle error
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(
        "https://divine.backenddd.in/HumanResource/api/Createdepartments/",
        {
          name,
        }
      );
      toast({
        title: "Department created successfully!",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      // Refetch departments after adding a new one
      fetchDepartments();
      onDataChange();
      setName(""); // Clear the input field
    } catch (error) {
      console.error("Error creating department:", error);
      toast({
        title:
          "An error occurred while creating the department. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleDelete = async (id) => {
    setIsDeleteModalOpen(true);
    setSelectedDepartment({ id });
  };

  const confirmDelete = async () => {
    try {
      await axios.delete(
        `https://divine.backenddd.in/HumanResource/api/departments/delete/${selectedDepartment.id}/`
      );
      toast({
        title: "Department deleted successfully!",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      // Refetch departments after deletion
      fetchDepartments();
      onDataChange();
      setIsDeleteModalOpen(false);
    } catch (error) {
      console.error("Error deleting department:", error);
      toast({
        title:
          "An error occurred while deleting the department. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleEdit = (department) => {
    setSelectedDepartment(department);
    setIsEditModalOpen(true);
  };

  const handleCloseEditModal = () => {
    setSelectedDepartment(null);
    setIsEditModalOpen(false);
  };

  const handleSaveEdit = async () => {
    try {
      await axios.put(
        `https://divine.backenddd.in/HumanResource/api/departments/${selectedDepartment.id}/`,
        {
          name: selectedDepartment.name,
        }
      );
      toast({
        title: "Department updated successfully!",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setIsEditModalOpen(false);
      onDataChange();
      // Refetch departments after editing
      fetchDepartments();
    } catch (error) {
      console.error("Error updating department:", error);
      toast({
        title:
          "An error occurred while updating the department. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <ChakraProvider>
      <Box
        maxW="md"
        mx="auto"
        mt="8"
        // p="4"
        borderWidth="1px"
        borderRadius="lg"
        className="addDepartmentStyling"
      >
        <form onSubmit={handleSubmit}>
          <h5 variant="h5" gutterBottom className="departmentHeading">
            Add Document Format
          </h5>

          <div className="inputBox">
            <div className="row">
              <div className="col-12">
                <FormControl id="departmentName">
                  <Input
                    type="text"
                    value={name}
                    placeholder="Enter Document Name ..."
                    onChange={(e) => setName(e.target.value)}
                  />
                </FormControl>
              </div>
              <div className="col-6">
                <Button
                  colorScheme="blue"
                  className="depatmentBtn"
                  type="submit"
                >
                  Create
                </Button>
              </div>
            </div>
          </div>
        </form>
        <div className="departmentData">
          <List>
            {departments.map((department) => (
              <ListItem key={department.id} borderBottom="1px solid #ccc">
                <Flex align="center" justify="space-between">
                  {department.name}
                  <Flex>
                    <IconButton
                      aria-label="Edit"
                      icon={<EditIcon />}
                      mr="2"
                      onClick={() => handleEdit(department)}
                    />
                    <IconButton
                      aria-label="Delete"
                      icon={<DeleteIcon />}
                      onClick={() => handleDelete(department.id)}
                    />
                  </Flex>
                </Flex>
              </ListItem>
            ))}
          </List>
        </div>
        <Modal isOpen={isEditModalOpen} onClose={handleCloseEditModal}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Edit Department</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormControl>
                <FormLabel>Department Name</FormLabel>
                <Input
                  type="text"
                  value={selectedDepartment ? selectedDepartment.name : ""}
                  onChange={(e) =>
                    setSelectedDepartment({
                      ...selectedDepartment,
                      name: e.target.value,
                    })
                  }
                />
              </FormControl>
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="blue" onClick={handleSaveEdit}>
                Save
              </Button>
              <Button onClick={handleCloseEditModal}>Cancel</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        <Modal
          isOpen={isDeleteModalOpen}
          onClose={() => setIsDeleteModalOpen(false)}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Confirm Delete</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              Are you sure you want to delete this department?
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="red" onClick={confirmDelete}>
                Delete
              </Button>
              <Button onClick={() => setIsDeleteModalOpen(false)}>
                Cancel
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>
    </ChakraProvider>
  );
};

export default AddDocumentFormat;
