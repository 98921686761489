import { ChakraProvider } from "@chakra-ui/react";
import React , { useEffect, useState } from "react";
import NavigationMenu from "../Navigation/NavigationMenu";
import AddDepartment from "./AddDepartment";
import AddWorkingDepartment from "./AddWorkingDepartment";
import AddDesignation from "./AddDesignation";
import "./AddMaster.css";
import axios from "axios";

const AddMaster = () => {
  const [departments, setDepartments] = useState([]);
  const [designations, setDesignations] = useState([]);
  const [WorkingDepartments, setWorkingDepartments] = useState([]);
  const [positions, setPositions] = useState([]);

  useEffect(() => {
    // Fetch departments from server when component mounts
    fetchDepartments();
  }, []);

  const fetchDepartments = async () => {
    try {
      const response = await axios.get(
        "https://divine.backenddd.in/HumanResource/api/departments/"
      );
      setDepartments(response.data);
    } catch (error) {
      console.error("Error fetching departments:", error);
      // Handle error
    }
  };

  useEffect(() => {
    // Fetch WorkingDepartments and departments from server when component mounts
    fetchWorkingDepartments();
  }, []);

  const fetchWorkingDepartments = async () => {
    try {
      const response = await axios.get(
        "https://divine.backenddd.in/HumanResource/api/working_departments/"
      );
      setWorkingDepartments(response.data);
    } catch (error) {
      console.error("Error fetching WorkingDepartments:", error);
    }
  };

  useEffect(() => {
    // Fetch positions and departments from server when component mounts
    fetchPositions();
  }, []);

  const fetchPositions = async () => {
    try {
      const response = await axios.get(
        "https://divine.backenddd.in/HumanResource/api/positions/"
      );
      setPositions(response.data);
    } catch (error) {
      console.error("Error fetching positions:", error);
    }
  };

  const handleDataChange = () => {
    fetchDepartments();
    fetchPositions();
    fetchWorkingDepartments();
  };

  return (
    <>
      <NavigationMenu />
      <ChakraProvider>
        <div className="addMasterWrapper">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-md-12 col-lg-12">
                <div className="addHrPolicyHeading">
                  <h1>Add Master</h1>
                  <p>
                    Click on Create button to add the{" "}
                    <span> Department, Working Dept, Designation </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-4 col-lg-4">
              <AddDepartment
                  departments={departments}
                  onDataChange={handleDataChange}
                />
              </div>
              <div className="col-12 col-md-4 col-lg-4">
                <AddWorkingDepartment
                  workingDepartments={WorkingDepartments}
                  departments={departments}
                  onDataChange={handleDataChange}
                />
              </div>
              <div className="col-12 col-md-4 col-lg-4">
                <AddDesignation
                  positions={positions}
                  departments={departments}
                  onDataChange={handleDataChange}
                />
              </div>
            </div>
          </div>
        </div>
      </ChakraProvider>
    </>
  );
};

export default AddMaster;
