import React, { useState, useRef, useEffect } from "react";
import NavigationMenu from "../Navigation/NavigationMenu";
import "./ViewEmployee.css";
import ReactToPrint from "react-to-print";

import {
  FormControl,
  FormLabel,
  Select,
  Input,
  ChakraProvider,
  Button,
  Icon,
  InputGroup,
  InputRightElement,
  IconButton,
  VStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Image,
  useDisclosure,
  ModalFooter,
  Textarea,
  List,
  ListItem,
  Link,
  Text,
  Checkbox,

} from "@chakra-ui/react";
import axios from "axios";
import { input, Space } from "antd";
import { AiOutlineEye } from "react-icons/ai";
import {
  ViewIcon,
  ViewOffIcon,
  DeleteIcon,
  AttachmentIcon,
  ExternalLinkIcon,
  CheckIcon,
  EditIcon,
} from "@chakra-ui/icons";

import AddDependentMember from "./DependentMember/AddDependentMember";
import AddDisciplinaryRecords from "./Disciplinary/AddDisciplinaryRecords";
import AddSalary from "./Salary/AddSalary";
import AddPromotion from "./Promotion/AddPromotion";
import AddPerformance from "./Performance/AddPerformance";
import AddTraining from "./Training/AddTraining";
import { useNavigate } from "react-router-dom";
import AddAdditionalField from "./AdditionalField/AddAdditionalField";
import AddUploadDocument from "./UploadDocuments/AddUploadDocument";
import moment from "moment";
import "./AddEmployee.css";
import { useLocation } from "react-router-dom";
import AddTrainingOne from "./Training/AddTrainingOne";
import AddPerformanceOne from "./Performance/AddPerformanceOne";

const EmployeeImageModal = ({ employee }) => {
  const {
    isOpen: imgModelisOpen,
    onOpen: imgModelonOpen,
    onClose: imgModelonClose,
  } = useDisclosure();

  const handleImageClick = (e) => {
    e.stopPropagation(); // Prevent any parent click event
    imgModelonOpen();
  };

  return (
    <>
      <Image
        src={`https://divine.backenddd.in${employee.image}/`}
        alt={employee.name}
        boxSize="100px"
        mr="10px"
        mb="10px"
        borderRadius="50%"
        className="profilePictures"
        onClick={handleImageClick}
        cursor="pointer"
        loading='lazy'
      />

      <Modal isOpen={imgModelisOpen} onClose={imgModelonClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody display="flex" justifyContent="center" alignItems="center">
            <Image
              src={`https://divine.backenddd.in${employee.image}/`}
              alt={employee.name}
              maxWidth="100%"
              maxHeight="100%"
              loading='lazy'
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

const ViewEmployees = () => {
  const printRef = useRef();

  const location = useLocation();

  // ***********start profile image properties
  const [selectedImage, setSelectedImage] = useState(null);
  const [aadharCard, setAadharCard] = useState([]);
  const [panCard, setPanCard] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [employee, setEmployee] = useState({});
  const [staff, setStaff] = useState("");
  const [userStatus, setUserStatus] = useState(false);
  const [canUpdateStatus, setCanUpdateStatus] = useState(false);
  const [attachedFiles10th, setAttachedFiles10th] = useState([]);
  const [attachedFiles12th, setAttachedFiles12th] = useState([]);
  const [attachedFilesPostGraduation, setAttachedFilesPostGraduation] =
    useState([]);
  const [attachedFilesGraduation, setAttachedFilesGraduation] = useState([]);
  const [workingDept, setWorkingDept] = useState([]);
  const [file, setFile] = useState(null); // State to store the file object
  const [username, setUsername] = useState("");

  const [depts, setDepts] = useState([]);
  const [positions, setPositions] = useState([]);
  const [marital_statuses, setMaritalStatuses] = useState([]);
  const [blood_groups, setBloodGroups] = useState([]);
  const [additionalFields, setAdditionalFields] = useState([]);
  const navigate = useNavigate();
  const [formSaved, setFormSaved] = useState(false);

  const [formData, setFormData] = useState({
    dependant_schooling_10th: false,
    dependant_schooling_12th: false,
    dependant_schooling_graduation: false,
    dependant_schooling_post_graduation: false,
  });

  const [modalOpen, setModalOpen] = useState({
    dependant_schooling_10th: false,
    dependant_schooling_12th: false,
    dependant_schooling_graduation: false,
    dependant_schooling_post_graduation: false,
  });

  const [attachedFiles, setAttachedFiles] = useState({
    dependant_schooling_10th: [],
    dependant_schooling_12th: [],
    dependant_schooling_graduation: [],
    dependant_schooling_post_graduation: [],
  });

  const getInitialEid = () => {
    const eidFromState = location.state?.eid;
    if (eidFromState) {
      localStorage.setItem("eid", eidFromState);
      return eidFromState;
    }
    return localStorage.getItem("eid");
  };

  const [eid, setEid] = useState(getInitialEid());

  const validateFile = (file) => {
    const validTypes = ["image/jpeg", "image/png", "application/pdf"];
    const maxSize = 50 * 1024 * 1024; // 50 MB

    if (!validTypes.includes(file.type)) {
      // console.error("Invalid file type. Allowed types are JPEG, PNG, and PDF.");
      return false;
    }

    if (file.size > maxSize) {
      // console.error("File is too large. Maximum allowed size is 5MB.");
      return false;
    }

    return true;
  };

  const handleFileChange = (event, key) => {
    const files = event.target.files;
    const filesWithUrls = Array.from(files)
      .map((file) => {
        const formData = new FormData();
        formData.append("doc", file);
        formData.append("name", key); // You might want to pass other related data
        formData.append("username", username);
        // Replace URL and setup with your actual upload logic
        fetch(
          "https://divine.backenddd.in/HumanResource/staff-form-documents/",
          {
            method: "POST",
            body: formData,
          }
        )
          .then((response) => response.json())
          .then((data) => {
            // console.log('File uploaded successfully', data);
            fetchFiles();
          })
          .catch((error) => {
            // console.error('Error uploading file:', error);
          });

        if (!validateFile(file)) {
          alert("Invalid file type or size.");
          return null; // Skip this file if validation fails
        }
        return {
          ...file,
          url: URL.createObjectURL(file), // Set the URL property for each file
          name: file.name, // Retain the original file name
        };
      })
      .filter((file) => file !== null); // Remove null entries from failed validations

    setAttachedFiles((prevAttachedFiles) => ({
      ...prevAttachedFiles,
      [key]: [...prevAttachedFiles[key], ...filesWithUrls],
    }));
  };

  const handleChange = (event, key) => {
    const { name, value, checked, type, files } = event.target;

    // if (name === "username") {
    //   setUsername(value);
    // }
    if (type === "file") {
      if (files && files.length > 0) {
        const file = files[0];
        setFile(file);
      } else {
        setFile(null);
      }
    } else if (type === "checkbox") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: checked,
      }));

      if (!checked) {
        setAttachedFiles((prevAttachedFiles) => ({
          ...prevAttachedFiles,
          [key]: [],
        }));
      }
    } else {
      // Handling all other inputs (text, email, etc.)
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };


  useEffect(() => {
    const accessToken = localStorage.getItem("access_token");

    const fetchData = async () => {
      const response = await axios.get(
        `https://divine.backenddd.in/HumanResource/view_employee_detail/${eid}/`
      );
      setEmployee(response.data);
      setUsername(response.data.username);

    };

    fetchDocuments();
    fetchData();
  }, []);

  const fetchDocuments = async () => {
    try {
      const response = await fetch(
        `https://divine.backenddd.in/HumanResource/staff_additional_text_field/${eid}/`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch documents");
      }
      const data = await response.json();
      // console.log("Data: ", data); // Log the data variable here
      setDocuments(data.files_data);
      setStaff(data.staff);

      const fetchDependants = async () => {
        try {
          const response = await axios.get(
            `https://divine.backenddd.in/HumanResource/getdependants/${data.staff.username}/`
          );
          //   setDependants(response.data);
        } catch (error) {
          console.error("Error fetching dependant family data:", error);
          // Handle error fetching data
        }
      };

      //   setTextField(data.files_data.map((fileData) => fileData.documents));
      fetchDependants();

      const fetchAdditionalFields = async () => {
        // if (!username) return;

        try {
          const response = await axios.get(
            `https://divine.backenddd.in/HumanResource/get_staff_form_additional_fields/${data.staff.username}/`
          );
          setAdditionalFields(response.data);
        } catch (error) {
          // console.error('Failed to fetch additional fields:', error);
        }
      };

      fetchAdditionalFields();
    } catch (error) {
      console.error("Error fetching documents:", error);
    }
  };


  const fetchFiles = async () => {
    try {
      const response = await fetch(
        `https://divine.backenddd.in/HumanResource/getuserwisedocs/${username}/`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const files = await response.json();
      // console.log("Attached Files: ", files)
      setAttachedFiles10th(
        files.filter((file) => file.name === "dependant_schooling_10th")
      );
      setAttachedFiles12th(
        files.filter((file) => file.name === "dependant_schooling_12th")
      );
      setAttachedFilesGraduation(
        files.filter((file) => file.name === "dependant_schooling_graduation")
      );
      setAttachedFilesPostGraduation(
        files.filter(
          (file) => file.name === "dependant_schooling_post_graduation"
        )
      );
    } catch (error) {
      // console.error('Failed to fetch files:', error);
    }
  };

  useEffect(() => {
    if (username) {
      fetchFiles();
      fetchAdditionalFields();
      fetchAadharCardDetails();
      fetchPanCardDetails();
    }
  }, [username]); // Fetch files when username changes

  const handleDeleteFile = async (fileId) => {
    try {
      const response = await fetch(
        `https://divine.backenddd.in/HumanResource/deletestaffformdocs/${fileId}/`,
        {
          method: "POST",
        }
      );
      if (!response.ok) throw new Error("Failed to delete the document");

      fetchFiles();
    } catch (error) {
      // console.error('Error deleting file:', error);
      alert("Failed to delete the file.");
    }
  };

  const fetchAdditionalFields = async () => {
    // if (!username) return;

    try {
      const response = await axios.get(
        `https://divine.backenddd.in/HumanResource/get_staff_form_additional_fields/${username}/`
      );
      setAdditionalFields(response.data);
    } catch (error) {
      // console.error('Failed to fetch additional fields:', error);
    }
  };


  const {
    isOpen: isOpenProfileImage,
    onOpen: onOpenProfileImage,
    onClose: onCloseProfileImage,
  } = useDisclosure();

  const handleImageChange = (e) => {
    // console.warn("test image butn", e);
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  // end  profile image properties

  // ***********start password show properties
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // end password show properties

  //  ***********start: date of joining and resign properties
  const [selectedDateJoining, setSelectedDateJoining] = useState(null);
  const [selectedDateResign, setSelectedDateResign] = useState(null);
  //  end: date of joining and resign properties

  // *********** start AADHAR CARD properties *********************************************************************
  const {
    isOpen: isOpenAdharCard,
    onOpen: onOpenAdharCard,
    onClose: onCloseAdharCard,
  } = useDisclosure();

  const [filesAdharCard, setFilesAdharCard] = useState([]); // Tracks attached files

  // Triggers the file input when the attachment icon is clicked
  // const handleAttachClickAdharCard = () => {
  // };

  const fileInputRefAdharCard = useRef(null);

  const fetchAadharCardDetails = async () => {
    try {
      const response = await axios.get(
        `https://divine.backenddd.in/HumanResource/get_aadhar_details/${username}/`
      );
      if (response.status === 200) {
        setAadharCard(response.data);
      } else {
        console.error("Failed to fetch Aadhar details");
      }
    } catch (error) {
      console.error("Error fetching Aadhar details: ", error);
    }
  };

  const fetchPanCardDetails = async () => {
    try {
      const response = await axios.get(
        `https://divine.backenddd.in/HumanResource/get_pan_details/${username}/`
      );
      if (response.status === 200) {
        setPanCard(response.data);
      } else {
        console.error("Failed to fetch Aadhar details");
      }
    } catch (error) {
      console.error("Error fetching Aadhar details: ", error);
    }
  };

  const handleAttachClickAdharCard = async () => {
    // Open file dialog to choose a file
    fileInputRefAdharCard.current.click();
  };

  const handleAttachFileAdharCard = async (event) => {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];

      // Prepare the form data for submission
      const formData = new FormData();
      formData.append("staff", username); // Assuming 'username' is the staff identifier
      formData.append("file_name", "adhar_card"); // Set file_name as required by your backend
      formData.append("file", file);

      // Perform the upload
      try {
        const response = await fetch(
          "https://divine.backenddd.in/HumanResource/upload_adhar_pan/",
          {
            method: "POST",
            body: formData,
          }
        );

        if (response.ok) {
          // Handle success
          fetchAadharCardDetails();
          console.log("File uploaded successfully");
        } else {
          // Handle errors
          console.error("Failed to upload file");
        }
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    }
  };

  const handleDeleteFileAdharCard = async (id) => {
    try {
      const response = await axios.delete(
        `https://divine.backenddd.in/HumanResource/delete_aadhar_details/${id}/`
      );

      if (response.status === 200) {
        // Correctly check the status property of the response object
        fetchAadharCardDetails(); // Refresh the data
      } else {
        console.error("Failed to delete file:", response.status);
      }
    } catch (error) {
      console.error("Error deleting file:", error);
      alert("Deletion Failed"); // Provide feedback on failure
    }
  };

  // end AADHAR CARD properties

  const handleAttachFilePanCard = async (event) => {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];

      // Prepare the form data for submission
      const formData = new FormData();
      formData.append("staff", username); // Assuming 'username' is the staff identifier
      formData.append("file_name", "pan_card"); // Set file_name as required by your backend
      formData.append("file", file);

      // Perform the upload
      try {
        const response = await fetch(
          "https://divine.backenddd.inHumanResource/upload_pan_card/",
          {
            method: "POST",
            body: formData,
          }
        );

        if (response.ok) {
          // Handle success
          fetchPanCardDetails();
          console.log("File uploaded successfully");
        } else {
          // Handle errors
          console.error("Failed to upload file");
        }
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    }
  };

  const handleDeleteFilePanCard = async (id) => {
    try {
      const response = await axios.delete(
        `https://divine.backenddd.in/HumanResource/delete_pan_details/${id}/`
      );

      if (response.status === 200) {
        // Correctly check the status property of the response object
        fetchPanCardDetails(); // Refresh the data
      } else {
        console.error("Failed to delete file:", response.status);
      }
    } catch (error) {
      console.error("Error deleting file:", error);
      alert("Deletion Failed"); // Provide feedback on failure
    }
  };

  // *********** start PAN CARD properties
  const {
    isOpen: isOpenPanCard,
    onOpen: onOpenPanCard,
    onClose: onClosePanCard,
  } = useDisclosure();

  const [filesPanCard, setFilesPanCard] = useState([]); // Tracks attached files
  const fileInputRefPanCard = useRef(null); // Reference to the hidden file input

  // Triggers the file input when the attachment icon is clicked
  const handleAttachClickPanCard = () => {
    fileInputRefPanCard.current.click();
  };

  const [isTenthVisible, setIsTenthVisible] = useState(false);

  const {
    isOpen: isOpenTenth,
    onOpen: onOpenTenth,
    onClose: onCloseTenth,
  } = useDisclosure();

  const [filesTenth, setFilesTenth] = useState([]); // Tracks attached files
  const fileInputRefTenth = useRef(null); // Reference to the hidden file input

  // Triggers the file input when the attachment icon is clicked
  const handleAttachClickTenth = () => {
    fileInputRefTenth.current.click();
  };

  // Handles deletion of a specific file
  const handleDeleteFileTenth = (url) => {
    setFilesTenth(filesTenth.filter((file) => file.url !== url));
    URL.revokeObjectURL(url); // Clean up the object URL
  };
  // End TENTH properties

  // Start Twelth properties
  const [isTwelthVisible, setIsTwelthVisible] = useState(false);

  const {
    isOpen: isOpenTwelth,
    onOpen: onOpenTwelth,
    onClose: onCloseTwelth,
  } = useDisclosure();

  const [filesTwelth, setFilesTwelth] = useState([]); // Tracks attached files
  const fileInputRefTwelth = useRef(null); // Reference to the hidden file input

  // Triggers the file input when the attachment icon is clicked
  const handleAttachClickTwelth = () => {
    fileInputRefTwelth.current.click();
  };

  // Start Graduation properties
  const [isGraduationVisible, setIsGraduationVisible] = useState(false);

  const {
    isOpen: isOpenGraduation,
    onOpen: onOpenGraduation,
    onClose: onCloseGraduation,
  } = useDisclosure();

  const [filesGraduation, setFilesGraduation] = useState([]); // Tracks attached files
  const fileInputRefGraduation = useRef(null); // Reference to the hidden file input

  // Triggers the file input when the attachment icon is clicked
  const handleAttachClickGraduation = () => {
    fileInputRefGraduation.current.click();
  };

  // Start Master properties
  const [isMasterVisible, setIsMasterVisible] = useState(false);

  const {
    isOpen: isOpenMaster,
    onOpen: onOpenMaster,
    onClose: onCloseMaster,
  } = useDisclosure();

  const fileInputRefMaster = useRef(null); // Reference to the hidden file input

  // Triggers the file input when the attachment icon is clicked
  const handleAttachClickMaster = () => {
    fileInputRefMaster.current.click();
  };

  // Handle Date Change
  const handleDateChange = (date, dateString, key) => {
    // Format the date as YYYY-MM-DD before setting it to state
    const formattedDate = date ? moment(date).format("YYYY-MM-DD") : null;
    setFormData((prevState) => ({
      ...prevState,
      [key]: formattedDate,
    }));
  };


  const [leaveData, setLeaveData] = useState({
    cl: 7,
    el: 15,
    nh: 7,
    sl: 7,
  });

  const handleLeaveChange = (e) => {
    const { name, value } = e.target;
    setLeaveData({
      ...leaveData,
      [name]: value,
    });
  };

  useEffect(() => {
    if (employee.esi_no == "Not Covered") {
      setLeaveData((prevData) => ({
        ...prevData,
        sl: 7,
      }));
    } else {
      setLeaveData((prevData) => ({
        ...prevData,
        sl: 0,
      }));
    }
  }, [employee.esi_no]);

  return (
    <>
      <NavigationMenu />
      {
        // console.log(employee, "getting all information")
      }
      <ChakraProvider>
        <div className="addEmployeeComponent printable-section" ref={printRef} >
          <div className="container-fluid">
            <div className="row">
              <div className="col-9">
                <div className="addEmployeeHeading">
                  <h1>View Employee</h1>
                  <p>
                    Click on <span>SAVE</span> button at the bottom everytime
                    any progress is made of form
                  </p>
                </div>
              </div>
              <div className="col-3">
                <EmployeeImageModal employee={employee} />
              </div>
            </div>
            <div ref={printRef} className="addEmployeeFieldSection">
              <div className="addEmployeeFieldSectionHeading">
                <h2>General Details</h2>
              </div>

              <form
                // onSubmit={handleSubmit}
                className="addEmployeeFieldSectionForm"
              >
                <div className="row">
                  <div className="col-4">
                    <FormControl
                      fullWidth
                      isRequired
                      className="addEmployeeFormGroup"
                    >
                      {/* <lable id="depts">Department</lable> */}
                      <FormLabel htmlFor="department">Department</FormLabel>
                      {/* <Select
                        id="department"
                        value={employee.dept || ""}
                        label="Department"
                        name="dept"
                        onChange={handleChange}
                      >
                        <option value={employee.dept}>
                          {employee.dep_name}
                        </option>
                        {depts.map((dept) => (
                          <option value={dept.id}>{dept.name}</option>
                        ))}
                      </Select> */}
                      <Input
                        value={employee.dep_name}
                        style={{ pointerEvents: "none" }}
                      ></Input>
                    </FormControl>
                  </div>
                  <div className="col-4">
                    <FormControl
                      fullWidth
                      isRequired
                      className="addEmployeeFormGroup"
                    >
                      <FormLabel htmlFor="working_department">
                        Working Department {workingDept}
                      </FormLabel>

                      <Input
                        value={employee.wor_dep_name}
                        style={{ pointerEvents: "none" }}
                      ></Input>
                    </FormControl>
                  </div>
                  <div className="col-4">
                    <FormControl
                      fullWidth
                      isRequired
                      className="addEmployeeFormGroup"
                    >
                      <FormLabel htmlFor="designation">Designation</FormLabel>

                      <Input
                        value={employee.pos_name}
                        style={{ pointerEvents: "none" }}
                      ></Input>
                    </FormControl>
                  </div>

                  <div className="col-4">
                    <FormControl isRequired className="addEmployeeFormGroup">
                      <FormLabel htmlFor="employee_id">Employee ID</FormLabel>
                      <Input
                        id="employee_id"
                        type="text"
                        name="staff_id"
                        value={employee.staff_id}
                        placeholder="Enter Employee ID"
                      />
                    </FormControl>
                  </div>

                  <div className="col-4">
                    <FormControl isRequired className="addEmployeeFormGroup">
                      <FormLabel htmlFor="first_name">First Name</FormLabel>
                      <Input
                        id="first_name"
                        type="text"
                        name="fname"
                        value={employee.fname}
                        onChange={handleChange}
                        placeholder="Enter First Name"
                      />
                    </FormControl>
                  </div>

                  <div className="col-4">
                    <FormControl isRequired className="addEmployeeFormGroup">
                      <FormLabel htmlFor="last_name">Last Name</FormLabel>
                      <Input
                        id="last_name"
                        type="text"
                        name="lname"
                        value={employee.lname}
                        onChange={handleChange}
                        placeholder="Enter Last Name"
                      />
                    </FormControl>
                  </div>

                  <div className="col-4">
                    <FormControl isRequired className="addEmployeeFormGroup">
                      <FormControl>
                        {employee.image && (
                          <>
                            <FormLabel htmlFor="profile_picture">
                              Replace Image
                            </FormLabel>
                          </>
                        )}
                        <input
                          id="profile_picture"
                          type="file"
                          onChange={handleChange}
                          name="image"
                        // value={employee.image}
                        />
                      </FormControl>
                      {selectedImage && (
                        <VStack mt={4}>
                          <Button
                            leftIcon={<Icon as={AiOutlineEye} />}
                            onClick={onOpenProfileImage}
                          >
                            View Image
                          </Button>
                        </VStack>
                      )}{" "}
                    </FormControl>
                  </div>

                  <div className="col-4">
                    <FormControl isRequired className="addEmployeeFormGroup">
                      <FormLabel htmlFor="father_name">
                        Father / Husband Name
                      </FormLabel>
                      <Input
                        id="father_name"
                        type="text"
                        value={employee.father_name}
                        name="father_name"
                        placeholder="Enter Father / Husband Name"
                        onChange={handleChange}
                      />
                    </FormControl>
                  </div>

                  <div className="col-4">
                    <FormControl isRequired className="addEmployeeFormGroup">
                      <FormLabel htmlFor="father_occupation">
                        Father / Husband Occupation
                      </FormLabel>
                      <Input
                        id="father_occupation"
                        type="text"
                        value={employee.father_occupation}
                        name="father_occupation"
                        placeholder="Enter Father / Husband Occupation"
                        onChange={handleChange}
                      />
                    </FormControl>
                  </div>

                  <div className="col-4">
                    <FormControl isRequired className="addEmployeeFormGroup">
                      <FormLabel htmlFor="mother_name">Mother Name</FormLabel>
                      <Input
                        id="mother_name"
                        type="text"
                        value={employee.mother_name}
                        name="mother_name"
                        placeholder="Enter Mother Name"
                        onChange={handleChange}
                      />
                    </FormControl>
                  </div>

                  <div className="col-4">
                    <FormControl isRequired className="addEmployeeFormGroup">
                      <FormLabel>Create Username</FormLabel>
                      <Input
                        // id="username"
                        type="text"
                        autoFocus={employee.username}
                        value={employee.username}
                        placeholder="Enter Username"
                      // name="username"
                      // onChange={handleChange}
                      />
                    </FormControl>
                  </div>

                  <div className="col-4">
                    <FormControl isRequired className="addEmployeeFormGroup">
                      <FormLabel htmlFor="password">Create Password</FormLabel>
                      <InputGroup>
                        <Input
                          id="password"
                          type={showPassword ? "text" : "password"}
                          placeholder="Enter Create Password"
                          value={employee.password}
                          name="password"
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        <InputRightElement>
                          <IconButton
                            variant="ghost"
                            icon={showPassword ? <ViewOffIcon /> : <ViewIcon />}
                            onClick={handleTogglePasswordVisibility}
                          />
                        </InputRightElement>
                      </InputGroup>
                    </FormControl>
                  </div>
                  <div className="col-6">
                    <FormControl isRequired className="addEmployeeFormGroup">
                      <FormLabel htmlFor="temporary_address">
                        Temporary Complete Address
                      </FormLabel>
                      <Textarea
                        value={employee.temporary_address}
                        id="temporary_address"
                        placeholder="Enter Temporary Address"
                        name="temporary_address"
                        onChange={handleChange}
                      />
                    </FormControl>
                  </div>
                  <div className="col-6">
                    <FormControl isRequired className="addEmployeeFormGroup">
                      <FormLabel htmlFor="permanent_address">
                        Permanent Complete Address
                      </FormLabel>
                      <Textarea
                        value={employee.permanent_address}
                        id="permanent_address"
                        placeholder="Enter Permanent Address"
                        name="permanent_address"
                        onChange={handleChange}
                      />
                    </FormControl>
                  </div>
                  <div className="col-4">
                    <FormControl isRequired className="addEmployeeFormGroup">
                      <FormLabel htmlFor="primary_phone">
                        Primary Phone Number
                      </FormLabel>
                      <Input
                        id="primary_phone"
                        value={employee.mobile_number}
                        type="text"
                        placeholder="Enter Primary Phone No."
                        name="mobile_number"
                        onChange={handleChange}
                      />
                    </FormControl>
                  </div>

                  <div className="col-4">
                    <FormControl isRequired className="addEmployeeFormGroup">
                      <FormLabel htmlFor="alternative_phone">
                        Alternate Number
                      </FormLabel>
                      <Input
                        value={employee.second_mobile_number}
                        id="alternative_phone"
                        type="text"
                        placeholder="Enter Secondary Phone No."
                        name="second_mobile_number"
                        onChange={handleChange}
                      />
                    </FormControl>
                  </div>

                  <div className="col-4">
                    <FormControl isRequired className="addEmployeeFormGroup">
                      <FormLabel htmlFor="email_id">Email ID</FormLabel>
                      <Input
                        id="email_id"
                        value={employee.email}
                        type="text"
                        placeholder="Enter Email ID"
                        name="email"
                        onChange={handleChange}
                      />
                    </FormControl>
                  </div>

                  <div className="col-4">
                    <FormControl fullWidth className="addEmployeeFormGroup">
                      <FormLabel htmlFor="marital_status">
                        Marital Status
                      </FormLabel>
                      {/* <Select
                        id="marital_status"
                        value={employee.marital_status || ""}
                        label="Marital Status"
                        name="marital_status"
                        onChange={handleChange}
                      >
                        <option value={employee.marital_status}>
                          {employee.mar_name}
                        </option>
                        {marital_statuses.map((dept) => (
                          <option value={dept.id}>{dept.name}</option>
                        ))}
                      </Select> */}
                      <Input
                        value={employee.mar_name}
                        style={{ pointerEvents: "none" }}
                      ></Input>
                    </FormControl>
                  </div>

                  <div className="col-4">
                    <FormControl
                      fullWidth
                      isRequired
                      className="addEmployeeFormGroup"
                    >
                      <FormLabel htmlFor="blood_group">Blood Group</FormLabel>
                      {/* <Select
                        id="blood_group"
                        value={employee.blood_group || ""}
                        label="Blood Group"
                        name="blood_group"
                        onChange={handleChange}
                      >
                        <option value="">--Select Blood Group--</option>
                        {blood_groups.map((dept) => (
                          <option value={dept.id}>{dept.name}</option>
                        ))}
                      </Select> */}
                      <Input
                        value={employee.blood_name}
                        style={{ pointerEvents: "none" }}
                      ></Input>
                    </FormControl>
                  </div>

                  <div className="col-4">
                    <FormControl isRequired className="addEmployeeFormGroup">
                      <FormLabel htmlFor="last_organization">
                        Last Organization Name
                      </FormLabel>
                      <Input
                        value={employee.last_org_name}
                        id="last_organization"
                        type="text"
                        placeholder="Enter Last Organization Name"
                        name="last_org_name"
                        onChange={handleChange}
                      />
                    </FormControl>
                  </div>

                  <div className="col-4">
                    <FormControl isRequired className="addEmployeeFormGroup">
                      <FormLabel htmlFor="total_experience">
                        Total Experience
                      </FormLabel>
                      <Input
                        value={employee.past_exp}
                        id="total_experience"
                        type="text"
                        placeholder="Enter Total Experience"
                        name="past_exp"
                        onChange={handleChange}
                      />
                    </FormControl>
                  </div>

                  <div className="col-4">
                    <FormControl isRequired className="addEmployeeFormGroup">
                      <FormLabel htmlFor="date_of_joining">
                        Date of Joining
                      </FormLabel>
                      <Space direction="vertical" style={{ width: "100%" }}>
                        <input
                          id="date_of_joining"
                          style={{ width: "100%" }}
                          format="YYYY-MM-DD"
                          name="joining_date"
                          value={employee.joining_date || null}
                          onChange={(date, dateString) =>
                            handleDateChange(date, dateString, "joining_date")
                          }
                        />
                      </Space>
                    </FormControl>
                  </div>

                  <div className="col-4">
                    <FormControl isRequired className="addEmployeeFormGroup">
                      <FormLabel htmlFor="date_of_resign">
                        Date of Resign
                      </FormLabel>
                      <Space direction="vertical" style={{ width: "100%" }}>
                        <input
                          id="date_of_resign"
                          style={{ width: "100%" }}
                          format="YYYY-MM-DD"
                          name="resignation_date"
                          value={employee.resignation_date || null}
                          onChange={(date, dateString) =>
                            handleDateChange(
                              date,
                              dateString,
                              "resignation_date"
                            )
                          }
                        />
                      </Space>
                    </FormControl>
                  </div>

                  <div className="col-4">
                    <FormControl isRequired className="addEmployeeFormGroup">
                      <FormLabel htmlFor="date_of_birth">
                        Date of Birth
                      </FormLabel>
                      <Space direction="vertical" style={{ width: "100%" }}>
                        <input
                          id="date_of_birth"
                          style={{ width: "100%" }}
                          format="YYYY-MM-DD"
                          name="dob"
                          value={employee.dob || null}
                          onChange={(date, dateString) =>
                            handleDateChange(date, dateString, "dob")
                          }
                        />
                      </Space>
                    </FormControl>
                  </div>

                  <div className="col-4">
                    <FormControl isRequired className="addEmployeeFormGroup">
                      <FormLabel htmlFor="aadhar_card">
                        Aadhar Card Number
                      </FormLabel>
                      <Input
                        id="aadhar_card"
                        type="text"
                        value={employee.adhar_card}
                        placeholder="Enter Aadhar Card No."
                        name="adhar_card"
                        onChange={handleChange}
                      />
                    </FormControl>
                  </div>

                  <div className="col-2">
                    <FormControl isRequired className="addEmployeeFormGroup">
                      <FormLabel htmlFor="choose_aadhar">
                        Choose Adhar Card
                      </FormLabel>
                      <input
                        id="choose_aadhar"
                        type="file"
                        ref={fileInputRefAdharCard}
                        onChange={handleAttachFileAdharCard}
                        style={{ display: "none" }}
                      />

                      <IconButton
                        id="choose_aadhar"
                        icon={<AttachmentIcon />}
                        onClick={handleAttachClickAdharCard}
                        aria-label="Attach files"
                        mr={2}
                      />
                      <IconButton
                        icon={<ViewIcon />}
                        onClick={onOpenAdharCard}
                        aria-label="View attachments"
                        ml={2}
                      />

                      <Modal
                        isOpen={isOpenAdharCard}
                        onClose={onCloseAdharCard}
                      >
                        <ModalOverlay />
                        <ModalContent>
                          <ModalHeader>Uploaded Image</ModalHeader>
                          <ModalCloseButton />
                          <ModalBody>
                            <Image src={selectedImage} />
                            <List spacing={3}>
                              {aadharCard.map((file, index) => (
                                <div key={index}>
                                  <Text flex="1" isTruncated>
                                    {file.file_name}
                                  </Text>
                                  <IconButton
                                    onClick={() =>
                                      window.open(
                                        `https://divine.backenddd.in${file.file}`,
                                        "_blank"
                                      )
                                    }
                                  >
                                    <Link href={file.url} isExternal>
                                      <IconButton
                                        icon={<ExternalLinkIcon />}
                                        aria-label="Open file"
                                        mr={2}
                                      />
                                    </Link>
                                  </IconButton>
                                  {/* <IconButton
                                    onClick={() =>
                                      handleDeleteFileAdharCard(file.id)
                                    }
                                  >
                                    <DeleteIcon />
                                  </IconButton> */}
                                </div>
                              ))}
                            </List>
                          </ModalBody>

                          <ModalFooter>
                            <Button
                              colorScheme="blue"
                              mr={3}
                              onClick={onCloseProfileImage}
                            >
                              Close
                            </Button>
                          </ModalFooter>
                        </ModalContent>
                      </Modal>
                    </FormControl>
                  </div>

                  <div className="col-4">
                    <FormControl isRequired className="addEmployeeFormGroup">
                      <FormLabel htmlFor="pan_card">Pan Card Number</FormLabel>
                      <Input
                        id="pan_card"
                        type="text"
                        value={employee.pan_card}
                        placeholder="Enter Pan Card No."
                        name="pan_card"
                        onChange={handleChange}
                      />
                    </FormControl>
                  </div>

                  <div className="col-2">
                    <FormControl isRequired className="addEmployeeFormGroup">
                      <FormLabel htmlFor="choose_pan">
                        Choose Pan Card
                      </FormLabel>
                      <input
                        type="file"
                        ref={fileInputRefPanCard}
                        onChange={handleAttachFilePanCard}
                        multiple
                        style={{ display: "none" }}
                      />
                      <IconButton
                        id="choose_pan"
                        icon={<AttachmentIcon />}
                        onClick={handleAttachClickPanCard}
                        aria-label="Attach files"
                        mr={2}
                      />
                      <IconButton
                        icon={<ViewIcon />}
                        onClick={onOpenPanCard}
                        aria-label="View attachments"
                        ml={2}
                      />

                      <Modal isOpen={isOpenPanCard} onClose={onClosePanCard}>
                        <ModalOverlay />
                        <ModalContent>
                          <ModalHeader>Attached Files</ModalHeader>
                          <ModalCloseButton />
                          <ModalBody>
                            <List spacing={3}>
                              {panCard.map((file, index) => (
                                <div key={index}>
                                  <Text flex="1" isTruncated>
                                    {file.file_name}
                                  </Text>
                                  <IconButton
                                    onClick={() =>
                                      window.open(
                                        `https://divine.backenddd.in${file.file}`,
                                        "_blank"
                                      )
                                    }
                                  >
                                    <Link href={file.url} isExternal>
                                      <IconButton
                                        icon={<ExternalLinkIcon />}
                                        aria-label="Open file"
                                        mr={2}
                                      />
                                    </Link>
                                  </IconButton>
                                  {/* <IconButton
                                    onClick={() =>
                                      handleDeleteFilePanCard(file.id)
                                    }
                                  >
                                    <DeleteIcon />
                                  </IconButton> */}
                                </div>
                              ))}
                            </List>
                          </ModalBody>
                          <ModalFooter>
                            <Button
                              colorScheme="blue"
                              mr={3}
                              onClick={onClosePanCard}
                            >
                              Close
                            </Button>
                          </ModalFooter>
                        </ModalContent>
                      </Modal>
                    </FormControl>
                  </div>

                  {/* ******************************************************************** */}

                  <div className="col-12">
                    <div className="addEmployeeSectionsHeading mt-4">
                      <h1>Education Section</h1>
                      <hr />
                    </div>
                  </div>

                  <div className="col-2">
                    <FormControl isRequired className="addEmployeeFormGroup">
                      <FormLabel htmlFor="choose_tenth">
                        Choose 10th Document
                      </FormLabel>
                      {/* <Checkbox
                        id="choose_tenth"
                        isChecked={isTenthVisible}
                        onChange={(e) => setIsTenthVisible(e.target.checked)}
                      >
                        10th Standard
                      </Checkbox> */}

                      <input
                        type="file"
                        ref={fileInputRefTenth}
                        // onChange={handleAttachFileTenth}
                        onChange={(e) =>
                          handleFileChange(e, "dependant_schooling_10th")
                        }
                        multiple
                        style={{ display: "none" }}
                      />
                      <IconButton
                        icon={<AttachmentIcon />}
                        onClick={handleAttachClickTenth}
                        aria-label="Attach files"
                        mr={2}
                      />
                      <IconButton
                        icon={<ViewIcon />}
                        onClick={onOpenTenth}
                        aria-label="View attachments"
                        ml={2}
                      />

                      <Modal isOpen={isOpenTenth} onClose={onCloseTenth}>
                        <ModalOverlay />
                        <ModalContent>
                          <ModalHeader>Attached Files</ModalHeader>
                          <ModalCloseButton />
                          <ModalBody>
                            <List spacing={3}>
                              {attachedFiles10th.map((file, index) => (
                                <div key={index}>
                                  {/* <Typography>{file.name}</Typography> */}
                                  <Text flex="1" isTruncated>
                                    {file.name}
                                  </Text>
                                  <IconButton
                                    onClick={() =>
                                      window.open(
                                        `https://divine.backenddd.in${file.doc}/`,
                                        "_blank"
                                      )
                                    }
                                  >
                                    <Link href={file.url} isExternal>
                                      <IconButton
                                        icon={<ExternalLinkIcon />}
                                        aria-label="Open file"
                                        mr={2}
                                      />
                                    </Link>
                                  </IconButton>
                                  {/* <IconButton
                                    onClick={() =>
                                      handleDeleteFile(file.id, index)
                                    }
                                  >
                                    <DeleteIcon />
                                  </IconButton> */}
                                </div>
                              ))}
                            </List>
                          </ModalBody>
                          <ModalFooter>
                            <Button
                              colorScheme="blue"
                              mr={3}
                              onClick={onCloseTenth}
                            >
                              Close
                            </Button>
                          </ModalFooter>
                        </ModalContent>
                      </Modal>
                    </FormControl>
                  </div>

                  <div className="col-2">
                    <FormControl isRequired className="addEmployeeFormGroup">
                      <FormLabel htmlFor="choose_twelth">
                        Choose 12th Document
                      </FormLabel>
                      {/* <Checkbox
                        id="choose_twelth"
                        isChecked={isTwelthVisible}
                        onChange={(e) => setIsTwelthVisible(e.target.checked)}
                      >
                        12th Standard
                      </Checkbox> */}
                      <input
                        type="file"
                        ref={fileInputRefTwelth}
                        onChange={(e) =>
                          handleFileChange(e, "dependant_schooling_12th")
                        }
                        multiple
                        style={{ display: "none" }}
                      />
                      <IconButton
                        icon={<AttachmentIcon />}
                        onClick={handleAttachClickTwelth}
                        aria-label="Attach files"
                        mr={2}
                      />
                      <IconButton
                        icon={<ViewIcon />}
                        onClick={onOpenTwelth}
                        aria-label="View attachments"
                        ml={2}
                      />

                      <Modal isOpen={isOpenTwelth} onClose={onCloseTwelth}>
                        <ModalOverlay />
                        <ModalContent>
                          <ModalHeader>Attached Files</ModalHeader>
                          <ModalCloseButton />
                          <ModalBody>
                            <List spacing={3}>
                              {attachedFiles12th.map((file, index) => (
                                <div key={index}>
                                  {/* <Typography>{file.name}</Typography> */}
                                  <Text flex="1" isTruncated>
                                    {file.name}
                                  </Text>
                                  <IconButton
                                    onClick={() =>
                                      window.open(
                                        `https://divine.backenddd.in${file.doc}/`,
                                        "_blank"
                                      )
                                    }
                                  >
                                    <Link href={file.url} isExternal>
                                      <IconButton
                                        icon={<ExternalLinkIcon />}
                                        aria-label="Open file"
                                        mr={2}
                                      />
                                    </Link>
                                  </IconButton>
                                  {/* <IconButton
                                    onClick={() =>
                                      handleDeleteFile(file.id, index)
                                    }
                                  >
                                    <DeleteIcon />
                                  </IconButton> */}
                                </div>
                              ))}
                            </List>
                          </ModalBody>
                          <ModalFooter>
                            <Button
                              colorScheme="blue"
                              mr={3}
                              onClick={onCloseTwelth}
                            >
                              Close
                            </Button>
                          </ModalFooter>
                        </ModalContent>
                      </Modal>
                    </FormControl>
                  </div>
                  <div className="col-2">
                    <FormControl isRequired className="addEmployeeFormGroup">
                      <FormLabel htmlFor="choose_graduation">
                        Graduation
                      </FormLabel>
                      {/* <Checkbox
                        id="choose_graduation"
                        isChecked={isGraduationVisible}
                        onChange={(e) =>
                          setIsGraduationVisible(e.target.checked)
                        }
                      >
                        Graduation
                      </Checkbox> */}
                      {/* {isGraduationVisible && (
                        <> */}
                      <input
                        type="file"
                        ref={fileInputRefGraduation}
                        multiple
                        style={{ display: "none" }}
                        onChange={(e) =>
                          handleFileChange(e, "dependant_schooling_graduation")
                        }
                      />
                      <IconButton
                        icon={<AttachmentIcon />}
                        onClick={handleAttachClickGraduation}
                        aria-label="Attach files"
                        mr={2}
                      />
                      <IconButton
                        icon={<ViewIcon />}
                        onClick={onOpenGraduation}
                        aria-label="View attachments"
                        ml={2}
                      />

                      <Modal
                        isOpen={isOpenGraduation}
                        onClose={onCloseGraduation}
                      >
                        <ModalOverlay />
                        <ModalContent>
                          <ModalHeader>Attached Files</ModalHeader>
                          <ModalCloseButton />
                          <ModalBody>
                            <List spacing={3}>
                              {attachedFilesGraduation.map((file, index) => (
                                <div key={index}>
                                  {/* <Typography>{file.name}</Typography> */}
                                  <Text flex="1" isTruncated>
                                    {file.name}
                                  </Text>
                                  <IconButton
                                    onClick={() =>
                                      window.open(
                                        `https://divine.backenddd.in${file.doc}/`,
                                        "_blank"
                                      )
                                    }
                                  >
                                    <Link href={file.url} isExternal>
                                      <IconButton
                                        icon={<ExternalLinkIcon />}
                                        aria-label="Open file"
                                        mr={2}
                                      />
                                    </Link>
                                  </IconButton>
                                  {/* <IconButton
                                    onClick={() =>
                                      handleDeleteFile(file.id, index)
                                    }
                                  >
                                    <DeleteIcon />
                                  </IconButton> */}
                                </div>
                              ))}
                            </List>
                          </ModalBody>
                          <ModalFooter>
                            <Button
                              colorScheme="blue"
                              mr={3}
                              onClick={onCloseGraduation}
                            >
                              Close
                            </Button>
                          </ModalFooter>
                        </ModalContent>
                      </Modal>
                      {/* </>
                      )} */}
                    </FormControl>
                  </div>

                  <div className="col-2">
                    <FormControl isRequired className="addEmployeeFormGroup">
                      <FormLabel htmlFor="choose_post_graduation">
                        Post Graduation
                      </FormLabel>
                      {/* <Checkbox
                        id="choose_post_graduation"
                        isChecked={isMasterVisible}
                        onChange={(e) => setIsMasterVisible(e.target.checked)}
                      >
                        Post Graduation
                      </Checkbox> */}
                      {/* {isMasterVisible && (
                        <> */}
                      <input
                        type="file"
                        ref={fileInputRefMaster}
                        // onChange={handleAttachFileMaster}
                        onChange={(e) =>
                          handleFileChange(
                            e,
                            "dependant_schooling_post_graduation"
                          )
                        }
                        multiple
                        style={{ display: "none" }}
                      />
                      <IconButton
                        icon={<AttachmentIcon />}
                        onClick={handleAttachClickMaster}
                        aria-label="Attach files"
                        mr={2}
                      />
                      <IconButton
                        icon={<ViewIcon />}
                        onClick={onOpenMaster}
                        aria-label="View attachments"
                        ml={2}
                      />

                      <Modal isOpen={isOpenMaster} onClose={onCloseMaster}>
                        <ModalOverlay />
                        <ModalContent>
                          <ModalHeader>Attached Files</ModalHeader>
                          <ModalCloseButton />
                          <ModalBody>
                            <List spacing={3}>
                              {attachedFilesPostGraduation.map(
                                (file, index) => (
                                  <div key={index}>
                                    {/* <Typography>{file.name}</Typography> */}
                                    <Text flex="1" isTruncated>
                                      {file.name}
                                    </Text>
                                    <IconButton
                                      onClick={() =>
                                        window.open(
                                          `https://divine.backenddd.in${file.doc}/`,
                                          "_blank"
                                        )
                                      }
                                    >
                                      <Link href={file.url} isExternal>
                                        <IconButton
                                          icon={<ExternalLinkIcon />}
                                          aria-label="Open file"
                                          mr={2}
                                        />
                                      </Link>
                                    </IconButton>
                                    {/* <IconButton
                                      onClick={() =>
                                        handleDeleteFile(file.id, index)
                                      }
                                    >
                                      <DeleteIcon />
                                    </IconButton> */}
                                  </div>
                                )
                              )}
                            </List>
                          </ModalBody>
                          <ModalFooter>
                            <Button
                              colorScheme="blue"
                              mr={3}
                              onClick={onCloseMaster}
                            >
                              Close
                            </Button>
                          </ModalFooter>
                        </ModalContent>
                      </Modal>
                      {/* </>
                      )} */}
                    </FormControl>
                  </div>
                  <div className="col-4">
                    <FormControl isRequired className="addEmployeeFormGroup">
                      <FormLabel htmlFor="other_qualification">
                        Other Qualification
                      </FormLabel>
                      <Input
                        id="other_qualification"
                        type="text"
                        value={employee.dependant_qualification}
                        placeholder="Enter Other Qualification"
                        name="dependant_qualification"
                        onChange={handleChange}
                      />
                    </FormControl>
                  </div>

                  <div className="col-12">
                    <div className="wrapAddEmployeeSections">
                      <div className="addEmployeeSectionsHeading">
                        <h1>Dependent Family Member</h1>
                        <hr />
                      </div>
                      <AddDependentMember username={username} />
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="wrapAddEmployeeSections">
                      <div className="addEmployeeSectionsHeading">
                        <h1>Disciplinary Records</h1>
                        <hr />
                      </div>
                      <AddDisciplinaryRecords username={username} />
                    </div>
                  </div>

                  <div className="addEmployeeSectionsHeading mt-2">
                    <h1>Office Section</h1>
                    <hr />
                  </div>

                  <div className="col-4">
                    <FormControl isRequired className="addEmployeeFormGroup">
                      <FormLabel htmlFor="pf_number">PF No.</FormLabel>
                      <Input
                        id="pf_number"
                        type="text"
                        placeholder="Enter PF No."
                        name="pf_no"
                        value={employee.pf_no}
                        onChange={handleChange}
                      />
                    </FormControl>
                  </div>
                  <div className="col-4">
                    <FormControl isRequired className="addEmployeeFormGroup">
                      <FormLabel htmlFor="pf_nominee">PF Nominee</FormLabel>
                      <Input
                        id="pf_nominee"
                        type="text"
                        value={employee.pf_nominee}
                        placeholder="Enter PF Nominee"
                        name="pf_nominee"
                        onChange={handleChange}
                      />
                    </FormControl>
                  </div>
                  <div className="col-4">
                    <FormControl isRequired className="addEmployeeFormGroup">
                      <FormLabel htmlFor="esi_number">ESI No.</FormLabel>
                      <Input
                        id="esi_number"
                        value={employee.esi_no}
                        type="text"
                        placeholder="Enter ESI No."
                        name="esi_no"
                        onChange={handleChange}
                      />
                    </FormControl>
                  </div>

                  <div className="col-4">
                    <FormControl isRequired className="addEmployeeFormGroup">
                      <FormLabel htmlFor="bank_name">Bank Name</FormLabel>
                      <Input
                        id="bank_name"
                        value={employee.bank_name}
                        type="text"
                        placeholder="Enter Bank Name"
                        name="bank_name"
                        onChange={handleChange}
                      />
                    </FormControl>
                  </div>
                  <div className="col-4">
                    <FormControl isRequired className="addEmployeeFormGroup">
                      <FormLabel htmlFor="bank_ac">Bank A/c No.</FormLabel>
                      <Input
                        id="bank_ac"
                        type="text"
                        value={employee.bank_acc_no}
                        placeholder="Enter Bank A/c No."
                        name="bank_acc_no"
                        onChange={handleChange}
                      />
                    </FormControl>
                  </div>
                  <div className="col-4">
                    <FormControl isRequired className="addEmployeeFormGroup">
                      <FormLabel htmlFor="ifsc_code">IFSC Code.</FormLabel>
                      <Input
                        id="ifsc_code"
                        value={employee.ifsc_code}
                        type="text"
                        placeholder="Enter IFSC Code."
                        name="ifsc_code"
                        onChange={handleChange}
                      />
                    </FormControl>
                  </div>

                  <div className="col-4">
                    <FormControl isRequired className="addEmployeeFormGroup">
                      <FormLabel htmlFor="current_salary">
                        Current Salary
                      </FormLabel>
                      <Input
                        id="current_salary"
                        type="text"
                        value={employee.current_salary}
                        placeholder="Enter Current Salary"
                        name="current_salary"
                        onChange={handleChange}
                      />
                    </FormControl>
                  </div>

                  <div className="col-12 col-md-4 col-lg-4">
                    <FormControl className="addEmployeeFormGroup">
                      <FormLabel htmlFor="current_salary">
                        Hostel Deduction
                      </FormLabel>
                      <Input
                        id="hostel_deduction"
                        value={employee.hostel_deduction}
                        type="text"
                        placeholder="Enter Hostel Deduction"
                        name="hostel_deduction"
                        onChange={handleChange}
                      />
                    </FormControl>
                  </div>

                  <div className="addEmployeeSectionsHeading mt-2">
                    <h1>Leave Section</h1>
                    <hr />
                  </div>

                  <div className="col-4">
                    <FormControl className="addEmployeeFormGroup">
                      <FormLabel htmlFor="pf_number">CL (Casual Leave)</FormLabel>
                      <Input
                        id="cl"
                        type="text"
                        placeholder="Enter CL"
                        name="cl"
                        value={employee.cl ? employee.cl : leaveData.cl}
                        onChange={handleLeaveChange}
                      />
                    </FormControl>
                  </div>
                  <div className="col-4">
                    <FormControl className="addEmployeeFormGroup">
                      <FormLabel htmlFor="pf_number">EL</FormLabel>
                      <Input
                        id="el"
                        type="text"
                        placeholder="Enter EL"
                        name="el"
                        value={employee.el ? employee.el : leaveData.el}
                        onChange={handleLeaveChange}
                      />
                    </FormControl>
                  </div>
                  <div className="col-4">
                    <FormControl className="addEmployeeFormGroup">
                      <FormLabel htmlFor="pf_number">NH (National Holiday)</FormLabel>
                      <Input
                        id="nh"
                        type="text"
                        placeholder="Enter NH"
                        name="nh"
                        value={employee.nh ? employee.nh : leaveData.nh}
                        onChange={handleLeaveChange}
                      />
                    </FormControl>
                  </div>
                  
                  <div className="col-4">
                    <FormControl className="addEmployeeFormGroup">
                      <FormLabel htmlFor="pf_number">SL (Sick Leave)</FormLabel>
                      <Input
                        id="sl"
                        type="text"
                        placeholder="Enter SL"
                        name="sl"
                        value={leaveData.sl}
                        // onChange={handleLeaveChange}
                      />
                    </FormControl>
                  </div>

                  <div className="col-12">
                    <div className="wrapAddEmployeeSections">
                      <div className="addEmployeeSectionsHeading">
                        <h1>Salary Section</h1>
                        <hr />
                      </div>
                      <AddSalary username={username} />
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="wrapAddEmployeeSections">
                      <div className="addEmployeeSectionsHeading">
                        <h1>Promotion & Change of Responsibility</h1>
                        <hr />
                      </div>
                      <AddPromotion username={username} />
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="wrapAddEmployeeSections">
                      <div className="addEmployeeSectionsHeading">
                        <h1>Performance Appraisal Information</h1>
                        <hr />
                      </div>
                      <AddPerformanceOne username={username} />
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="wrapAddEmployeeSections">
                      <div className="addEmployeeSectionsHeading">
                        <h1>Training Section</h1>
                        <hr />
                      </div>
                      <AddTrainingOne username={username} />
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="wrapAddEmployeeSections">
                      <div className="addEmployeeSectionsHeading">
                        <h1>Create Custom Field</h1>
                        <hr />
                      </div>

                      {<AddAdditionalField username={username} />}
                    </div>
                  </div>

                  {/* print button */}

                  {/*                     
                    <div className="col-12">
                    <Button
                      style={{
                        backgroundColor: "#3182CE",
                        color: "white",
                        marginRight: "20px",
                      }}
                    >
                      <ExternalLinkIcon />{" "}
                      <span style={{ paddingLeft: "5px" }}>Print</span>
                    </Button>
                   
                  </div> */}

                  <div className="col-12">
                    <ReactToPrint
                      trigger={() => (
                        <Button
                          style={{
                            backgroundColor: "#3182CE",
                            color: "white",
                            marginRight: "20px",
                          }}
                        >
                          <ExternalLinkIcon />{" "}
                          <span style={{ paddingLeft: "5px" }}>Print</span>
                        </Button>
                      )}
                      content={() => printRef.current}
                    />
                  </div>
                  {/* // print button */}
                </div>
              </form>
            </div>

            <div className="addEmployeeDocWrapper no-print" >
              <div className="row">
                <div className="col-12">
                  <div className="addEmployeeHeading my-4">
                    <h1>Upload Documents</h1>
                    <p>
                      Click on <span>SAVE</span> button at the bottom everytime
                      any progress is made of form
                    </p>
                  </div>
                </div>
                <div className="col-12">
                  <div className="addEmployeeDocSection">
                    <AddUploadDocument />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ChakraProvider>
    </>
  );
};

export default ViewEmployees;
