import React, { useState } from "react";
import axios from "axios";
import {
  Box,
  Button,
  ChakraProvider,
  FormControl,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useToast,
  useDisclosure,
} from "@chakra-ui/react";

import { AddIcon } from "@chakra-ui/icons";
const AddDept = ({ onDataChange }) => {
  const [name, setName] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://divine.backenddd.in/HumanResource/api/Createdepartments/",
        { name }
      );
      toast({
        title: "Department created successfully!",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      onDataChange(response.data); // Notify parent component of the new department
      setName("");
      onClose();
    } catch (error) {
      console.error("Error creating department:", error);
      toast({
        title: "An error occurred while creating the department. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <ChakraProvider>
      <Box>
        <Button  className="addButtonEmp"
        leftIcon={<AddIcon />}
        colorScheme="teal"
        variant="solid" 
        onClick={onOpen} >
          Add
        </Button>

        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Add Department</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <form onSubmit={handleSubmit}>
                <FormControl id="departmentName" isRequired>
                  <Input
                    type="text"
                    value={name}
                    placeholder="Enter department name"
                    onChange={(e) => setName(e.target.value)}
                  />
                </FormControl>
              </form>
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="blue" mr={3} onClick={handleSubmit}>
                Save
              </Button>
              <Button variant="ghost" onClick={onClose}>
                Cancel
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>
    </ChakraProvider>
  );
};

export default AddDept;
