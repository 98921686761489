import React, { useState, useEffect } from "react";
import NavigationMenu from "../Navigation/NavigationMenu";
import axios from "axios";
import Select from 'react-select';
import { Link } from 'react-router-dom';

import {
  Input,
  ChakraProvider,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  // Select,
  Textarea,
  Table,
  Thead,
  Icon,
  Tbody,
  Tr,
  Th,
  Td,
  FormLabel,
  Flex,
  Image,
  FormControl,
  Box,
} from "@chakra-ui/react";

import { Select as ChakraSelect } from '@chakra-ui/react';

import { DatePicker } from "antd";
import "./AddLeaveRequest.css";

import { ChevronDownIcon, ChevronUpIcon, AddIcon } from "@chakra-ui/icons";

// import { SearchIcon, ViewIcon, EditIcon, DeleteIcon } from "@chakra-ui/icons";

const LeaveSummaryPage = () => {
  const handleSearch = () => {
    // Define what happens when you search
    console.log("Searching...");
  };

  const [isOpen, setIsOpen] = useState(false);
  const [leaveCategory, setLeaveCategory] = useState("");
  const [leaveReason, setLeaveReason] = useState("");
  const [staffID, setStaffID] = useState("");
  const [filterStatus, setFilterStatus] = useState("");
  const [filterStatusname, setFilterStatusName] = useState("");

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [leaveState, setLeaveState] = useState("");
  const [editIndex, setEditIndex] = useState(null);
  const [leaveRecords, setLeaveRecords] = useState([]);
  const [employee, setEmployee] = useState([]);
  const [staff, setStaff] = useState([]);
  const [searchDate, setSearchDate] = useState([]);

  const [leaveRequest, setLeaveRequests] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState(null);

  const [leaveSummary, setLeaveSummary] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [leaveCounts, setLeaveCounts] = useState({
    yesterday: 0,
    today: 0,
    tomorrow: 0,
    absent: 0,

  });

  useEffect(() => {
    const accessToken = localStorage.getItem("access_token");
    const fetchLeaveCounts = async () => {
      try {
        const response = await axios.get(
          "https://divine.backenddd.in/Leave/leave-counts/",
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        setLeaveCounts(response.data);
      } catch (error) {
        console.error("Error fetching leave counts:", error);
      }
    };

    fetchLeaveCounts();
  }, []);

  const leaveCategories = [
    "Annual Leave",
    "Sick Leave",
    "Maternity Leave",
    "Monthly Off",
    "Casual Leave",
    "Privilege Leave",
    "Absent",
  ];
  const leaveStates = ["Pending", "Approved", "Rejected"];

  const handleAddLeaveRecord = async () => {
    const newLeaveRecord = {
      staffID,
      leaveCategory,
      leaveReason,
      fromDate,
      toDate,
      leaveState,
    };

    console.log("Staff ID: ", staffID);
    console.log("Leave Category: ", leaveCategory);
    console.log("Leave Reason: ", leaveReason);
    console.log("Leave Reason: ", fromDate);
    console.log("Leave Reason: ", toDate);
    console.log("Leave Reason: ", leaveState);

    const formData = {
      staff_id: selectedStaff,
      start_date: fromDate,
      end_date: toDate,
      leave_type: leaveCategory,
      reason: leaveReason,
      status: leaveState,
    };

    const accessToken = localStorage.getItem("access_token");

    try {
      const response = await axios.post(
        "https://divine.backenddd.in/Leave/api/save-leave-request/",
        formData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.status === 200) {
        console.log("Leave request submitted:", response.data);
        alert("Saved");
        fetchLeaveRequests();
      }
    } catch (error) {
      console.error("Error:", error);
    }

    if (editIndex !== null) {
      const updatedLeaveRecords = [...leaveRecords];
      updatedLeaveRecords[editIndex] = newLeaveRecord;
      setLeaveRecords(updatedLeaveRecords);
      setEditIndex(null);
    } else {
      setLeaveRecords([...leaveRecords, newLeaveRecord]);
    }

    // Clear input fields after adding or editing leave record
    setLeaveCategory("");
    setLeaveReason("");
    setFromDate("");
    setToDate("");
    setLeaveState("");
    setIsOpen(false);
  };


  const token = localStorage.getItem("access_token");

  const fetchLeaveRequests = async () => {
    const accessToken = localStorage.getItem("access_token");

    try {
      const response = await axios.get(
        "https://divine.backenddd.in/Leave/api/leave-summary-api-view/",
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      // setLeaveRequests(response.data);
      setLeaveSummary(response.data);
      // countLeaveRequests();
    } catch (error) {
      console.error("Error fetching leave requests:", error);
    }
  };

  useEffect(() => {
    fetchLeaveRequests();
    fetchData();
  }, []);

  const [expandedRowSearchEmployee, setExpandedRowSearchEmployee] = useState(null);

  const toggleExpandSearchEmployee = (id) => {
    setExpandedRowSearchEmployee(expandedRowSearchEmployee === id ? null : id);
  };

  const fetchData = async () => {
    try {
      if (token) {
        const response = await axios.get(
          "https://divine.backenddd.in/HumanResource/StaffList/",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setStaff(response.data);
      } else {
        console.error("Access token not found");
      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };

  const fetchfilteredLeaveRequests = async () => {
    const accessToken = localStorage.getItem("access_token");
    console.log("Date Search: ", searchDate);
    try {
      const response = await axios.get(
        `https://divine.backenddd.in/Leave/api/filtered_leave_summary_api_by_date_view/`,
        {
          params: {
            search_date: searchDate,
            status: filterStatus,
            filter_status_name: filterStatusname
          },
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setLeaveRequests(response.data);
    } catch (error) {
      console.error("Error fetching leave requests:", error);
    }
  };

  const handleDateFilter = async (date) => {
    const token = localStorage.getItem("access_token");

    try {
      const response = await axios.get(
        `https://divine.backenddd.in/Leave/api/Filtered_leave_requests_by_date_view/${date}/`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("Leave Requests: ", response.data);
      setLeaveRequests(response.data);
      // Handle the response data as needed, e.g., set it to state
    } catch (error) {
      console.error("Error fetching leave requests:", error);
    }
  };


  const staffOptions = staff.map((category) => ({
    value: category.staff_id,
    label: `${category.fname} ${category.lname} - ${category.staff_id}`,
  }));

  const handleStaffChange = (selectedOption) => {
    // Assuming you are making an API call here or setting the state to be sent to backend
    const staffId = selectedOption ? selectedOption.value : null;
    console.log('Selected Staff ID:', staffId);
    setSelectedStaff(staffId);
    // Send staffId to the backend
  };

  const selectedStaffOption = staffOptions.find(
    (option) => option.value === selectedStaff
  );

  const staffSearchOptions = staff.map((category) => ({
    value: category.staff_id,
    label: `${category.fname} ${category.lname} - ${category.staff_id}`,
  }));

  const handleSearchStaffChange = (selectedOption) => {
    const staffId = selectedOption ? selectedOption.value : null;
    console.log('Selected Staff ID:', staffId);
    setFilterStatusName(staffId);
    // Send staffId to the backend
  };

  const selectedSearchStaffOption = staffSearchOptions.find(
    (option) => option.value === filterStatusname
  );


  return (
    <>
      <NavigationMenu />

      <ChakraProvider>
        <div className="leaveRequestWrapper">
          <div className="container-fluid">
            <div className="leaveRequestSearchWrapper">
              <div className="row">
                <div className="col-12 col-md-12 col-lg-12">
                  <Button
                    colorScheme="blue"
                    leftIcon={<AddIcon />}
                    backgroundColor={"#338687"}
                    onClick={() => setIsOpen(true)}
                  >
                    Add Leave
                  </Button>

                  <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
                    <ModalOverlay />
                    <ModalContent>
                      <ModalHeader>Add Leave Request</ModalHeader>
                      <ModalCloseButton />
                      <ModalBody>
                        <label htmlFor="leaveCategory">Employee ID</label>
                        <Select
                          id="staff_id"
                          placeholder="Select Staff"
                          value={selectedStaffOption}
                          name="staff_id"
                          onChange={handleStaffChange}
                          options={staffOptions}
                          isSearchable
                        />
                        <label htmlFor="leaveCategory">Leave Category</label>
                        <ChakraSelect
                          id="leaveCategory"
                          placeholder="Select Leave Category"
                          value={leaveCategory}
                          onChange={(e) => setLeaveCategory(e.target.value)}
                        >
                          {leaveCategories.map((category, index) => (
                            <option key={index} value={category}>
                              {category}
                            </option>
                          ))}
                        </ChakraSelect>
                        <label htmlFor="leaveReason">Leave Reason</label>
                        <Textarea
                          id="leaveReason"
                          placeholder="Leave Reason"
                          value={leaveReason}
                          onChange={(e) => setLeaveReason(e.target.value)}
                        />
                        <label htmlFor="fromDate">From Date</label>
                        <Input
                          id="fromDate"
                          type="date"
                          placeholder="From Date"
                          value={fromDate}
                          onChange={(e) => setFromDate(e.target.value)}
                        />
                        <label htmlFor="toDate">To Date</label>
                        <Input
                          id="toDate"
                          type="date"
                          placeholder="To Date"
                          value={toDate}
                          onChange={(e) => setToDate(e.target.value)}
                        />
                        <label htmlFor="leaveState">Leave State</label>
                        <ChakraSelect
                          id="leaveState"
                          placeholder="Select Leave State"
                          value={leaveState}
                          onChange={(e) => setLeaveState(e.target.value)}
                        >
                          {leaveStates.map((state, index) => (
                            <option key={index} value={state}>
                              {state}
                            </option>
                          ))}
                        </ChakraSelect>
                      </ModalBody>
                      <ModalFooter>
                        <Button
                          colorScheme="blue"
                          mr={3}
                          onClick={() => setIsOpen(false)}
                        >
                          Close
                        </Button>
                        <Button
                          colorScheme="green"
                          onClick={handleAddLeaveRecord}
                        >
                          Save
                        </Button>
                      </ModalFooter>
                    </ModalContent>
                  </Modal>
                </div>
              </div>
            </div>

            {/* ***********************************  Search Leave Status *********************** */}

            <div className="row">
              <div className="col-12 col-md-4 col-lg-4">
                <div className="leaveRequestHeading">
                  <h1>View Leave Request</h1>
                  <p>
                    Explore employee detail using on{" "}
                    <span>Emp ID, Name, DOJ</span> button at the bottom
                    everytime.
                  </p>
                </div>
              </div>


              <div className="col-12 col-md-2 col-lg-2">
                <Button
                  colorScheme="blue"
                  my={"2"}
                  width={"100%"}
                  onClick={() => handleDateFilter("Yesterday")}
                >
                  Yesterday - {leaveCounts.yesterday}
                </Button>
                <br />
                <Button
                  colorScheme="blue"
                  my={"2"}
                  width={"100%"}
                  onClick={() => handleDateFilter("Today")}
                >
                  Today - {leaveCounts.today}
                </Button>
                <br />
                <Button
                  colorScheme="blue"
                  my={"2"}
                  width={"100%"}
                  onClick={() => handleDateFilter("Tomorrow")}
                >
                  Tomorrow - {leaveCounts.tomorrow}
                </Button>

                <Button
                  colorScheme="blue"
                  my={"2"}
                  width={"100%"}
                  onClick={() => handleDateFilter("Absent")}
                >
                  Absent - {leaveCounts.absent}
                </Button>
              </div>

              {/* ***********************************  Show Leave Status *********************** */}
              <div className="col-12 col-md-12 col-lg-12">
                <div className="viewLeaveRequestTable">
                  <Box overflowX={"auto"}>
                    <Table variant="simple" className="leaveRequestTableFormat">
                      <Thead>
                        <Tr>
                          <Th width={"20%"}>Name</Th>
                          <Th width={"60%"}>Leaves Taken</Th>
                          <Th width={"20%"}>View</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {leaveSummary.map((employee) => (
                          <React.Fragment key={employee.id}>
                            <Tr
                              onClick={() =>
                                toggleExpandSearchEmployee(employee.id)
                              }
                              style={{ cursor: "pointer" }}
                            >
                              <Td>{employee.user}</Td>
                              <Td>{employee.total_leaves}</Td>
                              <td>
                                <Link
                                  to='/leavesummary'
                                  state={{ user_id: employee.user_id }}
                                >
                                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye-fill" viewBox="0 0 16 16">
                                    <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0" />
                                    <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8m8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7" />
                                  </svg>
                                </Link>
                              </td>

                            </Tr>
                            {expandedRowSearchEmployee === employee.id && (
                              <Tr>
                                <Td colSpan={7}>
                                  <h3 className="viewTableLeaveReason">
                                    Leave Reason{" "}
                                  </h3>
                                  <p>{employee.reason}</p>
                                </Td>
                              </Tr>
                            )}
                          </React.Fragment>
                        ))}
                      </Tbody>
                    </Table>
                  </Box>
                </div>
              </div>

            </div>
          </div>
        </div>
      </ChakraProvider>
    </>
  );
};

export default LeaveSummaryPage;
