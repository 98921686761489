import React, { useEffect, useState } from "react";
import { Box, Text, Button, Image, ChakraProvider } from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import NavigationMenu from "../Navigation/NavigationMenu";
import "./AddFormat.css";

const PrintPreview = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { certificateId, employeeId } = location.state || {};

  const [currentDate, setCurrentDate] = useState("");
  const [certificateData, setCertificateData] = useState(null);

  useEffect(() => {
    const today = new Date();
    const formattedDate = today.toLocaleDateString("en-GB");
    setCurrentDate(formattedDate);
  }, []);

  useEffect(() => {
    if (certificateId && employeeId) {
      axios
        .get(`https://divine.backenddd.in/Letter/api/certificates/view/${certificateId}/`, {
          params: { employee_id: employeeId },
        })
        .then((response) => {
          setCertificateData(response.data);
          console.log("Data: ", response.data);
        })
        .catch((error) => {
          console.error("Error fetching certificate data:", error);
        });
    }
  }, [certificateId, employeeId]);

  const handlePrint = () => {
    window.print();
  };

  const handleCancel = () => {
    navigate(-1); // Go back to the previous page
  };

  if (!certificateData) {
    return <div>Loading...</div>;
  }

  // Ensure certificateData.content is a string before calling replace
  let certificateContent = certificateData.certgen_ser.content;
  if (typeof certificateContent !== "string") {
    certificateContent = JSON.stringify(certificateContent);
  }

  certificateContent = certificateContent
    .replace(/{{fname}}/g, certificateData.staff.fname)
    .replace(/{{lname}}/g, certificateData.staff.lname)
    .replace(/{{position}}/g, certificateData.staff.pos_name)
    .replace(/{{department}}/g, certificateData.staff.dep_name)
    .replace(/{{father_name}}/g, certificateData.staff.father_name)
    .replace(/{{working_dept}}/g, certificateData.staff.wor_dep_name)
    .replace(/{{bank_acc_no}}/g, certificateData.staff.bank_acc_no)
    .replace(/{{ifsc_code}}/g, certificateData.staff.ifsc_code)
    .replace(/{{joining_date}}/g, certificateData.staff.joining_date)
    .replace(/{{adhar_card}}/g, certificateData.staff.adhar_card)
    .replace(/{{pan_card}}/g, certificateData.staff.pan_card)
    .replace(/{{blood_group}}/g, certificateData.staff.blood_name)

  return (
    <ChakraProvider>
      <Box id="printableContent" padding="20px">
        <Box marginBottom="20px">
          <Text fontSize="2xl" fontWeight="bold" textAlign="center">
            {certificateData.name}
          </Text>
        </Box>

        {/* <Box marginBottom="20px">
          <Text fontSize="md" textAlign="right">
            Date: {currentDate}
          </Text>
        </Box> */}

        {/* <Box marginBottom="20px">
          <Text fontSize="md">To </Text>
          <Text fontSize="md">
            {certificateData.staff.fname} {certificateData.staff.lname}
          </Text>
          <Text fontSize="md">{certificateData.staff.pos_name}</Text>
          <Text fontSize="md">{certificateData.staff.dep_name}</Text>
        </Box> */}

        <Box marginBottom="20px">
          <Text
            fontSize="md"
            textAlign="justify"
            dangerouslySetInnerHTML={{ __html: certificateContent }}
          />
        </Box>

        <Box marginTop="30px">
          {/* <Text fontSize="md">Sincerely,</Text> */}
          <Text fontSize="md" marginTop="10px">
            {certificateData.sig_name}
          </Text>
          <Text fontSize="md" marginTop="10px">
            {certificateData.sig_position}
          </Text>
          {/* <Image
            src={`https://divine.backenddd.in${certificateData.sig_img}/`}

          <Image
            src={`https://divine.backenddd.in${certificateData.sig_img}/`}
            alt="Signature"
            width="150px"
            height="auto"
          /> */}
        </Box>

        <Box marginTop="20px">
          <Button onClick={handlePrint} marginRight="10px">
            Print
          </Button>
          <Button onClick={handleCancel}>Cancel</Button>
        </Box>
      </Box>
    </ChakraProvider>
  );
};

export default PrintPreview;
