import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";

import axios from "axios";
import {
  Box,
  Button,
  ChakraProvider,
  Checkbox,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  extendTheme,
  Heading,
} from "@chakra-ui/react";
import "./Login.css";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";

import bannerImage from "../../assets/loginbanner.jpg";
import logoImg from "../../assets/divine-logo.png";

const Login = () => {
  const navigate = useNavigate();
  const [LoginError, setLoginError] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [rememberMe, setRememberMe] = useState(false);

  const handlePasswordVisibility = () => setShowPassword(!showPassword);

  const handleSubmit = (event) => {
    event.preventDefault();
    // Here you would typically handle the login logic, e.g., calling an API
  };

  useEffect(() => {
    // Check if user is already logged in
    const accessToken = localStorage.getItem("access_token");
    if (accessToken) {
      // User is already logged in, redirect to the desired page
      navigate("/dashboard");
    }
  }, []);

  const submit = async (e) => {
    e.preventDefault();
    const user = {
      username: username,
      password: password,
    };

    try {
      const { data } = await axios.post(
        "https://divine.backenddd.in/token/",
        user,
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );

      localStorage.clear();
      localStorage.setItem("access_token", data.access);
      localStorage.setItem("refresh_token", data.refresh);
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${data["access"]}`;
      const token = localStorage.getItem("access_token");

      // Check user permissions

      const response = await axios.get(
        "https://divine.backenddd.in/HumanResource/user-status/",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const userId = response.data.userId;

      if (
        response.data.is_superuser ||
        response.data.permissions.includes("auth.custom_hr_perm")
      ) {
        // User is superuser or has custom HR permission, redirect to dashboard
        navigate("/dashboard");
      } else {
        navigate("/material/peruserdetail");
      }
    } catch (error) {
      if (error.response) {
        const errorMessage = error.response.data.detail;
        if (errorMessage === "Invalid credentials") {
          setPasswordError("Invalid username or password");
        } else {
          setLoginError("Invalid Credentials");
        }
      } else {
        setLoginError("Invalid Credentials");
      }
    }
  };

  // Customize the theme to center the login box and apply global styles
  const theme = extendTheme({
    styles: {
      global: {
        "html, body": {
          height: "100%",
          margin: 0,
          padding: 0,
          backgroundImage: `url(${bannerImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          fontFamily: `"Nunito", sans-serif`,
        },
        ".container": {
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        },
      },
    },
  });

  return (
    <ChakraProvider theme={theme}>
      <div className="container">
        <div className="loginLogo">
          <img src={logoImg} alt="" />
        </div>

        <Box
          backgroundColor="white"
          borderRadius="md"
          boxShadow="md"
          className="loginBox"
        >
          <Heading
            className="loginBoxHeading"
            width="100%"
            backgroundColor="#81a2f0"
            color="white"
            textAlign="center"
          >
            Divine Medical Mission Hospital
          </Heading>
          <form onSubmit={submit} className="loginForm">
            <FormControl isRequired>
              <FormLabel className="loginFormLabel">Username</FormLabel>
              <Input
                type="text"
                placeholder="Enter your username"
                value={username}
                className="loginFormInput"
                onChange={(e) => setUsername(e.target.value)}
              />
            </FormControl>

            <FormControl mt={4} isRequired>
              <FormLabel className="loginFormLabel">Password</FormLabel>
              <InputGroup>
                <Input
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter your password"
                  value={password}
                  className="loginFormInput"
                  onChange={(e) => setPassword(e.target.value)}
                />
                <InputRightElement width="4.5rem" className="LoginFormEyeIcon">
                  <Button
                    h="1.75rem"
                    size="sm"
                    className="LoginFormEyeIconBtn"
                    onClick={handlePasswordVisibility}
                  >
                    {showPassword ? <ViewOffIcon /> : <ViewIcon />}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>

            <FormControl>
              <Checkbox
                mt={4}
                mb={4}
                className="loginFormCheckbox"
                isChecked={rememberMe}
                onChange={(e) => setRememberMe(e.target.checked)}
              >
                Remember Me
              </Checkbox>
            </FormControl>
            {LoginError && (
              <>
                <p style={{ color: "red" }}>{LoginError}</p>
              </>
            )}
            <Button
              mt={4}
              colorScheme="blue"
              className="FormSubmitBtn"
              type="submit"
            >
              Submit
            </Button>
          </form>
        </Box>
      </div>
    </ChakraProvider>
  );
};

export default Login;
