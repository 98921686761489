import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import NavigationMenu from "../Navigation/NavigationMenu";
import {
  Input,
  ChakraProvider,
  Button,
  Table,
  Thead,
  Icon,
  Tbody,
  Tr,
  Th,
  Td,
  FormLabel,
  Flex,
  Image,
  FormControl,
  Box,
  Heading,
  Text,
  Divider,
  VStack,
  Center,
  HStack
} from "@chakra-ui/react";
import { Select as ChakraSelect } from "@chakra-ui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import "./AddLeaveRequest.css";

const LeaveSummary = () => {
  const location = useLocation();
  const [leaveCount, setLeaveCount] = useState(0);
  const [yearlyleaveCount, setYearlyLeaveCount] = useState(0);

  const [staff, setStaff] = useState([]);
  const { user_id } = location.state;
  const [leaveDetails, setLeaveDetails] = useState([]);
  const [searchDate, setSearchDate] = useState("");
  const [searchToDate, setSearchToDate] = useState("");
  const [filterStatus, setFilterStatus] = useState("");
  const [expandedRowSearchEmployee, setExpandedRowSearchEmployee] =
    useState(null);


  // Balance Leaves
  const [sl, setSL] = useState(null);
  const [cl, setCL] = useState(null);
  const [el, setEL] = useState(null);
  const [nh, setNH] = useState(null);

  // Taken Leaves
  const [taken_sl, setTakenSL] = useState(null);
  const [taken_cl, setTakencl] = useState(null);
  const [taken_el, setTakenel] = useState(null);
  const [taken_nh, setTakennh] = useState(null);

  // Given Leaves
  const [given_el, setGivenel] = useState(null);
  const [given_sl, setGivensl] = useState(null);
  const [given_cl, setGivencl] = useState(null);
  const [given_nh, setGivennh] = useState(null);


  const toggleExpandSearchEmployee = (id) => {
    setExpandedRowSearchEmployee(expandedRowSearchEmployee === id ? null : id);
  };

  useEffect(() => {
    const accessToken = localStorage.getItem("access_token");

    if (user_id) {
      const fetchLeaveDetails = async () => {
        try {
          const response = await axios.get(
            `https://divine.backenddd.in/Leave/api/leave_requests_by_employee_api_view/${user_id}/`,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );
          setLeaveDetails(response.data.data);
          setLeaveCount(response.data.leave_count);
          setSL(response.data.sl);
          setEL(response.data.el);
          setNH(response.data.nh);
          setCL(response.data.cl);

          setGivensl(response.data.given_sl);
          setGivencl(response.data.given_cl);
          setGivenel(response.data.given_el);
          setGivennh(response.data.given_nh);

          setTakenSL(response.data.taken_sl);
          setTakencl(response.data.taken_cl);
          setTakenel(response.data.taken_el);
          setTakennh(response.data.taken_nh);

          setYearlyLeaveCount(response.data.yearly_leave_count);

        } catch (error) {
          console.error("Error fetching leave details:", error);
        }
      };

      fetchLeaveDetails();
    }
  }, [user_id]);

  const fetchFilteredLeaveRequests = async () => {
    const accessToken = localStorage.getItem("access_token");
    try {
      const response = await axios.get(
        `https://divine.backenddd.in/Leave/api/filtered_leave_summary_api_by_date_view/`,
        {
          params: {
            search_date: searchDate,
            search_to_date: searchToDate,
            status: filterStatus,
            filter_status_name: user_id,
          },
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setLeaveDetails(response.data.data);
      setLeaveCount(response.data.leave_count);
    } catch (error) {
      console.error("Error fetching leave requests:", error);
    }
  };

  if (!user_id) return <div>No user selected</div>;

  return (
    <div>
      <NavigationMenu />
      <ChakraProvider >
        <div className="bgLeaveColor">

          <Center>
            <VStack spacing={5} className="leaveRequestWrapper" width="80%">
              {/* Filter Leave */}
              <Box bg="#338687" color="white" p={5} borderRadius="md" boxShadow="md" textAlign="center" width="100%">
                {leaveDetails.length > 0 ? (
                  <>
                    <Heading size="lg">Leave Summary</Heading>
                    <Text fontSize="xl" mt={2}>
                      {leaveDetails[0].staff_name}
                    </Text>
                    <Divider my={3} borderColor="whiteAlpha.600" />
                    <div className="container" style={{ display: "flex", justifyContent: "space-around" }}>
                      <Text fontSize="md">
                        This <b>Year</b> Leave Taken: <strong>{yearlyleaveCount}</strong>
                      </Text>
                      <Text fontSize="md">
                        This <b>Month</b> Leave Taken: <strong>{leaveCount}</strong>
                      </Text>
                    </div>
                    <Divider my={3} borderColor="whiteAlpha.600" />

                    <div className="container" style={{ display: "flex", justifyContent: "space-around" }}>
                      <Text fontSize="md">
                        CL: {given_cl} - {taken_cl} = <strong>{cl < 0 ? <span style={{ color: "#f43f5e" }}>{cl}</span> : cl}</strong>
                      </Text>
                      <Text fontSize="md">
                        EL: {given_el} - {taken_el} = <strong>{el < 0 ? <span style={{ color: "#f43f5e" }}>{el}</span> : el}</strong>
                      </Text>
                      <Text fontSize="md">
                        SL: {given_sl} - {taken_sl} = <strong>{sl < 0 ? <span style={{ color: "#f43f5e" }}>{sl}</span> : sl}</strong>
                      </Text>
                      <Text fontSize="md">
                        NH: {given_nh} - {taken_nh} = <strong>{nh < 0 ? <span style={{ color: "#f43f5e" }}>{nh}</span> : nh}</strong>
                      </Text>
                    </div>
                  </>
                ) : (
                  <Text fontSize="lg">Loading leave details...</Text>
                )}
              </Box>

              <HStack spacing={3} width="100%">
                <FormControl id="leaveStatus" width="25%">
                  <FormLabel>Leave Status</FormLabel>
                  <ChakraSelect
                    placeholder="Select option"
                    value={filterStatus}
                    onChange={(e) => setFilterStatus(e.target.value)}
                  >
                    <option value="Pending">Pending</option>
                    <option value="Approved">Approved</option>
                    <option value="Not Approved">Not Approved</option>
                  </ChakraSelect>
                </FormControl>

                <FormControl id="startDate" width="25%">
                  <FormLabel>Start Date</FormLabel>
                  <Input
                    type="date"
                    value={searchDate}
                    onChange={(e) => setSearchDate(e.target.value)}
                  />
                </FormControl>

                <FormControl id="toDate" width="25%">
                  <FormLabel>To Date</FormLabel>
                  <Input
                    type="date"
                    value={searchToDate}
                    onChange={(e) => setSearchToDate(e.target.value)}
                  />
                </FormControl>

                <Button bg={"#3182ce"} color={"white"} onClick={fetchFilteredLeaveRequests}>
                  Search Leave
                </Button>
              </HStack>
              {/* Filter Leave */}

              <Box overflowX="auto" width="100%" mt={5}>
                <Table  >
                  <Thead bg="#3182ce">
                    <Tr>
                      <Th color="white">Sr. No.</Th>
                      <Th color="white">Leave ID</Th>
                      <Th color="white">Name</Th>
                      <Th color="white">Leave Type</Th>
                      <Th color="white">From Date</Th>
                      <Th color="white">To Date</Th>
                      <Th color="white">Status</Th>
                      <Th></Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {leaveDetails.map((employee, index) => (
                      <React.Fragment key={employee.id}>
                        <Tr
                          onClick={() => toggleExpandSearchEmployee(employee.id)}
                          style={{ cursor: "pointer" }}
                        >
                          <Td>{index + 1}</Td>
                          <Td>{employee.id}</Td>
                          <Td>
                            <Flex align="center">
                              <Image
                                src={`https://divine.backenddd.in${employee.staff_img}`}
                                alt={employee.staff_name}
                                boxSize="30px"
                                mr="10px"
                                borderRadius="full"
                              />
                              {employee.staff_name} {employee.staff_lname}
                            </Flex>
                          </Td>
                          <Td>{employee.leave_type}</Td>
                          <Td>{employee.start_date}</Td>
                          <Td>{employee.end_date}</Td>
                          <Td>{employee.status}</Td>
                          <Td>
                            {expandedRowSearchEmployee === employee.id ? (
                              <Icon as={ChevronUpIcon} />
                            ) : (
                              <Icon as={ChevronDownIcon} />
                            )}
                          </Td>
                        </Tr>
                        {expandedRowSearchEmployee === employee.id && (
                          <Tr>
                            <Td colSpan={7}>
                              <Box p={5} bg="gray.50" borderRadius="md" boxShadow="md">
                                <Heading size="sm" mb={3}>
                                  Leave Reason
                                </Heading>
                                <Text>{employee.reason}</Text>
                                {employee.leave_type === "Sick Leave" && (
                                  <a href={`https://divine.backenddd.in${employee.medical_certificate}/`} target="__blank">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye-fill" viewBox="0 0 16 16">
                                      <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0" />
                                      <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8m8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7" />
                                    </svg>
                                  </a>
                                )}
                              </Box>
                            </Td>
                          </Tr>
                        )}
                      </React.Fragment>
                    ))}
                  </Tbody>
                </Table>
              </Box>
            </VStack>
          </Center>
        </div>
      </ChakraProvider>
    </div>
  );
};

export default LeaveSummary;
