
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from './components/Login/Login';
import Dashboard from './components/Dashboard/Dashboard';
import AddEmployee from './components/Employee/AddEmployee';
import AddUploadDocument from './components/Employee/UploadDocuments/AddUploadDocument';
import SearchEmployee from './components/SearchEmployee/SearchEmployee';
import AddLeaveRequest from './components/LeaveRequest/AddLeaveRequest';
import AddHRPolicy from './components/HRPolicy/AddHRPolicy';
import AddCircular from './components/Circular/AddCircular';
import AddMaster from './components/Master/AddMaster';
import AddFormat from './components/Format/AddFormat';
import IssueFormat from './components/Format/IssueFormat';
import ViewEmployee from './components/Employee/ViewEmployee';
import EditEmployee from './components/Employee/EditEmployee';
import AddSignature from './components/Signature/AddSignature';
import PrintJoiningLetter from './components/Format/PrintJoiningLetter';
import ViewFormat from './components/Format/ViewFormat';
import Add from './components/Add/Add';
import AddNabhPolicy from './components/NabhPolicy/AddNabhPolicy';
import FileFormatForm from './components/Master/AddFileFormat';
import LeaveSummary from './components/LeaveRequest/LeaveSummary';
import AddSalary from './components/Salary/AddSalary';
import LeaveSummaryPage from './components/LeaveRequest/Addleave2';
import AddLicence from './components/Licence/AddLicence';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/addemployee" element={<AddEmployee />} />
          <Route path="/viewemployee" element={<ViewEmployee />} />
          <Route path="/searchemployee" element={<SearchEmployee />} />
          <Route path="/addleaverequest" element={<AddLeaveRequest />} />
          <Route path="/addhrpolicy" element={<AddHRPolicy />} />
          <Route path="/addcircular" element={<AddCircular />} />
          <Route path="/addmaster" element={<AddMaster/>} />
          <Route path="/addformat" element={<AddFormat/>} />
          <Route path="/issueformat" element={<IssueFormat/>} />
          <Route path="/addsignature" element={<AddSignature/>} />
          <Route path="/editEmployee" element={<EditEmployee/>} />
          <Route path="/printpreview" element={<PrintJoiningLetter/>} />
          <Route path="/viewformat" element={<ViewFormat/>} />
          <Route path="/add" element={<Add/>} />
          <Route path="/addDocument" element={<FileFormatForm />} />
          <Route path="/addnabhpolicy" element={<AddNabhPolicy/>} />
          <Route path="/leavesummary" element={<LeaveSummary />} />
          <Route path="/addSalary" element={<AddSalary />} />
          <Route path="/leaveSummaryPage" element={<LeaveSummaryPage />} />
          <Route path="/licencepage" element={<AddLicence />} />

        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
