import React, { useState, useEffect } from "react";
import NavigationMenu from "../Navigation/NavigationMenu";
import axios from "axios";

import {
  ChakraProvider,
  InputGroup,
  Input,
  InputRightElement,
  IconButton,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  FormControl,
  FormLabel,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
} from "@chakra-ui/react";

import {
  SearchIcon,
  ViewIcon,
  EditIcon,
  DeleteIcon,
  AddIcon,
} from "@chakra-ui/icons";

import "./AddCircular.css";

const API_BASE_URL = "https://divine.backenddd.in";

const AddCircular = () => {
  const [name, setName] = useState("");
  const [doc, setDoc] = useState(null); // Changed initial state to null
  const [pubdate, setPubDate] = useState(null); // Changed initial state to null

  const [policies, setPolicies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refNumber, setRefNumber] = useState("");

  const [isOpenAddHRPolicy, setIsOpenAddHRPolicy] = useState(false);

  const resetFormAddHRPolicy = () => {
    setName("");
    setDoc(null);
    setPubDate(null);
    setRefNumber("");
    setEditingIndexAddHRPolicy(-1);
    setIsOpenAddHRPolicy(false);
  };

  const fetchPolicies = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/HRPolicy/Circulars/`);
      setPolicies(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Failed to fetch policies", error);
      setLoading(false);
    }
  };
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedCirculars, setSelectedCirculars] = useState(null);
  // Create a new policy
  const createPolicy = async () => {
    const formData = new FormData();
    formData.append("title", name); // Corrected field name to 'title'
    formData.append("ref_number", refNumber); // Added ref_number
    formData.append("pub_date", pubdate); // Assuming pub_date is current date
    if (doc) {
      formData.append("doc", doc);
    } else {
      return;
    }

    try {
      const response = await axios.post(
        `https://divine.backenddd.in/HRPolicy/AddCirculars/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("Policy Created:", response.data);
      fetchPolicies(); // Refresh the list
      resetFormAddHRPolicy();
    } catch (error) {
      console.error("Failed to create policy", error);
    }
  };

  // Update an existing policy
  const updatePolicy = async (id, policyData) => {
    try {
      // Ensure "ref_number" is included in the policyData
      if (!policyData.ref_number) {
        throw new Error("Reference number is required.");
      }

      const response = await axios.put(
        `${API_BASE_URL}/HRPolicy/UpdateCirculars/${id}/`,
        policyData
      );
      console.log("Policy Updated:", response.data);
      fetchPolicies(); // Refresh the list
    } catch (error) {
      console.error("Failed to update policy", error);
      // Handle error gracefully, display error message to the user
    }
  };

  // Delete a policy
  const deletePolicy = async (id) => {
    try {
      const response = await axios.delete(
        `${API_BASE_URL}/HRPolicy/DeleteCirculars/${id}/`
      );
      console.log("Policy Deleted:", response.data);
      fetchPolicies(); // Refresh the list
    } catch (error) {
      console.error("Failed to delete policy", error);
    }
  };

  // Use Effect to load data on component mount
  useEffect(() => {
    fetchPolicies();
  }, []);

  const handleSearchAddHRPolicy = () => {
    // Define what happens when you search
    console.log("Searching...");
  };
  const {
    isOpen,
    onOpen: onOpenAddHRPolicy,
    onClose: onCloseAddHRPolicy,
  } = useDisclosure();

  const [policiesAddHRPolicy, setPoliciesAddHRPolicy] = useState([]);
  const [currentPolicyAddHRPolicy, setCurrentPolicyAddHRPolicy] = useState({
    name: "",
    file: null,
  });
  const [editingIndexAddHRPolicy, setEditingIndexAddHRPolicy] = useState(-1); // -1 when not editing

  const handleFileChangeAddHRPolicy = (e) => {
    setCurrentPolicyAddHRPolicy((prev) => ({
      ...prev,
      file: e.target.files[0],
    }));
  };

  const savePolicyAddHRPolicy = () => {
    const newPolicy = {
      ...currentPolicyAddHRPolicy,
      id:
        editingIndexAddHRPolicy === -1
          ? policiesAddHRPolicy.length + 1
          : policiesAddHRPolicy[editingIndexAddHRPolicy].id,
      createDate:
        editingIndexAddHRPolicy === -1
          ? new Date()
          : policiesAddHRPolicy[editingIndexAddHRPolicy].createDate,
    };

    let updatedPoliciesAddHRPolicy = [...policiesAddHRPolicy];
    if (editingIndexAddHRPolicy === -1) {
      updatedPoliciesAddHRPolicy.push(newPolicy);
    } else {
      updatedPoliciesAddHRPolicy[editingIndexAddHRPolicy] = newPolicy;
    }

    setPoliciesAddHRPolicy(updatedPoliciesAddHRPolicy);
    resetFormAddHRPolicy();
  };

  const handleEditAddHRPolicy = (index) => {
    console.log("Selected index:", index);
    console.log("Policies at the time of edit:", policies);

    if (index >= 0 && index < policies.length) {
      const policy = policies[index];
      setName(policy.title); // Set name in state
      setDoc(policy.doc); // Set document in state, assuming `policy.doc` holds the document info

      setEditingIndexAddHRPolicy(index);
      onOpenAddHRPolicy();
    } else {
      console.error("Invalid index or empty policies array");
    }
    // setEditingIndexAddHRPolicy(index);
    // onOpenAddHRPolicy();
  };

  const handleDeleteAddHRPolicy = (index) => {
    setPoliciesAddHRPolicy(policiesAddHRPolicy.filter((_, i) => i !== index));
  };

  const handleCloseEditModal = () => {
    setSelectedCirculars(null);
    setIsEditModalOpen(false);
  };

  const formatDate = (dateString) => {
    if (!dateString) return "No date provided"; // Fallback text or logic
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
  };

  const handleEdit = (policy) => {
    setSelectedCirculars(policy);
    setIsEditModalOpen(true);
  };

  const handleSaveEdit = async () => {
    const formData = new FormData();
    formData.append("title", selectedCirculars.title); // Assuming 'title' is the state for the policy name
    formData.append("ref_number", selectedCirculars.ref_number); // Assuming 'ref_number' is the state for the reference number
    formData.append("pub_date", selectedCirculars.pub_date); // Assuming 'pub_date' is the state for the publication date
    if (selectedCirculars.doc) {
      formData.append("doc", selectedCirculars.doc); // Assuming 'doc' is the state containing the file
    } else {
      formData.append("doc", "");
    }

    try {
      await axios.put(
        `${API_BASE_URL}/HRPolicy/UpdateCirculars/${selectedCirculars.id}/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setIsEditModalOpen(false);

      fetchPolicies();
    } catch (error) {
      console.error("Error updating policy:", error);
    }
  };

  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleSearch = async () => {
    try {
      const response = await axios.get(
        `https://divine.backenddd.in/HRPolicy/SearchCirculars/?q=${inputValue}/`
      );
      setPolicies(response.data);
    } catch (error) {
      console.error("Failed to search policies", error);
    }
  };

  const [userStatus, setUserStatus] = useState(false);
  const [isSuperuser, setIsSuperUser] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get("https://divine.backenddd.in/HumanResource/user-status/");
        if (response.status === 200) {
          console.log("Response: ", response.data)
          setUserStatus(response.data);
          setIsSuperUser(response.data.is_superuser);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, []);

  return (
    <>
      <NavigationMenu />
      <ChakraProvider>
        <div className="addHrPolicyWrapper">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-md-12 col-lg-12">
                <div className="addHrPolicyHeading">
                  <h1>Circular Document</h1>
                  <p>
                    Guidelines for <span> Employee Management </span>and
                    Workplace Conduct
                  </p>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md-4 col-lg-4">
                <InputGroup className="hrPolicySearchInput">
                  <Input
                    placeholder="Search Circular"
                    onChange={handleInputChange}
                  />
                  <InputRightElement>
                    <IconButton
                      aria-label="Search database"
                      icon={<SearchIcon />}
                      onClick={handleSearch}
                      size="sm"
                    />
                  </InputRightElement>
                </InputGroup>
              </div>

              <div className="col-12 col-md-4 col-lg-4">
                <Button colorScheme="blue" onClick={handleSearch}>Search</Button>
              </div>

              <div className="col-12 col-md-4 col-lg-4">
                <Button
                  leftIcon={<AddIcon />}
                  colorScheme="blue"
                  onClick={() => {
                    setIsOpenAddHRPolicy(true); // Trigger the opening of the modal
                  }}
                >
                  Add Circular
                </Button>
                <Modal
                  isOpen={isOpenAddHRPolicy}
                  onClose={resetFormAddHRPolicy}
                >
                  <ModalOverlay />
                  <ModalContent>
                    <ModalHeader>
                      {editingIndexAddHRPolicy === -1
                        ? "Create Circular"
                        : "Edit Circular"}
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                      <FormControl>
                        <FormLabel mt={4}>Reference Number</FormLabel>
                        <Input
                          value={refNumber}
                          onChange={(e) => setRefNumber(e.target.value)}
                        />
                        <FormLabel>Title of Circular</FormLabel>
                        <Input
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />

                        <FormLabel mt={4}>Documents</FormLabel>
                        <Input
                          type="file"
                          onChange={(e) => setDoc(e.target.files[0])}
                        />
                        <FormLabel mt={4}>Date of Circular</FormLabel>
                        <Input
                          type="date"
                          onChange={(e) => setPubDate(e.target.value)}
                        />
                      </FormControl>
                    </ModalBody>

                    <ModalFooter>
                      <Button
                        colorScheme="blue"
                        mr={3}
                        onClick={async () => {
                          await createPolicy();
                          resetFormAddHRPolicy();
                        }}
                      >
                        Save
                      </Button>
                    </ModalFooter>
                  </ModalContent>
                </Modal>
              </div>
            </div>

            <Modal isOpen={isEditModalOpen} onClose={handleCloseEditModal}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Edit Circular</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <FormControl>
                    <FormLabel>Circular Name</FormLabel>
                    <Input
                      type="text"
                      value={selectedCirculars ? selectedCirculars.title : ""}
                      onChange={(e) =>
                        setSelectedCirculars({
                          ...selectedCirculars,
                          title: e.target.value,
                        })
                      }
                    />
                    <FormLabel mt={4}>Reference Number</FormLabel>
                    <Input
                      type="text"
                      value={
                        selectedCirculars ? selectedCirculars.ref_number : ""
                      }
                      onChange={(e) =>
                        setSelectedCirculars({
                          ...selectedCirculars,
                          ref_number: e.target.value,
                        })
                      }
                    />
                    <FormLabel mt={4}>Publication Date</FormLabel>
                    <Input
                      type="date"
                      value={
                        selectedCirculars ? selectedCirculars.pub_date : ""
                      }
                      onChange={(e) =>
                        setSelectedCirculars({
                          ...selectedCirculars,
                          pub_date: e.target.value,
                        })
                      }
                    />
                    <FormLabel mt={4}>Attach Document</FormLabel>
                    <Input
                      type="file"
                      onChange={(e) =>
                        setSelectedCirculars({
                          ...selectedCirculars,
                          doc: e.target.files[0],
                        })
                      }
                    />
                  </FormControl>
                </ModalBody>
                <ModalFooter>
                  <Button colorScheme="blue" onClick={handleSaveEdit}>
                    Save
                  </Button>
                  <Button onClick={handleCloseEditModal}>Cancel</Button>
                </ModalFooter>
              </ModalContent>
            </Modal>

            <div className="addHRPolicyViewWrapper">
              <div className="row">
                <div className="col-12 col-md-12 col-lg-12">
                  <Box overflowX={"auto"}>
                    <Table
                      variant="simple"
                      mt={4}
                      className="hrPolicyTableFormat"
                    >
                      <Thead>
                        <Tr>
                          <Th width={"25%"}>Reference Number</Th>
                          <Th width={"20%"}>Title of Circular</Th>
                          <Th width={"20%"}>Date of Circular</Th>
                          <Th width={"5%"}>Documents</Th>
                          {isSuperuser && (
                            <Th width={"20%"}>Actions</Th>
                          )}
                        </Tr>
                      </Thead>
                      <Tbody>
                        {policies.map((policy, index) => (
                          <Tr key={index}>
                            <Td>{policy.ref_number}</Td>
                            <Td>{policy.title}</Td>
                            <Td>{formatDate(policy.pub_date)}</Td>

                            <Td>
                              <IconButton
                                icon={<ViewIcon />}
                                aria-label="View Document"
                                onClick={() =>
                                  window.open(
                                    `https://divine.backenddd.in${policy.doc}/`,
                                    "_blank"
                                  )
                                }
                              />
                            </Td>
                            {isSuperuser && (
                            <Td>
                              <IconButton
                                icon={<EditIcon />}
                                aria-label="Edit"
                                mx={2}
                                onClick={() => handleEdit(policy)} // This should prepare the form
                              />
                              <IconButton
                                icon={<DeleteIcon />}
                                aria-label="Delete"
                                onClick={() => deletePolicy(policy.id)}
                              />
                            </Td>
                            )}
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ChakraProvider>
    </>
  );
};

export default AddCircular;
