import React, { useState, useEffect } from "react";
import {
  Button,
  Input,
  FormControl,
  FormLabel,
  Select,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Image,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  IconButton,
  Box,
  ChakraProvider,
} from "@chakra-ui/react";
import { EditIcon, DeleteIcon } from "@chakra-ui/icons";
import NavigationMenu from "../Navigation/NavigationMenu";
import axios from "axios";

const ImageUploadForm = () => {
  const [entries, setEntries] = useState([]);
  const [staff, setStaff] = useState("");
  const [position, setPosition] = useState("");
  const [signature, setSignature] = useState(null);
  
  const [positions, setPositions] = useState("");


  const [editStaff, setEditStaff] = useState("");
  const [editPosition, setEditPosition] = useState("");
  const [editSignature, setEditSignature] = useState(null);
  const [editIndex, setEditIndex] = useState(-1);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDisclosure();

  useEffect(() => {
    fetchEntries();
    fetchPositions();
  }, []);

  const fetchEntries = async () => {
    try {
      const response = await axios.get(
        "https://divine.backenddd.in/HumanResource/signatures/"
      );
      setEntries(response.data);
    } catch (error) {
      console.error("Error fetching entries:", error);
    }
  };

  const handleFileChange = (event, isEdit = false) => {
    const file = event.target.files[0];
    if (isEdit) {
      setEditSignature(file);
    } else {
      setSignature(file);
    }
  };

  const fetchPositions = async () => {
    try {
      const response = await axios.get(
        "https://divine.backenddd.in/HumanResource/api/positions/"
      );
      setPositions(response.data);
      console.log("Positions: ", response.data);
    } catch (error) {
      console.error("Error fetching positions:", error);
    }
  };

  const addEntry = async () => {
    if (!staff || !position) return;

    const formData = new FormData();
    formData.append("staff", staff);
    formData.append("position", position);
    // formData.append("signature", signature);

    try {
      const response = await axios.post(
        "https://divine.backenddd.in/HumanResource/signatures/create/",
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );
      setEntries([...entries, response.data]);
      setStaff("");
      setPosition("");
      // setSignature(null);
    } catch (error) {
      console.error("Error adding entry:", error);
    }
  };

  const openEditModal = (index) => {
    setEditIndex(index);
    setEditStaff(entries[index].staff);
    setEditPosition(entries[index].position);
    setEditSignature(null); // Reset the signature for fresh upload
    onEditOpen();
  };

  const updateEntry = async () => {
    const formData = new FormData();
    formData.append("staff", editStaff);
    formData.append("position", editPosition);
    if (editSignature) {
      formData.append("signature", editSignature);
    }

    try {
      const response = await axios.put(
        `https://divine.backenddd.in/HumanResource/signatures/update/${entries[editIndex].id}/`,
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );
      const updatedEntries = [...entries];
      updatedEntries[editIndex] = response.data;
      // setEntries(updatedEntries);
      fetchEntries();
      onEditClose();
    } catch (error) {
      console.error("Error updating entry:", error);
    }
  };

  const deleteEntry = async (index) => {
    try {
      await axios.delete(
        `https://divine.backenddd.in/HumanResource/signatures/delete/${entries[index].id}/`
      );
      fetchEntries();
    } catch (error) {
      console.error("Error deleting entry:", error);
    }
  };

  return (
    <>
      <NavigationMenu />
      <ChakraProvider>
        <div className="addHrPolicyWrapper">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="addHrPolicyHeading">
                  <h1>Add Signature</h1>
                  <p>
                    Guidelines for <span> Formats, Certificate </span>and
                    Letters
                  </p>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md-3">
                <FormControl isRequired className="addEmployeeFormGroup">
                  <FormLabel>Name of Person</FormLabel>
                  <Input
                    placeholder="Enter name"
                    value={staff}
                    style={{ backgroundColor: "white" }}
                    onChange={(e) => setStaff(e.target.value)}
                  />
                </FormControl>
              </div>

              <div className="col-12 col-md-3">
                <FormControl isRequired className="addEmployeeFormGroup">
                  <FormLabel>Designation</FormLabel>
                  <Select
                    placeholder="Select designation"
                    value={position}
                    style={{ backgroundColor: "white" }}
                    onChange={(e) => setPosition(e.target.value)}
                  >
                    {positions &&
                      positions.sort((a,b)=>a.name.localeCompare(b.name)).map((pos, index) => (
                        <option key={index} value={pos.name}>
                          {pos.name}
                        </option>
                      ))}
                  </Select>
                </FormControl>
              </div>

              {/* <div className="col-12 col-md-3">
                <FormControl isRequired className="addEmployeeFormGroup">
                  <FormLabel>Upload Signature</FormLabel>
                  <Input
                    type="file"
                    accept="image/*"
                    style={{ backgroundColor: "white", paddingTop: "4px" }}
                    onChange={(event) => handleFileChange(event)}
                  />
                </FormControl>
              </div> */}

              <div className="col-12 col-md-3">
                <Button mt={12} onClick={addEntry} colorScheme="blue">
                  Create
                </Button>
              </div>
            </div>

            <div className="addHRPolicyViewWrapper">
              <div className="row">
                <div className="col-12">
                  <Box overflowX={"auto"}>
                    <Table variant="simple" className="hrPolicyTableFormat">
                      <Thead>
                        <Tr>
                          <Th>Srno.</Th>
                          <Th>Name of Person</Th>
                          <Th>Designation</Th>
                          <Th>Action</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {entries.map((entry, index) => (
                          <Tr key={index}>
                            <Td>{index + 1}</Td>
                            <Td>{entry.staff}</Td>
                            <Td>{entry.position}</Td>
                            {/* <Td>
                              <Button
                             
                                onClick={() =>
                                  window.open(
                                    `https://divine.backenddd.in${entry.signature}/`,
                                    "_blank"
                                  )
                                }
                              >
                                View
                              </Button>
                            </Td> */}
                            <Td>
                              <IconButton
                                icon={<EditIcon />}
                                onClick={() => openEditModal(index)}
                                m={1}
                              />
                              <IconButton
                                icon={<DeleteIcon />}
                                onClick={() => deleteEntry(index)}
                                m={1}
                              />
                            </Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </Box>

                  {/* Image View Modal */}
                  <Modal isOpen={isOpen} onClose={onClose}>
                    <ModalOverlay />
                    <ModalContent>
                      <ModalHeader>View Picture</ModalHeader>
                      <ModalCloseButton />
                      <ModalBody>
                        <Image src={signature} alt="Uploaded Image" />
                      </ModalBody>
                      <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={onClose}>
                          Close
                        </Button>
                      </ModalFooter>
                    </ModalContent>
                  </Modal>

                  {/* Edit Entry Modal */}
                  <Modal isOpen={isEditOpen} onClose={onEditClose}>
                    <ModalOverlay />
                    <ModalContent>
                      <ModalHeader>Edit Entry</ModalHeader>
                      <ModalCloseButton />
                      <ModalBody>
                        <FormControl>
                          <FormLabel>Name of Person</FormLabel>
                          <Input
                            placeholder="Enter name"
                            value={editStaff}
                            onChange={(e) => setEditStaff(e.target.value)}
                          />
                        </FormControl>
                        <FormControl mt={4}>
                          <FormLabel>Designation</FormLabel>
                          <Select
                            value={editPosition}
                            onChange={(e) => setEditPosition(e.target.value)}
                          >
                            {positions &&
                              positions.map((pos, index) => (
                                <option key={index} value={pos.name}>
                                  {pos.name}
                                </option>
                              ))}
                          </Select>
                        </FormControl>
                        {/* <FormControl mt={4}>
                          <FormLabel>Upload Signature</FormLabel>
                          <Input
                            type="file"
                            accept="image/*"
                            onChange={(event) => handleFileChange(event, true)}
                          />
                        </FormControl> */}
                      </ModalBody>
                      <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={updateEntry}>
                          Update
                        </Button>
                        <Button onClick={onEditClose}>Cancel</Button>
                      </ModalFooter>
                    </ModalContent>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ChakraProvider>
    </>
  );
};

export default ImageUploadForm;
