import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Select,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  IconButton,
  
} from "@chakra-ui/react";
import { ViewIcon, EditIcon, DeleteIcon } from "@chakra-ui/icons";
import axios from "axios";

function AddDisciplinaryRecords({ username }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [currentRecord, setCurrentRecord] = useState(null);
  const [records, setRecords] = useState([]);
  const [formData, setFormData] = useState({
    staff: "",
    referenceNumber: "",
    reasonStatus: "",
    actionTaken: "",
    document: null,
  });

  const apiUrl = "https://divine.backenddd.in/HumanResource/";

  // Fetch records initially and on dependency change
  useEffect(() => {
    fetchRecords();
  }, [username]);

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (files) {
      setFormData((prev) => ({ ...prev, document: files[0] }));
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const saveRecord = () => {
    const url = currentRecord
      ? `${apiUrl}disciplinary_records/${currentRecord.id}/`
      : `${apiUrl}disciplinary_records/`;
    const method = currentRecord ? "put" : "post";
    const data = new FormData();
    data.append("ref_no", formData.referenceNumber);
    data.append("reason_status", formData.reasonStatus);
    data.append("action_taken", formData.actionTaken);
    if (formData.document) {
      data.append("docs", formData.document);
    }
    data.append("staff", username);

    axios({
      method: method,
      url: url,
      data: data,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then(() => {
        fetchRecords();
        resetForm();
      })
      .catch((error) => console.error("Error saving data:", error));
  };

  const fetchRecords = () => {
    axios
      .get(
        `https://divine.backenddd.in/HumanResource/viewparticularemployeeDisciplinaryRecord/${username}/`
      )
      .then((response) => setRecords(response.data))
      .catch((error) => console.error("Error fetching data:", error));
  };

  const resetForm = () => {
    setFormData({
      referenceNumber: "",
      reasonStatus: "",
      actionTaken: "",
      document: null,
    });
    setCurrentRecord(null);
    onClose();
  };

  const handleEdit = (record) => {
    setCurrentRecord(record);
    // Populate form data with old values
    setFormData({
      referenceNumber: record.ref_no,
      reasonStatus: record.reason_status,
      actionTaken: record.action_taken,
      document: null,
    });
    onOpen();
  };

  const handleAddRecord = () => {
    onOpen();
    setCurrentRecord(null);
    setFormData({
      referenceNumber: "",
      reasonStatus: "",
      actionTaken: "",
      document: null,
    });
  };

  const handleDelete = (id) => {
    axios
      .delete(`${apiUrl}disciplinary_records/${id}/`)
      .then(() => fetchRecords())
      .catch((error) => console.error("Error deleting record:", error));
  };

  return (
    <>
      <Button onClick={handleAddRecord} className="addEmployeeBtn">
        <i class="fa-solid fa-plus AddDependIcon"></i> Add Record
      </Button>
      <Modal isOpen={isOpen} onClose={resetForm}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {currentRecord ? "Edit Record" : "Add Record"}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl isRequired>
              <FormLabel>Reference Number</FormLabel>
              <Input
                placeholder="Enter reference number"
                name="referenceNumber"
                value={formData.referenceNumber}
                onChange={handleInputChange}
              />
            </FormControl>
            <FormControl mt={4} isRequired>
              <FormLabel>Reason Status</FormLabel>
              <Textarea
                placeholder="Describe the reason"
                name="reasonStatus"
                value={formData.reasonStatus}
                onChange={handleInputChange}
              />
            </FormControl>
            <FormControl mt={4} isRequired>
              <FormLabel>Action Taken</FormLabel>
              <Select
                placeholder="Select action"
                name="actionTaken"
                value={formData.actionTaken}
                onChange={handleInputChange}
              >
                <option>Verbal warning</option>
                <option>Written warning</option>
                <option>Suspension</option>
                <option>Termination</option>
                <option>Demotion</option>
                <option>Discrimination</option>
                <option>Poor performance</option>
              </Select>
            </FormControl>
            <FormControl mt={4}  isRequired>
              <FormLabel>Attach Document</FormLabel>
              <Input type="file" name="document" onChange={handleInputChange} />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={saveRecord}>
              Save
            </Button>
            <Button onClick={resetForm}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Box overflowX={"auto"}>
        <Table variant="simple" className="addEmployeeTableFormat">
          <Thead>
            <Tr>
              <Th>Reference Number</Th>
              <Th>Reason Status</Th>
              <Th>Action Taken</Th>
              <Th>Document</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {records.map((record, index) => (
              <Tr key={index}>
                <Td>{record.ref_no}</Td>
                <Td>{record.reason_status}</Td>
                <Td>{record.action_taken}</Td>
                <Td>
                  {record.docs && (
                    <IconButton
                      aria-label="View document"
                      icon={<ViewIcon />}
                      onClick={() =>
                        window.open(`https://divine.backenddd.in${record.docs}/`)
                      }
                    />
                  )}
                </Td>
                <Td>
                  <IconButton
                    aria-label="Edit"
                    icon={<EditIcon />}
                    onClick={() => handleEdit(record)}
                  />
                  <IconButton
                    aria-label="Delete"
                    icon={<DeleteIcon />}
                    ml={2}
                    onClick={() => handleDelete(record.id)}
                  />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </>
  );
}

export default AddDisciplinaryRecords;
