import React, { useState, useEffect } from "react";
import {
  FormControl,
  FormLabel,
  Button,
  Input,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  IconButton,
  Box,
} from "@chakra-ui/react";
import { ViewIcon, EditIcon, DeleteIcon } from "@chakra-ui/icons";
import axios from "axios";

const AddSalary = ({ username }) => {
  const {
    isOpen: isOpenSalary,
    onOpen: onOpenSalary,
    onClose: onCloseSalary,
  } = useDisclosure();
  const [formDataSalary, setFormDataSalary] = useState({
    referenceNumber: "",
    dateOfIncrement: "",
    nextDueDate: "",
    amountIncrease: "",
    lastIncrementSalary: "",
    salary: "",
    document: null,
  });
  const [recordsSalary, setRecordsSalary] = useState([]);
  const [editingIndexSalary, setEditingIndexSalary] = useState(-1);
  const [refNo, setRefNo] = useState("");
  const [dateOfSalary, setDateOfSalary] = useState("");
  const [nextDueDate, setNextDueDate] = useState("");
  const [amountIncreased, setAmountIncreased] = useState("");
  const [lastIncreased, setLastIncreased] = useState("");
  const [prevlastIncreased, setPrevLastIncreased] = useState("");
  const [recalculateSalaryOnEdit, setRecalculateSalaryOnEdit] = useState(false);

  const [lastIncremented, setLastIncremented] = useState("");
  const [last2lastIncremented, setLast2LastIncremented] = useState("");
  const [updatedSalary, setUpdatedSalary] = useState(0);
  const [Salarys, setSalarys] = useState([]);
  const [docs, setDocs] = useState(null);
  const [selectedSal, setselectedSal] = useState(null);

  const resetForm = () => {
    setRefNo("");
    setDateOfSalary("");
    setNextDueDate("");
    setAmountIncreased("");
    setLastIncreased("");
    setUpdatedSalary(0);
    setDocs(null);
    setEditingIndexSalary(-1); // Also reset the editing index
  };

  const fetchSalarys = async () => {
    try {
      const response = await axios.get(
        `https://divine.backenddd.in/HumanResource/GetSalaryListView/${username}/`
      );
      console.log("Response: ", response.data);
      setSalarys(response.data);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  useEffect(() => {
    if (username) {
      fetchSalarys();
    }
  }, [username]);

  const handleAddRecord = async () => {
    resetForm();

    if (
      username !== undefined &&
      username !== null &&
      username !== "" &&
      username !== " "
    ) {
      const formData = new FormData(); // Use FormData for 'multipart/form-data'
      formData.append("staff", username);
      formData.append("ref_number", refNo);
      formData.append("date_of_increment", dateOfSalary);
      formData.append("next_due_date", nextDueDate);
      formData.append("amount_increased", amountIncreased);
      formData.append("last_increased_salary", prevlastIncreased);
      formData.append("salary", updatedSalary);

      if (docs) formData.append("docs", docs);

      try {
        const response = await fetch(
          "https://divine.backenddd.in/HumanResource/salary_list_create/",
          {
            method: "POST",
            body: formData, // Send as FormData
            // Don't set 'Content-Type': 'multipart/form-data' manually
          }
        );

        if (response.ok) {
          onCloseSalary();
          fetchSalarys();
          fetchSalary();
          resetForm();
        } else {
          const errorData = await response.json(); // Assuming API sends back a JSON on error
          console.error("Failed to add record", errorData);
        }
      } catch (error) {
        console.error("Error submitting Salary:", error);
      }
    } else {
      alert("Username Cant be null");
    }
  };

  // Get Current Salary
  const [currentSalary, setCurrentSalary] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchSalary = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `https://divine.backenddd.in/HumanResource/GetCurrentSalaryOfEmployee/${username}/`
      );
      setCurrentSalary(response.data.Current_Salary); // Adjust this if your response structure is different
      setPrevLastIncreased(response.data.last_amount_increased); // Adjust this if your response structure is different
      setLast2LastIncremented(response.data.last_2_last_increased_salary); // Adjust this if your response structure is different
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!username) {
      setError("Username is required");
      setLoading(false);
      return;
    }

    fetchSalary();
  }, [username]);

  useEffect(() => {
    // Parse values to ensure they are treated as numbers
    const increase = parseFloat(amountIncreased);
    const baseSalary = parseFloat(currentSalary);

    // Check if the amountIncreased is a number; if not, fallback to currentSalary
    if (!isNaN(increase) && !isNaN(baseSalary)) {
      const updated = baseSalary + increase;
      setUpdatedSalary(updated);
    } else {
      // Set to currentSalary if the amount increased is not a valid number
      setUpdatedSalary(baseSalary);
    }
  }, [amountIncreased, currentSalary]); // Depend on amountIncreased and currentSalary to recalculate when they change

  const handleInputChangeSalary = (e) => {
    const { name, value, files } = e.target;
    if (files) {
      setFormDataSalary({ ...formDataSalary, document: files[0] });
    } else {
      setFormDataSalary({ ...formDataSalary, [name]: value });
    }
  };

  const editRecordSalary = async (index) => {
    const record = Salarys.find((record) => record.id === index);
    if (record) {
      setRefNo(record.ref_number || "");
      setDateOfSalary(record.date_of_increment || "");
      // setNextDueDate(record.next_due_date || "");
      setAmountIncreased(record.amount_increased || 0);
      setLastIncreased(prevlastIncreased);
      setUpdatedSalary(record.salary || "");
      setDocs(record.docs || null);
      setEditingIndexSalary(index);
      onOpenSalary();
    }
  };

  const handleUpdateRecord = async () => {
    if (editingIndexSalary === -1) return; // Ensures there is a record to update.

    const formData = new FormData();
    formData.append("staff", username);
    formData.append("ref_number", refNo);
    formData.append("date_of_increment", dateOfSalary);
    formData.append("next_due_date", nextDueDate);
    formData.append("amount_increased", amountIncreased);
    formData.append("last_increased_salary", prevlastIncreased);
    formData.append("salary", updatedSalary);

    if (docs) {
      formData.append("docs", docs);
    }

    try {
      const response = await axios.put(
        `https://divine.backenddd.in/HumanResource/salary_detail/${editingIndexSalary}/`,
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );

      if (response.status === 200) {
        alert("Record updated successfully.");
        fetchSalarys();
        onCloseSalary();
        fetchSalary();
        resetForm();
        setEditingIndexSalary(-1);
      } else {
        alert("Failed to update the record.");
      }
    } catch (error) {
      console.error("Error updating Salary:", error);
      alert("Error updating record.");
    }
  };

  const deleteRecordSalary = async (index) => {
    setLoading(true);
    try {
      const response = await axios.delete(
        `https://divine.backenddd.in/HumanResource/salary_detail/${index}/`
      );
      if (response.status === 200) {
        // Check for the correct success status code as per your API
        alert("Salary Deleted Successfully");
        fetchSalarys();
        fetchSalary();
      } else {
        alert("Failed to delete the salary record");
      }
    } catch (err) {
      setError(err.message);
      alert("Error during deletion: " + err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button
        className="addEmployeeBtn"
        onClick={() => {
          setEditingIndexSalary(-1); // Ensure no index is considered for editing
          resetForm(); // Reset all form fields to initial state
          setRecalculateSalaryOnEdit(false);
          onOpenSalary(); // Open the modal
        }}
      >
        <i class="fa-solid fa-plus AddDependIcon"></i>
        Add Record
      </Button>

      <Modal isOpen={isOpenSalary} onClose={onCloseSalary}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {editingIndexSalary !== -1 ? "Edit Record" : "Add Record"}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl isRequired>
              {/* <label>Staff: {username}</label> */}

              <FormLabel>Reference Number</FormLabel>

              <Input
                placeholder="Enter reference number"
                name="referenceNumber"
                value={refNo}
                onChange={(e) => setRefNo(e.target.value)}
              />
            </FormControl>
            <FormControl mt={4} isRequired>
              <FormLabel>Date of Increment</FormLabel>
              <Input
                type="date"
                name="dateOfIncrement"
                value={dateOfSalary}
                onChange={(e) => setDateOfSalary(e.target.value)}
              />
            </FormControl>
            {/* <FormControl mt={4} isRequired>
              <FormLabel>Next Due Date</FormLabel>
              <Input
                type="date"
                name="nextDueDate"
                value={nextDueDate}
                onChange={(e) => setNextDueDate(e.target.value)}
              />
            </FormControl> */}
            <FormControl mt={4} isRequired>
              <FormLabel>Amount Increase</FormLabel>
              <Input
                type="number"
                name="amountIncrease"
                value={amountIncreased}
                onChange={(e) => setAmountIncreased(e.target.value)}
              />
            </FormControl>
            <FormControl mt={4} isRequired>
              <FormLabel>Last Increment Salary</FormLabel>
              <Input
                type="number"
                name="lastIncrementSalary"
                value={prevlastIncreased}
                onChange={(e) => setLastIncreased(e.target.value)}
              />
            </FormControl>
            <FormControl mt={4} isRequired>
              <FormLabel>Salary</FormLabel>
              {currentSalary && currentSalary !== 0 ? (
                <Input
                  type="number"
                  name="salary"
                  value={updatedSalary}
                  onChange={(e) => setUpdatedSalary(e.target.value)}
                />
              ) : (
                <Input
                  type="number"
                  name="salary"
                  value={updatedSalary}
                  onChange={(e) => setUpdatedSalary(e.target.value)}
                />
              )}
            </FormControl>
            <FormControl mt={4} isRequired>
              <FormLabel>Attach Document</FormLabel>
              <Input
                type="file"
                name="docs"
                onChange={(e) => setDocs(e.target.files[0])}
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="blue"
              onClick={
                editingIndexSalary !== -1 ? handleUpdateRecord : handleAddRecord
              }
            >
              {editingIndexSalary !== -1 ? "Update" : "Save"}
            </Button>
            <Button onClick={onCloseSalary}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Box overflowX={"auto"}>
        <Table variant="simple" mt={4} className="addEmployeeTableFormat">
          <Thead>
            <Tr>
              <Th>Reference Number</Th>
              <Th>Date of Increment</Th>
              <Th>Next Due Date</Th>
              <Th>Amount Increased</Th>
              <Th>Last Increment Salary</Th>
              <Th>Salary</Th>
              <Th>Document</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {Salarys.map((record, index) => (
              <Tr key={record.id}>
                <Td>{record.ref_number}</Td>
                <Td>{record.date_of_increment}</Td>
                <Td>{record.next_due_date}</Td>
                <Td>{record.amount_increased}</Td>
                <Td>{record.last_increased_salary}</Td>
                <Td>{record.salary}</Td>
                <Td>
                  {record.docs && (
                    <IconButton
                      aria-label="View document"
                      icon={<ViewIcon />}
                      onClick={() =>
                        window.open(`https://divine.backenddd.in${record.docs}/`)
                      }
                    />
                  )}
                </Td>
                <Td>
                  <IconButton
                    aria-label="Edit"
                    icon={<EditIcon />}
                    onClick={() => editRecordSalary(record.id)}
                  />
                  <IconButton
                    aria-label="Delete"
                    icon={<DeleteIcon />}
                    ml={2}
                    onClick={() => deleteRecordSalary(record.id)}
                  />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </>
  );
};

export default AddSalary;
