import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  List,
  ListItem,
  Flex,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  ChakraProvider,
  useToast,
  Select,
} from "@chakra-ui/react";
import { DeleteIcon, EditIcon } from "@chakra-ui/icons";

const AddWorkingDepartment = ({ workingDepartments, departments, onDataChange }) => {
  const [name, setName] = useState("");
  const [dept, setDept] = useState("");
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedWorkingDepartment, setSelectedWorkingDepartment] = useState(null);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false);
  const [workingDepartmentToDelete, setWorkingDepartmentToDelete] = useState(null);
  const toast = useToast();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post("https://divine.backenddd.in/HumanResource/api/Createworkingdepartments/", { name, dept });
      toast({
        title: "Working Department created successfully!",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      onDataChange();
      setName("");
      setDept("");
    } catch (error) {
      console.error("Error creating working department:", error);
      toast({
        title: "An error occurred while creating the working department. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };



  const handleDelete = (workingDepartment) => {
    setWorkingDepartmentToDelete(workingDepartment);
    setIsDeleteConfirmationOpen(true);
  };

  const confirmDelete = async () => {
    try {
      await axios.delete(`https://divine.backenddd.in/HumanResource/api/working_departments/delete/${workingDepartmentToDelete.id}/`);
      toast({
        title: "Working Department deleted successfully!",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      onDataChange();
      setIsDeleteConfirmationOpen(false);
    } catch (error) {
      console.error("Error deleting working department:", error);
      toast({
        title: "An error occurred while deleting the working department. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleEdit = (workingDepartment) => {

    setSelectedWorkingDepartment({ ...workingDepartment, dept: { id: workingDepartment.dept } });
    setIsEditModalOpen(true);
  };

  const handleCloseEditModal = () => {
    setSelectedWorkingDepartment(null);
    setIsEditModalOpen(false);
  };

  const handleSaveEdit = async () => {
    try {
      await axios.put(`https://divine.backenddd.in/HumanResource/api/working_departments/${selectedWorkingDepartment.id}/`, {
        name: selectedWorkingDepartment.name,
        dept: selectedWorkingDepartment.dept.id,
      });
      toast({
        title: "Working Department updated successfully!",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setIsEditModalOpen(false);
      onDataChange();
    } catch (error) {
      console.error("Error updating working department:", error);
      toast({
        title: "An error occurred while updating the working department. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const [userStatus, setUserStatus] = useState(false);
  const [isSuperuser, setIsSuperUser] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get("https://divine.backenddd.in/HumanResource/user-status/");
        if (response.status === 200) {
          console.log("Response: ", response.data)
          setUserStatus(response.data);
          setIsSuperUser(response.data.is_superuser);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, []);

  return (
    <ChakraProvider>
      <Box maxW="md" mx="auto" mt="8" borderWidth="1px" borderRadius="lg" className="addDepartmentStyling">
        <h5 className="departmentHeading">Add Working Department</h5>
        <form onSubmit={handleSubmit}>
          <div className="inputBox">
            <FormControl id="workingDepartmentName">
              <Input type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder="Working Department" />
            </FormControl>
            <FormControl id="deptName" my="3">
              <Select value={dept} onChange={(e) => setDept(e.target.value)} placeholder="Select Department">
                {departments.map((dept) => (
                  <option key={dept.id} value={dept.id}>
                    {dept.name}
                  </option>
                ))}
              </Select>
            </FormControl>
            <Button colorScheme="blue" type="submit">
              Create
            </Button>
          </div>
        </form>
        <div className="departmentData">
          <List mt="4">
            {workingDepartments.map((workingDepartment) => (
              <ListItem key={workingDepartment.id} borderBottom="1px solid #ccc" style={{paddingTop:"7px", paddingBottom:"7px"}}>
                <Flex align="center" justify="space-between">
                  {workingDepartment.name}
                  <Flex>
                    {isSuperuser && (
                      <>
                        <IconButton aria-label="Edit" icon={<EditIcon />} mr="2" onClick={() => handleEdit(workingDepartment)} />
                        <IconButton aria-label="Delete" icon={<DeleteIcon />} onClick={() => handleDelete(workingDepartment)} />
                      </>
                    )}
                  </Flex>
                </Flex> 
              </ListItem>
            ))}
          </List>
        </div>
        <Modal isOpen={isEditModalOpen} onClose={handleCloseEditModal}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Edit Working Department</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormControl>
                <FormLabel>Working Department Name</FormLabel>
                <Input
                  type="text"
                  value={selectedWorkingDepartment ? selectedWorkingDepartment.name : ""}
                  onChange={(e) => setSelectedWorkingDepartment({ ...selectedWorkingDepartment, name: e.target.value })}
                  placeholder="Working Department Name"
                />
              </FormControl>
              <FormControl>
                <FormLabel>Department</FormLabel>
                <Select
                  value={selectedWorkingDepartment ? selectedWorkingDepartment.dept.id : ""}
                  onChange={(e) => setSelectedWorkingDepartment({ ...selectedWorkingDepartment, dept: { id: e.target.value } })}
                  placeholder="Select Department"
                >
                  {departments.map((dept) => (
                    <option key={dept.id} value={dept.id}>
                      {dept.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="blue" onClick={handleSaveEdit}>
                Save
              </Button>
              <Button onClick={handleCloseEditModal}>Cancel</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        <Modal isOpen={isDeleteConfirmationOpen} onClose={() => setIsDeleteConfirmationOpen(false)}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Confirmation</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              Are you sure you want to delete {workingDepartmentToDelete && workingDepartmentToDelete.name}?
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="red" onClick={confirmDelete}>
                Yes
              </Button>
              <Button onClick={() => setIsDeleteConfirmationOpen(false)}>No</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>
    </ChakraProvider>
  );
};

export default AddWorkingDepartment;
