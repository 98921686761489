import React, { useState, useEffect, useMemo } from "react";
import NavigationMenu from "../Navigation/NavigationMenu";
import axios from "axios";
import "./AddNabhPolicy.css";


import {
  ChakraProvider,
  InputGroup,
  Input,
  InputRightElement,
  IconButton,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  FormControl,
  FormLabel,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
} from "@chakra-ui/react";

import {
  SearchIcon,
  ViewIcon,
  EditIcon,
  DeleteIcon,
  AddIcon,
} from "@chakra-ui/icons";
import { useLocation } from "react-router-dom";


const API_BASE_URL = "https://divine.backenddd.in";

const AddNabhPolicy = () => {
  const location = useLocation();

  const [name, setName] = useState("");
  const [doc, setDoc] = useState([""]);
  const [policies, setPolicies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [policiesName, setPoliciesName] = useState([]);
  const initialDept = location.state?.NabhPolicysearch;


  const fetchPolicies = async () => {
    try {
      if (
        initialDept !== null &&
        initialDept !== undefined &&
        initialDept !== "" &&
        initialDept !== " "
      ) {
        const response = await axios.get(
          `${API_BASE_URL}/HumanResource/NabhPolicy/${initialDept}/`
        );
        setPolicies(response.data);
        console.log("Polices: ", response.data["name"]);
        const policyNames = policies.map((policy) => policy.name);
        setPoliciesName(policyNames);
        console.log("Initial Nabh Policy: ", initialDept);
        setLoading(false);
      } else {
        const response = await axios.get(
          `${API_BASE_URL}/NabhPolicy/`
        );
        setPolicies(response.data);
        console.log("Polices: ", response.data["name"]);
        const policyNames = policies.map((policy) => policy.name);
        setPoliciesName(policyNames);
        console.log("Initial Nabh Policy: ", initialDept);
        setLoading(false);
      }
    } catch (error) {
      console.error("Failed to fetch policies", error);
      setLoading(false);
    }
  };

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedNabhPolicy, setSelectedNabhPolicy] = useState(null);
  // Create a new policy
  const createPolicy = async () => {
    const formData = new FormData();
    formData.append("name", name); // Ensure this is not an empty string
    if (doc) {
      formData.append("doc", doc);
    } else {
      return;
    }


    try {
      const response = await axios.post(
        `https://divine.backenddd.in/NabhPolicy/AddNabhPolicy/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("Policy Created:", response.data);
      setName("");
      setDoc(null);
      fetchPolicies(); // Refresh the list
    } catch (error) {
      console.error("Failed to create policy", error);
    }
  };

  // Update an existing policy
  const updatePolicy = async (id, policyData) => {
    try {
      const response = await axios.put(
        `${API_BASE_URL}/NabhPolicy/UpdateNabhPolicy/${id}/`,
        policyData
      );
      console.log("Policy Updated:", response.data);
      fetchPolicies(); // Refresh the list
    } catch (error) {
      console.error("Failed to update policy", error);
    }
  };

  // Delete a policy
  const deletePolicy = async (id) => {
    try {
      const response = await axios.delete(
        `${API_BASE_URL}/NabhPolicy/DeleteNabhPolicy/${id}/`
      );
      console.log("Policy Deleted:", response.data);
      fetchPolicies(); // Refresh the list
    } catch (error) {
      console.error("Failed to delete policy", error);
    }
  };

  // Use Effect to load data on component mount
  useEffect(() => {
    fetchPolicies();
  }, []);

  const {
    isOpen: isOpenAddNabhPolicy,
    onOpen: onOpenAddNabhPolicy,
    onClose: onCloseAddNabhPolicy,
  } = useDisclosure();
  const [policiesAddNabhPolicy, setPoliciesAddNabhPolicy] = useState([]);
  const [currentPolicyAddNabhPolicy, setCurrentPolicyAddNabhPolicy] = useState({
    name: "",
    file: null,
  });
  const [editingIndexAddNabhPolicy, setEditingIndexAddNabhPolicy] = useState(-1); // -1 when not editing


  const resetFormAddNabhPolicy = () => {
    setCurrentPolicyAddNabhPolicy({ name: "", file: null });
    setEditingIndexAddNabhPolicy(-1);
    onCloseAddNabhPolicy();
  };

  const handleEditAddNabhPolicy = (index) => {
    console.log("Selected index:", index);
    console.log("Policies at the time of edit:", policies);

    if (index >= 0 && index < policies.length) {
      const policy = policies[index];
      setName(policy.name); // Set name in state
      setDoc(policy.doc); // Set document in state, assuming `policy.doc` holds the document info
      setEditingIndexAddNabhPolicy(index);
      onOpenAddNabhPolicy();
    } else {
      console.error("Invalid index or empty policies array");
    }
  };

  const handleEdit = (NabhPolicy) => {
    setSelectedNabhPolicy(NabhPolicy);
    setIsEditModalOpen(true);
  };

  const handleDeleteAddNabhPolicy = (index) => {
    setPoliciesAddNabhPolicy(policiesAddNabhPolicy.filter((_, i) => i !== index));
  };

  const handleCloseEditModal = () => {
    setSelectedNabhPolicy(null);
    setIsEditModalOpen(false);
  };

  const formatDate = (dateString) => {
    if (!dateString) return "No date provided"; // Fallback text or logic
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
  };

  const handleSaveEdit = async () => {
    const formData = new FormData();
    formData.append("name", selectedNabhPolicy.name); // Assuming 'name' is the state for the policy name
    if (selectedNabhPolicy.doc) {
      formData.append("doc", selectedNabhPolicy.doc); // Assuming 'doc' is the state containing the file
    }

    try {
      await axios.put(
        `https://divine.backenddd.in/NabhPolicy/UpdateNabhPolicy/${selectedNabhPolicy.id}/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setIsEditModalOpen(false);

      fetchPolicies();
    } catch (error) {
      console.error("Error updating NabhPolicy:", error);
    }
  };

  //  Search

  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleSearch = async () => {
    try {
      const response = await axios.get(
        `https://divine.backenddd.in/NabhPolicy/SearchNabhPolicy/?q=${inputValue}/`
      );
      setPolicies(response.data);
    } catch (error) {
      console.error("Failed to search policies", error);
    }
  };


  const [userStatus, setUserStatus] = useState(false);
  const [isSuperuser, setIsSuperUser] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get("https://divine.backenddd.in/HumanResource/user-status/");
        if (response.status === 200) {
          console.log("Response: ", response.data)
          setUserStatus(response.data);
          setIsSuperUser(response.data.is_superuser);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, []);

  const extractParts = (name) => {
    const match = name.match(/^([A-Za-z]+)-(\d+)/);
    return match ? { prefix: match[1], number: parseInt(match[2], 10) } : { prefix: '', number: 0 };
  };
  
  const sortedPolicies = useMemo(() => {
    return policies.slice().sort((a, b) => {
      const partsA = extractParts(a.name);
      const partsB = extractParts(b.name);
  
      // First compare the alphabetical prefix
      if (partsA.prefix < partsB.prefix) return -1;
      if (partsA.prefix > partsB.prefix) return 1;
  
      // If prefixes are the same, then compare the numerical part
      return partsA.number - partsB.number;
    });
  }, [policies]);


  return (
    <>
      <NavigationMenu />
      <ChakraProvider>
        <div className="addNabhPolicyWrapper">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-md-12 col-lg-12">
                <div className="addNabhPolicyHeading">
                  <h1>NABH Policy</h1>
                  <p>
                    Guidelines for <span> Employee Management </span>and
                    Workplace Conduct
                  </p>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md-4 col-lg-4">
                <InputGroup className="NabhPolicySearchInput">
                  <Input
                    placeholder="Search Nabh Policy"
                    onChange={handleInputChange}
                  />
                  <InputRightElement>
                    <IconButton
                      aria-label="Search database"
                      icon={<SearchIcon />}
                      onClick={handleSearch}
                      size="sm"
                    />
                  </InputRightElement>
                </InputGroup>
              </div>

              <div className="col-12 col-md-4 col-lg-4">
                <Button colorScheme="blue" onClick={handleSearch}>
                  Search
                </Button>
              </div>

              <div className="col-12 col-md-4 col-lg-4">
                <Button
                  leftIcon={<AddIcon />}
                  colorScheme="blue"
                  onClick={() => {
                    createPolicy();
                    onOpenAddNabhPolicy();
                  }}
                >
                  Add Policy
                </Button>

                <Modal
                  isOpen={isOpenAddNabhPolicy}
                  onClose={resetFormAddNabhPolicy}
                >
                  <ModalOverlay />
                  <ModalContent>
                    <ModalHeader>
                      {editingIndexAddNabhPolicy === -1
                        ? "Add a New Policy"
                        : "Edit Policy"}
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                      <FormControl>
                        <FormLabel>Policy Name</FormLabel>
                        <Input
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                        <FormLabel mt={4}>Attach Document</FormLabel>
                        <Input
                          type="file"
                          onChange={(e) => setDoc(e.target.files[0])}
                        />
                      </FormControl>
                    </ModalBody>

                    <ModalFooter>
                      <Button
                        colorScheme="blue"
                        mr={3}
                        onClick={async () => {
                          await createPolicy();
                          resetFormAddNabhPolicy();
                        }}
                      >
                        Save
                      </Button>
                    </ModalFooter>
                  </ModalContent>
                </Modal>
              </div>
            </div>

            <Modal isOpen={isEditModalOpen} onClose={handleCloseEditModal}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Edit NabhPolicy</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <FormControl>
                    <FormLabel>NabhPolicy Name</FormLabel>
                    <Input
                      type="text"
                      value={selectedNabhPolicy ? selectedNabhPolicy.name : ""}
                      onChange={(e) =>
                        setSelectedNabhPolicy({
                          ...selectedNabhPolicy,
                          name: e.target.value,
                        })
                      }
                    />
                    <label htmlFor="">Change File</label>
                    <Input
                      type="file"
                      // value={selectedNabhPolicy ? selectedNabhPolicy.doc : ""}
                      onChange={(e) =>
                        setSelectedNabhPolicy({
                          ...selectedNabhPolicy,
                          doc: e.target.files[0],
                        })
                      }
                    />
                  </FormControl>
                </ModalBody>
                <ModalFooter>
                  <Button colorScheme="blue" onClick={handleSaveEdit}>
                    Save
                  </Button>{" "}
                  <Button onClick={handleCloseEditModal}>Cancel</Button>
                </ModalFooter>
              </ModalContent>
            </Modal>

            <div className="addNabhPolicyViewWrapper">
              <div className="row">
                <div className="col-12 col-md-12 col-lg-12">
                  <Box overflowX={"auto"}>
                    <Table
                      variant="simple"
                      mt={4}
                      className="NabhPolicyTableFormat"
                    >
                      <Thead>
                        <Tr>
                          <Th width={"10%"}>Sr No.</Th>
                          <Th width={"25%"}>Policy Name</Th>
                          <Th width={"20%"}>Created Date</Th>
                          <Th width={"20%"}>Renew Date</Th>
                          {isSuperuser && (
                            <>
                              <Th width={"5%"}>Document</Th>
                              <Th width={"20%"}>Actions</Th>
                            </>
                          )}
                        </Tr>
                      </Thead>
                      <Tbody>
                        {sortedPolicies.map((policy, index) => (
                          <Tr key={index}>
                            <Td>{index + 1}</Td>
                            <Td>{policy.name}</Td>
                            <Td>{formatDate(policy.created_date)}</Td>
                            <Td>
                              {formatDate(policy.renew_date)}
                            </Td>
                            
                            {isSuperuser && (
                              <>
                                <Td>
                                  <IconButton
                                    icon={<ViewIcon />}
                                    aria-label="View Document"
                                    onClick={() =>
                                      window.open(
                                        `https://divine.backenddd.in${policy.doc}/`,
                                        "_blank"
                                      )
                                    }
                                  />
                                </Td>
                                <Td>
                                  <IconButton
                                    icon={<EditIcon />}
                                    aria-label="Edit"
                                    mx={2}
                                    onClick={() => handleEdit(policy)} // This should prepare the form
                                  />
                                  <IconButton
                                    icon={<DeleteIcon />}
                                    aria-label="Delete"
                                    onClick={() => deletePolicy(policy.id)}
                                  />
                                </Td>
                              </>
                            )}

                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ChakraProvider>
    </>
  );
};

export default AddNabhPolicy;
