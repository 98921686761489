import React, { useState } from "react";
import axios from "axios";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";

const AddPosition = ({ departments, onDataChange }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [name, setName] = useState("");
  const [dept, setDept] = useState("");
  const toast = useToast();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://divine.backenddd.in/HumanResource/api/positions/add/",
        { name, dept }
      );
      toast({
        title: "Designation created successfully!",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setName("");
      setDept("");
      onDataChange(response.data); // Notify parent component of the new position
      onClose();
    } catch (error) {
      console.error("Error creating Designation:", error);
      toast({
        title: "An error occurred while creating the Designation. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Button
        className="addButtonEmp"
        leftIcon={<AddIcon />}
        colorScheme="teal"
        variant="solid"
        onClick={onOpen}
      >
        Add
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Designation</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl id="positionName">
              <FormLabel>Designation Name</FormLabel>
              <Input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Enter Designation name"
              />
            </FormControl>
            <FormControl id="deptName" mt={4}>
              <FormLabel>Department</FormLabel>
              <Select
                value={dept}
                onChange={(e) => setDept(e.target.value)}
                placeholder="Select department"
              >
                {departments.map((dept) => (
                  <option key={dept.id} value={dept.id}>
                    {dept.name}
                  </option>
                ))}
              </Select>
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="teal" mr={3} onClick={handleSubmit}>
              Save
            </Button>
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddPosition;
