import React, { useState, useEffect } from "react";
import NavigationMenu from "../Navigation/NavigationMenu";
import axios from "axios";

import {
  ChakraProvider,
  InputGroup,
  Input,
  InputRightElement,
  IconButton,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  FormControl,
  FormLabel,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
} from "@chakra-ui/react";

import {
  SearchIcon,
  ViewIcon,
  EditIcon,
  DeleteIcon,
  AddIcon,
} from "@chakra-ui/icons";

import "./AddSalary.css";

const API_BASE_URL = "https://divine.backenddd.in";

const AddSalary = () => {
  const [name, setName] = useState("");
  const [doc, setDoc] = useState(null); // Changed initial state to null
  const [startdate, setStartDate] = useState(null); // Changed initial state to null

  const [policies, setPolicies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refNumber, setRefNumber] = useState("");

  const [isOpenAddSalary, setIsOpenAddSalary] = useState(false);

  const resetFormAddSalary = () => {
    setName("");
    setDoc(null);
    // setPubDate(null);
    setRefNumber("");
    setEditingIndexAddSalary(-1);
    setIsOpenAddSalary(false);
  };

  const fetchPolicies = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/Salary/`);
      console.log("Fetch Salary Data: ", response)
      setPolicies(response.data.data);
      setLoading(false);
    } catch (error) {
      console.error("Failed to fetch policies", error);
      setLoading(false);
    }
  };
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedSalary, setSelectedSalary] = useState(null);
  // Create a new policy
  const createPolicy = async () => {
    const formData = new FormData();
    formData.append("name", name); // Corrected field name to 'name'
    formData.append("increment_date", startdate); // Added ref_number
    if (doc) {
      formData.append("doc", doc);
    } else {
      return;
    }

    try {
      const response = await axios.post(
        `https://divine.backenddd.in/Salary/AddSalary/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("Policy Created:", response.data);
      fetchPolicies(); // Refresh the list
      resetFormAddSalary();
    } catch (error) {
      console.error("Failed to create policy", error);
    }
  };

  // Update an existing policy
  const updatePolicy = async (id, policyData) => {
    try {
      // Ensure "ref_number" is included in the policyData
      if (!policyData.ref_number) {
        throw new Error("Reference number is required.");
      }

      const response = await axios.put(
        `${API_BASE_URL}/Salary/UpdateSalary/${id}/`,
        policyData
      );
      console.log("Policy Updated:", response.data);
      fetchPolicies(); // Refresh the list
    } catch (error) {
      console.error("Failed to update policy", error);
      // Handle error gracefully, display error message to the user
    }
  };

  // Delete a policy
  const deletePolicy = async (id) => {
    try {
      const response = await axios.delete(
        `${API_BASE_URL}/Salary/DeleteSalary/${id}/`
      );
      console.log("Policy Deleted:", response.data);
      fetchPolicies(); // Refresh the list
    } catch (error) {
      console.error("Failed to delete policy", error);
    }
  };

  // Use Effect to load data on component mount
  useEffect(() => {
    fetchPolicies();
  }, []);

  const handleSearchAddSalary = () => {
    // Define what happens when you search
    console.log("Searching...");
  };
  const {
    isOpen,
    onOpen: onOpenAddSalary,
    onClose: onCloseAddSalary,
  } = useDisclosure();

  const [policiesAddSalary, setPoliciesAddSalary] = useState([]);
  const [currentPolicyAddSalary, setCurrentPolicyAddSalary] = useState({
    name: "",
    file: null,
  });
  const [editingIndexAddSalary, setEditingIndexAddSalary] = useState(-1); // -1 when not editing

  const handleFileChangeAddSalary = (e) => {
    setCurrentPolicyAddSalary((prev) => ({
      ...prev,
      file: e.target.files[0],
    }));
  };

  const savePolicyAddSalary = () => {
    const newPolicy = {
      ...currentPolicyAddSalary,
      id:
        editingIndexAddSalary === -1
          ? policiesAddSalary.length + 1
          : policiesAddSalary[editingIndexAddSalary].id,
      createDate:
        editingIndexAddSalary === -1
          ? new Date()
          : policiesAddSalary[editingIndexAddSalary].createDate,
    };

    let updatedPoliciesAddSalary = [...policiesAddSalary];
    if (editingIndexAddSalary === -1) {
      updatedPoliciesAddSalary.push(newPolicy);
    } else {
      updatedPoliciesAddSalary[editingIndexAddSalary] = newPolicy;
    }

    setPoliciesAddSalary(updatedPoliciesAddSalary);
    resetFormAddSalary();
  };


  const handleCloseEditModal = () => {
    setSelectedSalary(null);
    setIsEditModalOpen(false);
  };

  const formatDate = (dateString) => {
    if (!dateString) return "No date provided"; // Fallback text or logic
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
  };

  const handleEdit = (policy) => {
    setSelectedSalary(policy);
    setIsEditModalOpen(true);
  };

  const handleSaveEdit = async () => {
    const formData = new FormData();
    formData.append("name", selectedSalary.name); // Assuming 'name' is the state for the policy name
    formData.append("increment_date", selectedSalary.increment_date); // Assuming 'ref_number' is the state for the reference number
    if (selectedSalary.doc) {
      formData.append("doc", selectedSalary.doc); // Assuming 'doc' is the state containing the file
    } else {
      formData.append("doc", "");
    }

    try {
      await axios.put(
        `${API_BASE_URL}/Salary/UpdateSalary/${selectedSalary.id}/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setIsEditModalOpen(false);

      fetchPolicies();
    } catch (error) {
      console.error("Error updating policy:", error);
    }
  };

  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleSearch = async () => {
    try {
      const response = await axios.get(
        `https://divine.backenddd.in/Salary/SearchSalary/?q=${inputValue}/`
      );
      setPolicies(response.data);
    } catch (error) {
      console.error("Failed to search policies", error);
    }
  };

  const [userStatus, setUserStatus] = useState(false);
  const [isSuperuser, setIsSuperUser] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get("https://divine.backenddd.in/HumanResource/user-status/");
        if (response.status === 200) {
          console.log("Response: ", response.data)
          setUserStatus(response.data);
          setIsSuperUser(response.data.is_superuser);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, []);

  return (
    <>
      <NavigationMenu />
      <ChakraProvider>
        <div className="addSalaryWrapper">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-md-12 col-lg-12">
                <div className="addSalaryHeading">
                  <h1>Salary Summary Document</h1>
                  <p>
                    Guidelines for <span> Employee Management </span>and
                    Workplace Conduct
                  </p>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md-4 col-lg-4">
                <InputGroup className="SalarySearchInput">
                  <Input
                    placeholder="Search Salary"
                    onChange={handleInputChange}
                  />
                  <InputRightElement>
                    <IconButton
                      aria-label="Search database"
                      icon={<SearchIcon />}
                      onClick={handleSearch}
                      size="sm"
                    />
                  </InputRightElement>
                </InputGroup>
              </div>

              <div className="col-12 col-md-4 col-lg-4">
                <Button colorScheme="blue" onClick={handleSearch}>Search</Button>
              </div>

              <div className="col-12 col-md-4 col-lg-4">
                <Button
                  leftIcon={<AddIcon />}
                  colorScheme="blue"
                  onClick={() => {
                    setIsOpenAddSalary(true); // Trigger the opening of the modal
                  }}
                >
                  Add Salary
                </Button>
                <Modal
                  isOpen={isOpenAddSalary}
                  onClose={resetFormAddSalary}
                >
                  <ModalOverlay />
                  <ModalContent>
                    <ModalHeader>
                      {editingIndexAddSalary === -1
                        ? "Add Summary"
                        : "Edit Salary"}
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                      <FormControl>
                        {/* <FormLabel mt={4}>Reference Number</FormLabel>
                        <Input
                          value={refNumber}
                          onChange={(e) => setRefNumber(e.target.value)}
                        /> */}
                        <FormLabel>Month Name</FormLabel>
                        <Input
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />

                        <FormLabel mt={4}>Documents</FormLabel>
                        <Input
                          type="file"
                          onChange={(e) => setDoc(e.target.files[0])}
                        />
                        <FormLabel mt={4}>Date of Salary</FormLabel>
                        <Input
                          type="date"
                          onChange={(e) => setStartDate(e.target.value)}
                        />
                      </FormControl>
                    </ModalBody>

                    <ModalFooter>
                      <Button
                        colorScheme="blue"
                        mr={3}
                        onClick={async () => {
                          await createPolicy();
                          resetFormAddSalary();
                        }}
                      >
                        Save
                      </Button>
                    </ModalFooter>
                  </ModalContent>
                </Modal>
              </div>
            </div>

            <Modal isOpen={isEditModalOpen} onClose={handleCloseEditModal}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Edit Salary Summary</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <FormControl>
                    <FormLabel>Month Name</FormLabel>
                    <Input
                      type="text"
                      value={selectedSalary ? selectedSalary.name : ""}
                      onChange={(e) =>
                        setSelectedSalary({
                          ...selectedSalary,
                          name: e.target.value,
                        })
                      }
                    />

                    <FormLabel mt={4}>Publication Date</FormLabel>
                    <Input
                      type="date"
                      value={
                        selectedSalary ? selectedSalary.increment_date : ""
                      }
                      onChange={(e) =>
                        setSelectedSalary({
                          ...selectedSalary,
                          increment_date: e.target.value,
                        })
                      }
                    />
                    <FormLabel mt={4}>Attach Document</FormLabel>
                    <Input
                      type="file"
                      onChange={(e) =>
                        setSelectedSalary({
                          ...selectedSalary,
                          doc: e.target.files[0],
                        })
                      }
                    />
                  </FormControl>
                </ModalBody>
                <ModalFooter>
                  <Button colorScheme="blue" onClick={handleSaveEdit}>
                    Save
                  </Button>
                  <Button onClick={handleCloseEditModal}>Cancel</Button>
                </ModalFooter>
              </ModalContent>
            </Modal>

            <div className="addSalaryViewWrapper">
              <div className="row">
                <div className="col-12 col-md-12 col-lg-12">
                  <Box overflowX={"auto"}>
                    <Table
                      variant="simple"
                      mt={4}
                      className="SalaryTableFormat"
                    >
                      <Thead>
                        <Tr>
                          <Th width={"25%"}>Month Name</Th>
                          <Th width={"20%"}>Date</Th>
                          <Th width={"5%"}>Documents</Th>
                          {isSuperuser && (
                            <Th width={"20%"}>Actions</Th>
                          )}
                        </Tr>
                      </Thead>
                      <Tbody>
                        {policies.map((policy, index) => (
                          <Tr key={index}>
                            <Td>{policy.name}</Td>
                            <Td>{formatDate(policy.increment_date)}</Td>
                            <Td>
                              <IconButton
                                icon={<ViewIcon />}
                                aria-label="View Document"
                                onClick={() =>
                                  window.open(
                                    `https://divine.backenddd.in${policy.doc}/`,
                                    "_blank"
                                  )
                                }
                              />
                            </Td>
                            {isSuperuser && (
                              <Td>
                                <IconButton
                                  icon={<EditIcon />}
                                  aria-label="Edit"
                                  mx={2}
                                  onClick={() => handleEdit(policy)} // This should prepare the form
                                />
                                <IconButton
                                  icon={<DeleteIcon />}
                                  aria-label="Delete"
                                  onClick={() => deletePolicy(policy.id)}
                                />
                              </Td>
                            )}
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ChakraProvider>
    </>
  );
};

export default AddSalary;
