import React, { useState, useEffect } from "react";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  IconButton,
  Box
} from "@chakra-ui/react";
import axios from "axios";
import { DeleteIcon, EditIcon } from "@chakra-ui/icons";

const AddDependentMember = ({ username }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [dependants, setDependants] = useState([]);
  const [formData, setFormData] = useState({ name: "", age: "", relation: "" });
  const [editId, setEditId] = useState(null);

  const fetchDependants = async () => {
    try {
      const response = await axios.get(`https://divine.backenddd.in/HumanResource/getdependants/${username}/`);
      setDependants(response.data);
    } catch (error) {
      console.error('Failed to fetch dependants:', error);
    }
  };

  useEffect(() => {
    fetchDependants();
  }, [username]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async () => {
    const apiUrl = editId ? `https://divine.backenddd.in/HumanResource/dependants/${editId}/` : `https://divine.backenddd.in/HumanResource/dependants/`;
    const method = editId ? axios.put : axios.post;

    try {
      const response = await method(apiUrl, { ...formData, staff: username });
      if (response.status === 200 || response.status === 201) {
        fetchDependants();
        onClose();
        setFormData({ name: "", age: "", relation: "" });
        setEditId(null);
      }
    } catch (error) {
      console.error('Failed to submit dependent:', error);
      alert(`Failed to ${editId ? 'update' : 'add'} the dependent: ${error.message}`);
    }
  };

  const handleEdit = (dependent) => {
    setFormData({ name: dependent.name, age: dependent.age, relation: dependent.relation });
    setEditId(dependent.id);
    onOpen();
  };

  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(`https://divine.backenddd.in/HumanResource/dependants/${id}/`);
      if (response.status === 200) {
        fetchDependants();
      }
    } catch (error) {
      console.error('Failed to delete dependent:', error);
      alert('Failed to delete the dependent: ' + error.message);
    }
  };

  return (
    <>
      <Button onClick={() => { setEditId(null); onOpen(); }} className="addEmployeeBtn"><i class="fa-solid fa-plus AddDependIcon"></i> {" "} Add Dependent</Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{editId ? "Edit Dependent" : "Add Dependent"}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>Name</FormLabel>
              <Input value={formData.name} onChange={handleChange} name="name" placeholder="Enter name" />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Age</FormLabel>
              <Input type="number" value={formData.age} onChange={handleChange} name="age" placeholder="Enter age" />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Relation</FormLabel>
              <Input value={formData.relation} onChange={handleChange} name="relation" placeholder="Enter relation" />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleSubmit}>
              Save
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Box overflowX="auto">
      <Table variant="simple" className="addEmployeeTableFormat"  >
        <Thead>
          <Tr>
            <Th width={"10%"} border='1px' borderColor='gray.200'>Srno.</Th> 
            <Th width={"30%"} border='1px' borderColor='gray.200'>Name</Th> 
            <Th width={"20%"} border='1px' borderColor='gray.200'>Age</Th>
            <Th width={"25%"} border='1px' borderColor='gray.200'>Relation</Th>
            <Th width={"15%"} border='1px' borderColor='gray.200'>Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {dependants.map((dep, index) => (
            <Tr key={index}>
              <Td>{index+1}</Td>
              <Td>{dep.name}</Td>
              <Td>{dep.age}</Td>
              <Td>{dep.relation}</Td>
              <Td>
                <IconButton icon={<EditIcon />} onClick={() => handleEdit(dep)} aria-label="Edit" />
                <IconButton icon={<DeleteIcon />} onClick={() => handleDelete(dep.id)} aria-label="Delete" ml={2} />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      </Box>
    </>
  );
};

export default AddDependentMember;
