import axios from "axios";
import React, { useState, useEffect } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  Select,
  Button,
  ChakraProvider,
} from "@chakra-ui/react";
import NavigationMenu from "../Navigation/NavigationMenu";
import { useNavigate } from "react-router-dom";
import "./IssueFormat.css";

const IssueFormat = () => {
  const [certificates, setCertificates] = useState([]);
  const [selectedCertificate, setSelectedCertificate] = useState("");
  const [certificateContent, setCertificateContent] = useState("");
  const [employeeNames, setEmployeeNames] = useState([]);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState({});
  const [formData, setFormData] = useState({
    staff: "",
    reference: "",
    content: "",
    signature: "",

  });
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get("https://divine.backenddd.in/Letter/api/save_certificate/")
      .then((response) => {
        setCertificates(response.data);
      })
      .catch((error) => {
        console.error("Error fetching certificates:", error);
      });

    const accessToken = localStorage.getItem("access_token");

    axios
      .get("https://divine.backenddd.in/HumanResource/StaffList/", {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        setEmployeeNames(response.data);
      })
      .catch((error) => {
        console.error("Error fetching employee names:", error);
      });
  }, []);

  const handleCertificateChange = (event) => {
    const certificateId = event.target.value;
    setSelectedCertificate(certificateId);
    setFormData({ ...formData, content: certificateId });

    axios
      .get(`https://divine.backenddd.in/Letter/api/save_certificate/${certificateId}/`)
      .then((response) => {
        console.log("Certificate OCntent: ", response.data)
        setCertificateContent(certificateId);
      })
      .catch((error) => {
        console.error("Error fetching certificate content:", error);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === "staff" && value) {
      axios
        .get(`https://divine.backenddd.in/HumanResource/view_employee_detail/${value}/`)
        .then((response) => {
          console.log("Response Data: ", response.data);
          setSelectedEmployeeId(response.data);
          setFormData((prevFormData) => ({
            ...prevFormData,
            staff: value,
          }));
        })
        .catch((error) => {
          console.error("Error fetching employee details:", error);
        });
    }
  };

  const handleView = async () => {
    console.log("Form Data being sent:", formData);

    try {
      const response = await axios.post(
        "https://divine.backenddd.in/Letter/save_certificate_model/",
        formData
      );

      if (response.status === 200) {
        console.log("Selected Employee: ", selectedEmployeeId);
        console.log("Selected Certificate: ", selectedCertificate);

        navigate("/printpreview", {
          state: { certificateId: selectedCertificate, employeeId: formData.staff },
        });
      } else {
        console.error("Unexpected response status:", response.status);
      }
    } catch (error) {
      console.error("Error saving view:", error);
    }
  };

  return (
    <>
      <NavigationMenu />
      <ChakraProvider>
        <div className="issueFormatWrapper">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-md-12 col-lg-12">
                <div className="issueFormatHeading">
                  <h1>Issue Format</h1>
                  <p>
                    Structured Format for Official
                    <span> Employment Documentation </span> and Correspondence
                  </p>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md-4 col-lg-4">
                <FormControl isRequired className="addEmployeeFormGroup">
                  <FormLabel htmlFor="employee_id">Employee ID</FormLabel>
                  <Select
                    name="staff"
                    value={formData.staff}
                    onChange={handleChange}
                  >
                    <option value="">--Select an Employee--</option>
                    {employeeNames.sort((a,b)=>a.fname.localeCompare(b.fname)).map((employee) => (
                      <option key={employee.id} value={employee.id}>
                        {employee.fname} {employee.lname}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="col-12 col-md-4 col-lg-4">
                <FormControl isRequired className="addEmployeeFormGroup">
                  <FormLabel htmlFor="format_name">Format Name</FormLabel>
                  <Select
                    value={selectedCertificate}
                    onChange={handleCertificateChange}
                    name="content"
                  >
                    <option value="">--Select a certificate--</option>
                    {certificates.map((certificate) => (
                      <option key={certificate.id} value={certificate.id}>
                        {certificate.name}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </div>

              <div className="col-12 col-md-4 col-lg-4">
                <Button
                  colorScheme="blue"
                  className="issueFormatBtn"
                  mr={3}
                  onClick={handleView}
                >
                  View and Save
                </Button>
              </div>
            </div>
          </div>
        </div>
      </ChakraProvider>
    </>
  );
};

export default IssueFormat;
