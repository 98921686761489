import { useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Badge,
  Button,
  ChakraProvider,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useToast,
  Input,
  FormControl,
  FormLabel,
  FormHelperText,
  extendTheme,
  List,
  ListItem,
  ListIcon,
  Box,
  Spinner,
  IconButton,
} from "@chakra-ui/react";
import {
  ViewIcon,
  ViewOffIcon,
  DeleteIcon,
  AttachmentIcon,
  ExternalLinkIcon,
} from "@chakra-ui/icons";

function ViewDocument() {
  const [isUploading, setIsUploading] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  // const eid = location.state ? location.state.eid : null;
  const [documents, setDocuments] = useState([]);
  const [fileName, setFileName] = useState("");
  const [staffId, setStaffId] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [fileToUpload, setFileToUpload] = useState(null);
  const [canUpdateStatus, setCanUpdateStatus] = useState(false);
  const toast = useToast();

  const getInitialEid = () => {
    const eidFromState = location.state?.eid;
    if (eidFromState) {
      localStorage.setItem("eid", eidFromState);
      return eidFromState;
    }
    return localStorage.getItem("eid");
  };

  const [eid, setEid] = useState(getInitialEid());

  useEffect(() => {
    if (eid) {
      fetchDocuments();
    } else {
      console.error("Employee ID is undefined");
      toast({
        title: "Error",
        description: "Employee ID is missing",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      navigate("/expansion-panel"); // Navigate to a fallback route if eid is not available
    }
  }, [eid]);

  const fetchDocuments = async () => {
    const accessToken = localStorage.getItem("access_token");

    try {
      const storedEid = localStorage.getItem("eid");

      const response = await fetch(
        `https://divine.backenddd.in/HumanResource/staff-with-documents/${storedEid}/`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch documents");
      }
      const users = await axios.get(
        `https://divine.backenddd.in/HumanResource/user-status/`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      console.log("Users: ", users);
      if (users.data.is_superuser) {
        setUserStatus(true);
        console.log("Working");
      }
      console.log("Users: ", users);
      if (users.data.permissions.includes("auth.custom_hr_perm")) {
        setCanUpdateStatus(true);
      }
      const data = await response.json();
      setDocuments(data.files_data);
    } catch (error) {
      console.error("Error fetching documents:", error);
    }
  };

  const openDocument = (document) => {
    const fileType = document.file.split(".").pop();
    if (
      fileType === "pdf" ||
      ["jpg", "jpeg", "png", "gif"].includes(fileType)
    ) {
      window.open(`https://divine.backenddd.in${document.file}`, "_blank");
    } else {
      console.error("Unsupported file type");
    }
  };

  useEffect(() => {
    const storedEid = localStorage.getItem("eid");

    const fetchDocument = async () => {
      if (!eid) {
        console.error("Employee ID is undefined");
        return;
      }
      try {
        const response = await fetch(
          `https://divine.backenddd.in/HumanResource/staff_docs_detail/${eid}/`
        );

        if (!response.ok) {
          throw new Error("Failed to fetch document");
        }
        const data = await response.json();
        // Assuming `data` contains the document details
        // Set the document state here
      } catch (error) {
        console.error("Error fetching document:", error);
      }
    };

    fetchDocument();
  }, [eid]);

  const handleOpenModal = (fileName, staffId) => {
    setIsModalOpen(true);
    setFileName(fileName);
    setStaffId(staffId);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setFileToUpload(null);
  };

  const handleFileChange = (event) => {
    setFileToUpload(event.target.files[0]);
  };

  const handleUploadDocument = async () => {
    if (!fileToUpload) {
      setErrorMessage("Please select a file to upload.");
      return;
    }
    setIsUploading(true);
    try {
      const formData = new FormData();
      formData.append("file", fileToUpload);
      formData.append("file_name", fileName);
      formData.append("staff", eid);

      const response = await fetch(
        `https://divine.backenddd.in/HumanResource/upload_documents/`,
        {
          method: "POST",
          body: formData,
        }
      );
      if (!response.ok) {
        throw new Error("Failed to upload document");
      }
      const data = await response.json();
      setSuccessMessage(data.message);
      setIsUploading(false);
      handleCloseModal();
      fetchDocuments();
    } catch (error) {
      setErrorMessage("Failed to upload document. Please try again later.");
      console.error("Error uploading document:", error);
    }
  };

  const colors = {
    brand: {
      red: "#C0392B", // Replace with the exact color code from the logo
      blue: "#2980B9", // Replace with the exact color code from the logo
      yellow: "#F1C40F", // Replace with the exact color code from the emblem
    },
  };

  const theme = extendTheme({ colors });

  const [currentDocumentList, setCurrentDocumentList] = useState([]);
  const [isDocumentListModalOpen, setIsDocumentListModalOpen] = useState(false);

  const openDocumentListModal = (fileData) => {
    setCurrentDocumentList(fileData.documents); // Assuming 'documents' is an array of document objects
    setIsDocumentListModalOpen(true);
  };

  const viewDocument = (documentUrl) => {
    window.open(`https://divine.backenddd.in${documentUrl}`, "_blank");
  };

  const handleDeleteDocument = async (event, documentId) => {
    event.stopPropagation();
    // Update this URL to the correct path where your Django app is hosted
    const deleteUrl = `https://divine.backenddd.in/HumanResource/delete-document/${documentId}/`;
    if (window.confirm("Are you sure you want to delete this document?")) {
      try {
        const response = await axios.delete(deleteUrl);
        // If the delete was successful, refresh the documents list
        if (response.status === 200) {
          setSuccessMessage("Document deleted successfully");
          // Refresh the list of documents
          setCurrentDocumentList((prevList) =>
            prevList.filter((document) => document.id !== documentId)
          );
          fetchDocuments();
        } else {
          setErrorMessage("An error occurred while deleting the document");
        }
      } catch (error) {
        console.error("Error deleting document:", error);
        setErrorMessage("An error occurred while deleting the document");
      }
    }
  };

  const [userStatus, setUserStatus] = useState(false);
  const [isSuperuser, setIsSuperUser] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get("https://divine.backenddd.in/HumanResource/user-status/");
        if (response.status === 200) {
          console.log("Response: ", response.data)
          setUserStatus(response.data);
          setIsSuperUser(response.data.is_superuser);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, []);

  return (
    <ChakraProvider theme={theme}>
      <div className="viewDocsContainer">
        <div className="viewDocsTable">
          <div className="docTableOne" id="uploadDoc">
            <Box overflowX={"auto"}>
              <Table variant="simple">
                <Thead style={{ backgroundColor: "#338687" }}>
                  <Tr>
                    <Th color="white">Srno.</Th>
                    <Th color="white">Document Name</Th>
                    <Th color="white">Status</Th>
                    
                      <Th color="white">Action</Th>
                    
                  </Tr>
                </Thead>
                <Tbody>
                  {documents
                    .slice()
                    .sort((a, b) =>
                      a.file_format.name.localeCompare(b.file_format.name)
                    )
                    .map((fileData, index) => (
                      <Tr key={index}>
                        <Td>{index + 1}</Td>
                        <Td>{fileData.file_format.name}</Td>
                        <Td>
                          {fileData.documents !== "Not Uploaded" ? (
                            <Badge colorScheme="green">Uploaded</Badge>
                          ) : (
                            <Badge colorScheme="red">Not Uploaded</Badge>
                          )}
                        </Td>
                        <Td>
                          {fileData.documents !== "Not Uploaded" ? (
                            <>
                              <Button
                                colorScheme="green"
                                size="sm"
                                onClick={() => openDocumentListModal(fileData)}
                              >
                                View
                              </Button>
                              <Button
                                colorScheme="blue"
                                size="sm"
                                style={{ marginLeft: "8px" }}
                                onClick={() =>
                                  handleOpenModal(
                                    fileData.file_format.name,
                                    fileData.staff_id
                                  )
                                }
                              >
                                Upload
                              </Button>
                            </>
                          ) : (
                            <>
                              
                                <Button
                                  colorScheme="blue"
                                  size="sm"
                                  onClick={() =>
                                    handleOpenModal(
                                      fileData.file_format.name,
                                      fileData.staff_id
                                    )
                                  }
                                >
                                  Upload
                                </Button>
                              
                            </>
                          )}
                        </Td>
                      </Tr>
                    ))}
                </Tbody>
              </Table>
            </Box>
          </div>
          <Modal
            isOpen={isDocumentListModalOpen}
            onClose={() => setIsDocumentListModalOpen(false)}
            isCentered
          >
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Document List</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <List spacing={3}>
                  {currentDocumentList.map((document, index) => (
                    <Box
                      as="li"
                      key={index}
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      p={2}
                      bg="gray.50"
                      borderRadius="md"
                      _hover={{ bg: "gray.100" }}
                    >
                      {/* Clickable area for viewing the document */}
                      <Box
                        display="flex"
                        alignItems="center"
                        cursor="pointer"
                        onClick={() => viewDocument(document.file)}
                      >
                        <ListIcon color="green.500" />
                        <Box as="span" color="gray.800" fontSize="md" mr={2}>
                          {index + 1} - {document.file_name_id}
                        </Box>
                      </Box>
                      {/* Delete button */}
                      {isSuperuser && (
                        <DeleteIcon onClick={(event) => {
                          event.stopPropagation(); // Prevent the view event from firing when deleting
                          handleDeleteDocument(event, document.id);

                        }} style={{ cursor: "pointer" }} />
                      )}
                    </Box>
                  ))}
                </List>
              </ModalBody>
              <ModalFooter>
                <Button
                  variant="ghost"
                  onClick={() => setIsDocumentListModalOpen(false)}
                >
                  Close
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </div>
      </div>

      <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Upload Document</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>Select File</FormLabel>
              <Input type="file" onChange={handleFileChange} />
              <FormHelperText>Select a file to upload.</FormHelperText>
            </FormControl>
            {errorMessage && (
              <Badge colorScheme="red" mt={2}>
                {errorMessage}
              </Badge>
            )}
          </ModalBody>
          <ModalFooter>
            {isUploading ? (
              <Spinner />
            ) : (
              <>
                <Button
                  colorScheme="blue"
                  mr={3}
                  onClick={handleUploadDocument}
                >
                  Upload
                </Button>
                <Button variant="ghost" onClick={handleCloseModal}>
                  Cancel
                </Button>
              </>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </ChakraProvider>
  );
}

export default ViewDocument;
